<template>
    <div v-if="ready">
        <SlotWidgetCompact
            v-if="compactWidget && slot && !isAks"
            class="slot-stats-widget"
            :slot-data="slot"
            :style="widgetStyle"
            :borders-style="compactSlotWidgetBorders"/>

        <SlotWidget
            v-else-if="!compactWidget && slot && !isAks"
            class="slot-stats-widget"
            :slot-data="slot"
            :style="widgetStyle"/>

        <SlotStatsWidgetAks
            v-else-if="!compactWidget && slot && isAks"
            class="slot-stats-widget"
            :slot-data="slot"
            :style="widgetStyle"/>
    </div>
</template>

<script>
    import SlotWidget from './SlotWidget.vue'
    import SlotWidgetCompact from './SlotWidgetCompact.vue'
    import SlotStatsWidgetAks from './aks/SlotStatsWidgetAks.vue'
    import {slotStatisticsFormatter} from './utils'

    export default {
        components: {SlotWidgetCompact, SlotWidget, SlotStatsWidgetAks},
        computed: {

            isAks() {
                return this.$store.getters['user/me'].id === 841
            },

            ready() {
                return this.$store.getters['widgetSlot/ready']
            },

            slot() {
                return slotStatisticsFormatter(this.$store.getters['widgetSlot/slot'])
            },

            compactWidget() {
                return this.$store.getters['settings/compactSlotWidget']
            },

            widgetStyle() {
                const compact = this.compactWidget
                const width = this.$store.getters['settings/slotWidgetWidth']
                const fontFactor = width / 500
                return {
                    'width': width + 'px',
                    'height': compact ? (width / 12.5) : (width * 0.3) + 'px',
                    'font-size': (fontFactor * 18) + 'px',
                }
            },

            compactSlotWidgetBorders() {
                return this.$store.getters['settings/compactSlotWidgetBorders']
            },
        },

    }
</script>
