<template>
    <div class="divider ">
        <hr class="divider-line"/>
        <div v-if="$slots.default" class="divider-text">
            <slot/>
        </div>
        <hr v-if="$slots.default" class="divider-line"/>
    </div>
</template>

<script>
    export default {

    }
</script>

<style lang="scss" scoped>
    .divider {
        display: flex;
        justify-content:space-evenly;
        align-items: center;
    }

    .divider-line {
        width:100%;
        border-width: 1px 0 0 0;
        margin: 3px 0;
    }

    .divider:has(.divider-text4) {
        .divider-line {
            margin: -1px 0;
        }
    }

    .divider-text {
        color: inherit;
        font-size: 0.45em;
        font-family: "Rubik", "sans-serif";
        margin: -1px 4px;
    }
</style>
