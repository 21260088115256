import {sessionBattleRoutes} from '@api/api-routes'
import axios from "@api/axios";
import {v4 as uuidv4} from 'uuid'
import {bucksToCents, centsToBucks} from '@utils/moneyConverters'


const resetState = (state) => {
	Object.assign(state, getDefaultState())
}

const getDefaultState = () => {
	return {
		session: {},
		slots:   [],
	}
}

export default {
	namespaced: true,

	state:     {
		session: {},
		slots:   [],
	},
	getters:   {
		session: state => state.session,
		slots:   state => state.slots,

        showWidgets: (state, getters, rootState) => {
            return rootState.settings.showWidgets;
        }
	},
	mutations: {
		session: (state, session) => state.session = centsToBucks(session),
		slots:   (state, slots) => state.slots = centsToBucks(slots),
		resetState,
	},
	actions:   {
		async startBattle({commit}, payload) {
            payload.fe_sign = uuidv4()
			payload = await bucksToCents(payload)

			try {
				const {data} = await axios.post(sessionBattleRoutes.create, payload)
                commit('session', data.session_info)
				commit("slots", data.slots)
				return data
			} catch (error) {
				console.log(error)
				throw error
			}
		},

		async fetchBattle({getters, commit, dispatch}, id) {
			try {
				const {data} = await axios.get(sessionBattleRoutes.getSession(id))
                commit('session', data.session_info)
				commit("slots", data.slots)
                if (getters.showWidgets) {
                    dispatch('widgetBattle/fetchWidget', data.session_info.id, {root: true})
                }
				return data
			} catch (error) {
				console.log(error)
				throw error
			}
		},

		async postResult({state, dispatch}, {id, win}) {
			const payload = {"win": win * 100}
			try {
				const response = await axios.post(sessionBattleRoutes.postResult(id), payload)
				await dispatch("fetchBattle", state.session.id)
				return response
			} catch (error) {
				console.log(error)
				throw error
			}
		},
	},

}
