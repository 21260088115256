import Constants from '@resources/js/constants/general.js';

export function useFilters() {
    const fixPercents = (number, digits=2) => fixDecimal(number, digits);

    const fixMoney = (number: number) => numberComplexHandler(number);

    const fixDate = (date: string) => {
        return truncate(date, 10);
    };

    const toUpperCase = (string:string) => string ? string.toUpperCase() : string;

    const numberComplexHandler = (number: number) => {
        if (number >= 0 && number < 100) return fixDecimal(number, 2);
        if (number >= 100 && number < 1000) return fixDecimal(number, 0);
        if (number >= 1000 && number < 100000) return numberSpacing(fixDecimal(number, 0));
        if (number >= 100000) return abbrNum(fixDecimal(number, 0));
        return number;
    };

    const fixDecimal = (data, dig = 2) => {
        const number = typeof data === 'string' ? Number(data) : data;
        if (number > 0 && number < 10) {
            for (let n = dig; n <= 4; n++) {
                const result = parseFloat(number.toFixed(n));
                if (result !== 0) return result;
            }
        } else if (number >= 10 && number < 100) {
            return parseFloat(number.toFixed(1));
        } else if (number >= 100) {
            return number.toFixed(0);
        }
        return number;
    };

    const abbrNum = (number, decPlaces = 2) => {
        const dec = Math.pow(10, decPlaces);
        const abbrev = ['K', 'M', 'B', 'T'];
        for (let i = abbrev.length - 1; i >= 0; i--) {
            const size = Math.pow(10, (i + 1) * 3);
            if (size <= number) {
                number = Math.round(number * dec / size) / dec;
                return number === 1000 && i < abbrev.length - 1 ? `1${abbrev[i + 1]}` : `${number}${abbrev[i]}`;
            }
        }
    };

    const numberSpacing = (number) => number ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : number;

    const truncate = (data, num = 10) => data ? data.slice(0, num) : '';

    const readableCurrency = (currency:keyof typeof Constants.CURRENCY ) => {
        switch (currency) {
            case Constants.CURRENCY_USD: return '$';
            case Constants.CURRENCY_EUR: return '€';
            case Constants.CURRENCY_RUB: return '₽';
            default: return '';
        }
    };

    const readableSessionType = (type) => {
        switch (type) {
            case Constants.TYPE_BONUS_HUNT: return 'Bonus Hunt';
            case Constants.TYPE_BONUS_BUY: return 'Bonus Buy';
            case Constants.TYPE_BATTLE: return 'Bonus Battle';
            case Constants.TYPE_BONUS_TOURNAMENT: return 'Bonus Tournament';
            case Constants.TYPE_SINGLE_ROLL: return 'Single Roll';
            default: return '';
        }
    };

    const isBonusHunt = (sessionType) => sessionType === Constants.TYPE_BONUS_HUNT;

    const isHuntOrBuy = (sessionType) => [Constants.TYPE_BONUS_HUNT, Constants.TYPE_BONUS_BUY].includes(sessionType);

    const isBattle = (sessionType) => sessionType === Constants.TYPE_BATTLE;

    const isTournament = (sessionType) => sessionType === Constants.TYPE_BONUS_TOURNAMENT;

    return {
        fixPercents,
        fixMoney,
        fixDate,
        toUpperCase,
        numberComplexHandler,
        fixDecimal,
        abbrNum,
        numberSpacing,
        truncate,
        readableCurrency,
        readableSessionType,
        isBonusHunt,
        isHuntOrBuy,
        isBattle,
        isTournament,
    };
}
