<template>
    <div class="session-widget" v-if="ready">
        <div class="session-widget-header text-big">
            <div class="title">
                {{ titleTranslate(session.title) }}
            </div>
            <div class="currency">
                {{ readableCurrency(session.value.currency) }}
            </div>
        </div>

        <div class="widget-session-stats text-middle">
            <div class="widget-block-small">
                <span class="title">БОНУС</span>
                <span class="value">{{ playedBonus }}</span>
            </div>

            <div class="widget-block-small middle">
                <span class="title">ВИН/ДЕП</span>
                <span class="value">{{ winDepRatio }}</span>
            </div>

            <div class="widget-block-small">
                <span class="title">СРД.Х</span>
                <span class="value">{{ fixPercents(session.average_x_price) }}</span>
            </div>
        </div>

        <div class="competitors-list">
            <div class="rounds-list">
                <div class="flex flex-column rounds-list-round-item">
                    <div v-for="(slot, i) in slots" :key="slot.id">
                        <div class="flex justify-content-between">
                            <div class="flex align-items-center gap-1" style="width: 75%">
                                <competitor-literal-icon
                                    class="text-small"
                                    :style="{ opacity: isWinner(slots, i) ? 1 : 0.5 }"
                                    :letter="competitorIcon(slot, i)"
                                />
                                <div class="text-small px-2 uppercase long-title" :style="{ opacity: isWinner(slots, i) ? 1 : 0.5 }">
                                    {{ slot.title }}
                                </div>
                            </div>
                            <div class="pl-2 text-small flex gap-2" style="width: 20%">
                                <div v-show="slot.spins_total === slot.spins_finished">
                                    <span v-if="isWinner(slots, i) && slot.slot_score" style="color:#08AA70">W</span>
                                    <span v-else-if="!isWinner(slots, i) && slot.slot_score" style="color:#780DCD">L</span>
                                    <span v-else>&nbsp;</span>
                                </div>
                                {{ fixPercents(slot.slot_score ?? 0) }}
                            </div>
                        </div>
                        <div class="divider" v-if="i === 0">
                            <hr class="divider-line"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="pvp-results-table">
            <div v-for="(slot, index) in slots" :key="slot.id" class="results-round-item">
                <div class="flex align-items-center justify-content-between text-small round-list-header">
                    <competitor-literal-icon :letter="competitorIcon(slot, index)"/>
                    <span>ЦЕНА: {{ slot.t_price ?? slot.price }}</span>
                    <span>СЫГРАНО РАУНДОВ: {{ slot.spins_finished }}</span>
                </div>
                <div class="results">
                    <div v-for="(result, result_index) in slot.results" :key="result_index">
            <span class="text-small round-slot-result-item">
              <span class="text-x-small serial-number-attempt"
                    :style="{ background: index === 1 ? '#08AA70' : '#780DCD' }">
                {{ result_index + 1 }}:
              </span>
              {{ fixMoney(result.win) }}
            </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import {computed} from 'vue'
    import {useStore} from 'vuex'
    import {useFilters} from '@composables/useFilters'
    import CompetitorLiteralIcon from '../../misc/CompetitorLiteralIcon.vue'

    const store = useStore()
    const {fixPercents, fixMoney, readableCurrency} = useFilters()

    const session = computed(() => store.getters['widgetBattle/session'].session_info)
    const slots = computed(() => store.getters['widgetBattle/session'].slots)

    const playedBonus = computed(() => `${slots.value[0].spins_finished + slots.value[1].spins_finished} / ${session.value.session_spins_total}`)
    const winDepRatio = computed(() => `${fixMoney(session.value.actual_win)} / ${fixMoney(session.value.target_win)}`)
    const ready = computed(() => !!store.getters['widgetBattle/session'])

    function titleTranslate(title) {
        const replacements = {
            'BONUS BUY': 'БОНУС БАЙ',
            'BONUS HUNT': 'БОНУС ХАНТ',
            'BONUS TOURNAMENT': 'БОНУС ТУРНИР',
            'BONUS BATTLE': 'БОНУС БАТЛ',
        }

        const pattern = new RegExp(Object.keys(replacements).join('|'), 'g')

        return title.replace(pattern, match => replacements[match])
    }

    function isRoundFinished(round) {
        return round.every(competitor =>
            competitor.results && Array.isArray(competitor.results) &&
            competitor.results.every(attempt => attempt.win !== null),
        )
    }

    function isWinner(round, index) {
        if (isRoundFinished(round)) {
            const winnerIndex = round.reduce((maxIndex, competitor, currentIndex) => {
                if (competitor.slot_score === 0) {
                    return maxIndex
                }

                if (maxIndex === null || parseFloat(competitor.slot_score) > parseFloat(round[maxIndex].slot_score)) {
                    return currentIndex
                }

                return maxIndex
            }, null)

            return index === winnerIndex
        } else {
            return true
        }
    }

    function competitorIcon(slot, i) {
        const fallbackPreset = ['A', 'B']
        const letter = slot?.icon ?? slot.color
        return letter.startsWith('#') ? fallbackPreset[i] : letter
    }
</script>

<style lang="scss" scoped>
    @import '../../../../assets/css/variables';

    .session-widget {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        color: white;
        background-color: #010D11;
        border-radius: 5%;
        cursor: none;
        gap: $container-padding;
        flex: 0 1 auto;
        overflow: hidden
    }

    .session-widget-header {
        display: flex;
        height: 12.5%;
        flex-wrap: nowrap;
        justify-content: space-between;
        background: linear-gradient(89.03deg, #008958 -3.87%, #3B0069 106.39%);
        align-items: center;
        padding: 0.5em;
        border-radius: 0 0 0.5em 0.5em;
    }

    .logo, .currency {
        background: #00000033;
        border-radius: 0.25em;
        padding: 0 0.25em;
    }

    .logo {
        height: 100%;
        width: 33%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.25em;

        > * {
            //background-image: url("./streamer-aks-logo.png");
            width: 100%;
            background-position: center center;
            background-repeat: no-repeat;
            background-clip: padding-box;
            background-size: contain;
        }
    }


    .widget-session-stats {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        margin: 0 0.3em !important;
        gap: $section-padding;
    }

    .widget-block-small {
        width: 25%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 0.3em 0.3em;
        overflow: hidden;

        &.middle {
            width: 50%;
        }

        .title {
            background: linear-gradient(269.28deg, #08AA70 4.14%, #780DCD 113.19%);
            background-clip: border-box;
            width: 100%;
            height: 40%;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            font-size: calc($small-font-size * .75) !important;
        }

        .value {
            background: linear-gradient(269.28deg, rgba(8, 170, 112, 0.15) 4.14%, rgba(120, 13, 205, 0.15) 113.19%);
            background-clip: border-box;
            width: 100%;
            height: 60%;
            display: flex;
            justify-content: center;
            align-items: flex-end;
        }
    }

    .competitors-list {
        padding: 0.2em;
        display: flex;
        gap: 0.2em;
        flex-direction: column;
        justify-content: space-evenly;
        overflow: hidden;
        max-height: min-content;
        transition: max-height 0.3s ease;
    }

    .rounds-list {
        display: flex;
        flex-direction: column;
        gap: 0.6em;
        //height: 100%
    }

    .rounds-list-round-item {
        background: linear-gradient(269.28deg, rgba(8, 170, 112, 0.15) 4.14%, rgba(120, 13, 205, 0.15) 113.19%);
        background-clip: border-box;
        border-radius: 1.2em;
        padding: 0.4em;
    }

    .divider {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    .divider-line {
        width: 100%;
        border-width: 1px 0 0 0;
        margin: 3px 0;
        background: linear-gradient(89.03deg, #008958 -3.87%, #3B0069 106.39%);
    }

    .divider:has(.divider-text) {
        .divider-line {
            margin: -1px 0;
        }
    }

    .divider-text {
        color: inherit;
        font-size: 0.4em;
        margin: -1px 4px;
    }


    .pvp-results-table {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.5em;
    }

    .results-round-item {
        border-radius: 1.5em;
        overflow: hidden;
    }

    .round-list-header {
        background: linear-gradient(269.28deg, #08AA70 4.14%, #780DCD 113.19%);
        padding: 0.2em 1.5em;

    }


    .results {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        row-gap: 0.1em;
        column-gap: 0.2em;
        background: linear-gradient(269.28deg, rgba(8, 170, 112, 0.15) 4.14%, rgba(120, 13, 205, 0.15) 113.19%);
        padding: 0.2em 1.5em;

    }

    .round-slot-result-item {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .serial-number-attempt {
        border-radius: 0.2em;
        padding: 0 0.2em;
    }


    .winner {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0.2em;
        border-radius: 1.3em;
        background: linear-gradient(269.28deg, #08AA70 4.14%, #780DCD 113.19%);
        justify-content: center;

        min-height: 50px;
        margin: 0 5%;
        opacity: 0.8;
    }


    * {
        font-family: Montserrat, sans-serif !important
    }
</style>

