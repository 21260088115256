<template>
    <div class="widget-settings">

        <Toolbar>
            <template #start>
                <label>Show widgets in main interface:</label>
                <i class="p-toolbar-separator"/>
                <i class="p-toolbar-separator"/>
                <InputSwitch v-model="showWidgets"/>
            </template>
        </Toolbar>
        <br>

        <Toolbar>
            <template #start>
                <label>Background color:</label>
                <i class="p-toolbar-separator"/>
                <i class="p-toolbar-separator"/>
                <ColorPicker
                    v-model="settings.generalSettings.backgroundColor"
                    class="p-inputtext-sm"/>
            </template>
        </Toolbar>
        <br>

        <Toolbar>
            <template #start>
                <label>Session widget size:</label>
                <i class="p-toolbar-separator"/>
                <i class="p-toolbar-separator"/>
                <Slider
                    v-model="settings.sessionWidgetSettings.width"
                    :step="10"
                    :min="200"
                    :max="1000"
                    :style="'width: 200px'"/>
            </template>
            <template #end>
                <label>{{ sessionWidgetWidthLabel }}</label>
            </template>
        </Toolbar>
        <br>

        <Toolbar>
            <template #start>
                <label>Compact slot widget:</label>
                <i class="p-toolbar-separator"/>
                <i class="p-toolbar-separator"/>
                <InputSwitch v-model="settings.slotWidgetSettings.compact"/>
            </template>
            <template #end>
                <label>Round borders:</label>
                <i class="p-toolbar-separator"/>
                <i class="p-toolbar-separator"/>
                <SelectButton
                    v-model="settings.slotWidgetSettings.borders"
                    :options="['top', 'both', 'bottom']"
                    :disabled="!settings.slotWidgetSettings.compact"
                    class="p-button-sm p-inputtext-sm"
                    single/>
            </template>
        </Toolbar>
        <br>

        <Toolbar>
            <template #start>
                <label>Slot widget size:</label>
                <i class="p-toolbar-separator"/>
                <i class="p-toolbar-separator"/>
                <Slider
                    v-model="settings.slotWidgetSettings.width"
                    :step="10"
                    :min="300"
                    :max="1000"
                    :style="'width: 200px'"/>
            </template>
            <template #end>
                <label> {{ slotWidgetWithLabel }}</label>
            </template>
        </Toolbar>
        <br>

        <Toolbar>
            <template #start>
                <label>Slot widget follows active slot:</label>
                <i class="p-toolbar-separator"/>
                <i class="p-toolbar-separator"/>
                <InputSwitch v-model="settings.generalSettings.followToggler"/>
            </template>
        </Toolbar>
        <br>

        <Toolbar>
            <template #start>
                <label>TOP WIN + TOP X</label>
                <i class="p-toolbar-separator"/>
                <InputSwitch v-model="settings.sessionWidgetSettings.showLosers"/>
                <i class="p-toolbar-separator"/>
                <label>TOP WIN + WORST WIN</label>
                <i class="p-toolbar-separator"/>
                <i class="p-toolbar-separator"/>
                <label>in widget leaders bar</label>
            </template>
        </Toolbar>
        <br>

        <Toolbar>
            <template #start>
                <label>Two columns</label>
                <i class="p-toolbar-separator"/>
                <InputSwitch v-model="settings.generalSettings.pvpAccordionView"/>
                <i class="p-toolbar-separator"/>
                <label>Accordion</label>
                <i class="p-toolbar-separator"/>
                <i class="p-toolbar-separator"/>
                <label> in PvP rounds</label>
            </template>
        </Toolbar>
        <br>

        <Card>
            <template #header>
                <Toolbar>
                    <template #start>
                        <label>Links for widgets on separated pages (OBS etc.)</label>
                    </template>
                </Toolbar>
            </template>
            <template #content>
                <WidgetLinkField/>
            </template>
        </Card>

        <br>
        <Button
            class="p-button-success p-button-sm"
            label="Save settings"
            icon="pi pi-save"
            @click="saveWidgetSettings"/>

    </div>
</template>

<script>
    import ColorPicker from 'primevue/colorpicker'
    import Toolbar from 'primevue/toolbar'
    import Slider from 'primevue/slider'
    import Button from 'primevue/button'
    import InputSwitch from 'primevue/inputswitch'
    import SelectButton from 'primevue/selectbutton'
    import {fixDecimal} from '../utils/formatters'
    import WidgetLinkField from '../components/misc/WidgetLinkField.vue'
    import Card from 'primevue/card'

    export default {
        name: 'WidgetSettings',

        components: {
            Card,
            WidgetLinkField,
            ColorPicker,
            Slider,
            Toolbar,
            Button,
            InputSwitch,
            SelectButton,
        },

        computed: {
            settings: {
                get() {
                    return this.$store.getters['settings/settings']
                },
                set(newValue) {
                    console.log(newValue)
                    this.$store.dispatch('settings/updateSettings', newValue)
                },
            },

            width: {
                get() {
                    return this.$store.getters['settings/sessionWidgetWidth']
                },
                set(event, value) {
                    console.log(event)
                    this.$store.dispatch('settings/setWidth', value)
                },
            },

            showWidgets: {
                get() {
                    return this.$store.getters['settings/showWidgets']
                },
                set(value) {
                    this.$store.dispatch('settings/setShowWidgets', value)
                },
            },

            sessionWidgetWidthLabel() {
                return `width: ${this.settings.sessionWidgetSettings.width}px, height: ${this.settings.sessionWidgetSettings.width}px`
            },

            slotWidgetWithLabel() {
                const height = fixDecimal(this.settings.slotWidgetSettings.width / (this.settings.slotWidgetSettings.compact ? 12.5 : 3), 0)
                return `width: ${this.settings.slotWidgetSettings.width}px, height:${height}px`
            },
        },

        methods: {

            saveWidgetSettings() {
                this.$store
                    .dispatch('settings/saveSettings')
                    .then((response) => {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Save!',
                            detail: 'Your settings saved. Autoload next time',
                            life: 1000,
                        })
                    })
                    .catch((error) => {
                    })
            },
        },
    }

</script>

<style lang="scss" scoped>
    .widget-settings {
        padding: 8px;
        height: 100vh;
        overflow-y: auto;
    }

    :deep .p-toolbar,
    :deep .p-card .p-card-body,
    :deep .p-card .p-card-content {
        padding: 0.5rem;
    }
</style>
