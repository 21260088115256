<template>
    <div class="battle-section">
        <hr>
        <div class="flex flex-row gap-5 pt-3">
            <span class="p-float-label">
                <InputNumber
                    id="inputBuys"
                    :min="1"
                    :max="6"
                    showButtons
                    v-model="battleData.spins"
                    class="p-inputtext-sm"/>
                <label for="inputBuys">Buys of each bonus</label>
            </span>

        </div>
        <hr>

        <div class="flex flex-column gap-2">
            <div v-for="(slot, i) in battleData.slots"
                 :key="i">
                <CompetitorSelector
                    :competitor-order="i+1"
                    session-type="bonus_battle"
                    v-model="battleData.slots[i].slot">

                    <template #competitor-price>
                        <InputNumber
                            :min="0"
                            :maxFractionDigits="2"
                            showButtons
                            size="12"
                            v-model="battleData.slots[i].price"
                            class="p-inputtext-sm"
                            :placeholder="`Bonus #${i+1} price`"/>
                    </template>

                    <template #competitor-bet>
                        <InputNumber
                            v-model="battleData.slots[i].bet"
                            class="p-inputtext-sm"
                            :placeholder="`Bonus #${i+1} bet`"
                            mode="decimal"
                            size="12"
                            :min="0"
                            showButtons
                            :maxFractionDigits="2"/>
                    </template>

                    <div class="color">
                        <Dropdown v-model="battleData.slots[i].color" :options="iconOptions" class="p-inputtext-sm">
                            <template #value="slotProps">
                                <competitor-literal-icon :letter="slotProps.value"/>
                            </template>
                            <template #option="slotProps">
                                <competitor-literal-icon :letter="slotProps.option"/>
                            </template>
                        </Dropdown>
                    </div>

                </CompetitorSelector>
            </div>
        </div>
    </div>
</template>

<script>
    import ColorPicker from 'primevue/colorpicker'
    import InputNumber from 'primevue/inputnumber'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import SlotsManagerWrapper from '../slots-manager/SlotsManagerWrapper.vue'
    import InputSwitch from 'primevue/inputswitch'
    import CompetitorSelector from './CompetitorSelector.vue'
    import CompetitorLiteralIcon from '../../components/misc/CompetitorLiteralIcon.vue'

    export default {
        components: {
            CompetitorLiteralIcon,
            CompetitorSelector, InputSwitch, SlotsManagerWrapper, InputNumber, ColorPicker, Button, Dropdown,
        },

        props: {
            modelValue: {
                type: Object,
                default: {},
            },
        },

        data() {
            return {
                iconOptions: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'],
            }
        },

        computed: {
            battleData: {
                get() {
                    return this.modelValue
                },
                set(newValue) {
                    this.$emit('update:modelValue', newValue)
                },
            },
        },
        methods: {
            generateBattleCompetitors() {
                this.battleData.slots = Array.from({length: 2}, (_, index) => ({
                    slot: {},
                    price: null,
                    bet: null,
                    color: index === 0 ? 'A' : 'B',
                }))
            },
        },
        mounted() {
            this.generateBattleCompetitors()
        },
    }
</script>

<style lang="scss" scoped>
    .color {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;

        p {
            margin: 0;
        }
    }
</style>
