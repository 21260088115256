<template>
    <div class="slots-list">
        <DataTable
            :value="searchResults"
            dataKey="id"
            :selection-mode="selectMode"
            v-model:selection="selectedSlots"
            :metaKeySelection="false"
            :scrollable="true"
            :loading="loading"
            responsiveLayout="scroll"
            class="p-datatable-sm">

            <template #header>
                <div class="table-header">

                    <span class="p-input-icon-left">
                         <i class="pi pi-search"/>
                         <InputText
                             v-model="searchSlot"
                             autofocus
                             placeholder="Search..."
                             class="p-inputtext-sm"
                             @input="searchWithDebounce"
                             ref="searchField"/>
                    </span>

                </div>
            </template>

            <Column field="attributes.title" header="Name" :sortable="true"/>
            <Column field="attributes.provider.title" header="Provider" :sortable="true"/>

            <template #empty>
                <span v-if="searchSlot == null">
                    Start with searching slots in the field above
                </span>
                <span v-else-if="!loading && !searchResults.length">
                    Nothing found :(
                </span>
            </template>

            <template #footer>
                <Paginator
                    :rows="15"
                    :totalRecords="pagination.total"
                    @page="pageChange"
                    :alwaysShow="false"/>
            </template>
        </DataTable>

    </div>
</template>

<script>
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import InputText from 'primevue/inputtext'
    import Button from 'primevue/button'
    import Dialog from 'primevue/dialog'
    import Paginator from 'primevue/paginator'
    import _ from 'lodash'
    import SlotsManagerCreateNewSlot from './SlotsManagerCreateNewSlot.vue'

    export default {
        name: 'SlotsManagerAddSlot',

        components: {
            SlotsManagerCreateNewSlot,
            DataTable,
            Column,
            InputText,
            Button,
            Dialog,
            Paginator,
        },

        props: {
            single: {
                default: false,
            },
        },

        data() {
            return {
                selectedSlots: [],
                searchSlot: null,
                pagination: {
                    total: null,
                    lastPage: null,
                    currentPage: null,
                },
            }
        },

        computed: {
            loading() {
                return this.$store.getters['slots/loading']
            },
            searchResults() {
                return this.$store.getters['slots/searchResults']
            },
            selectMode() {
                return this.single ? 'single' : 'multiple'
            },
            sessionId() {
                return this.$store.getters['sessions/activeSession'].session_id ?? null
            },
        },

        watch: {
            selectedSlots() {
                this.$emit('new-selected', this.selectedSlots)
            },
        },

        mounted() {
            this.$refs.searchField.$el.focus()
            this.$store.dispatch('slots/clearSearchResults')
        },

        methods: {
            searchWithDebounce: _.debounce(function () {
                this.fetchSlots()
            }, 500),

            setSearchValue(value) {
                this.searchSlot = value
            },

            fetchSlots(page) {
                if (this.searchSlot) {
                    const payload = {
                        searchValue: this.searchSlot,
                        page: page ? page + 1 : this.pagination.currentPage,
                        id: this.sessionId,
                    }

                    this.$store.dispatch('slots/fetchSearched', payload)
                        .then((response) => {
                            this.pagination.total = response.data.meta.page['total']
                            this.pagination.currentPage = response.data.meta.page['current-page']
                            this.pagination.lastPage = response.data.meta.page['last-page']
                        })
                        .catch((error) => {
                            this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: 2000})
                        })
                }
            },

            pageChange(e) {
                this.fetchSlots(e.page)
            },

        },
    }
</script>

<style lang="scss" scoped>
    :deep .dialog-create {
        width: 100%;
        height: 100%;

        .p-dialog {
            height: 50%;
            width: 95%;
        }
    }

    .table-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .p-dialog .p-dialog-footer button {
        width: 3em !important;
    }
    :deep tr[data-p-highlight="true"] {
        background-color: rgb(143 97 246 / 29%); /* Apply your desired background color */
    }

</style>
