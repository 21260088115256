<template>
    <router-view/>
</template>

<script>

    export default {
        name: 'OBSLayout',
    }
</script>
