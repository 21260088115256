import {fixMoney, fixPercents, numberSpacing, readableCurrency, toUpperCase} from '@utils/formatters'
import {cloneDeep} from 'lodash'

export function slotStatisticsFormatter(slot) {
    const slotData = cloneDeep(slot)
    return {
        title: slotData.info.title ? toUpperCase(slotData.info.title) : '',
        provider: slotData.info.provider_title ?? '',
        potential: slotData.info.potential ? numberSpacing(slotData.info.potential) : '',
        rtp: slotData.info.rtp ?? '',
        topWin: {
            bet: slotData.record.top_win.bet ? fixMoney(slotData.record.top_win.bet) : '',
            win: slotData.record.top_win.win ? fixMoney(slotData.record.top_win.win) : '',
            currency: slotData.record.top_win.currency ? readableCurrency(slotData.record.top_win.currency) : '',
        },
        topX: {
            bet: slotData.record.top_x.bet ? fixMoney(slotData.record.top_x.bet) : '',
            x: slotData.record.top_x.x ? fixPercents(slotData.record.top_x.x) : '',
            currency: slotData.record.top_x.currency ? readableCurrency(slotData.record.top_x.currency) : '',
        },
        avgX: {
            bets: slotData.record.avg_x.bets_qty ?? '',
            x: slotData.record.avg_x.avg_result ? fixPercents(slotData.record.avg_x.avg_result) : '',
        },
    }
}
