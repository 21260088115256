<template>
    <div
        v-if="round>0"
        class="bonus-tournament-round" >
        <PVPWrapper
            :slots="slots"
            @update:result="postResult"/>
    </div>
</template>

<script>
    import Loading from '../../Loading.vue'

    import Button from 'primevue/button'
    import PVPWrapper from '../pvp/PVPWrapper.vue'


    export default {
        name: 'TournamentRound',
        components: {
            PVPWrapper,
            Button,
            Loading,
        },

        props: {
            round: {
                type: Number,
                default: 0,
            },
        },

        computed: {

            slots() {
                if (this.round > 0) {
                    return this.$store.getters['sessionTournament/roundByNum'](this.round)
                }
            },
        },

        methods: {
            async postResult(event) {
                console.log("(TournamentRound.vue:44) ---> event:", event);
                let {data, value, newValue, field} = event
                data[field] = newValue
                if (value !== newValue) {
                    const payload = {
                        round: this.round,
                        slot_id: data.slot_id,
                        win: data.win,
                    }
                    try {
                        await this.$store.dispatch('sessionTournament/postResults', payload)
                    } catch (error) {
                        this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: 1500})
                    }

                }
            },
        },

    }
</script>

<style lang="scss" scoped>
    .bonus-battle {
        overflow-y: auto;
        height: 90vh
    }
</style>
