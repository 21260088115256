<template>
    <div class="bonus-hunt">
        <DataTable
            :value="slots"
            dataKey="id"
            class="editable-cells-table p-datatable-sm"
            editMode="cell"
            @row-reorder="onRowReorder"
            @cell-edit-complete="onCellEditComplete"
            :scrollable="true"
            scrollHeight="85vh"
            showGridlines>
            <Column
                :rowReorder="true"
                :styles="{'max-width':'4%', 'justify-content':'center'}"
                :reorderableColumn="false"/>

            <Column
                header="S"
                :styles="{'max-width':'4%', 'justify-content':'center'}"
                :exportable="true">
                <template #body="{data, index}">
                    <RadioButton
                        v-model="activeSlot"
                        :value="index"
                        @change="onActiveSlotChange"/>
                </template>
            </Column>

            <Column
                header="I"
                :styles="{'max-width':'4%', 'justify-content':'center'}">
                <template #body="{data, index}">
                    <RadioButton
                        v-model="slotStats"
                        :value="index"
                        @change="manualSlotStatsChange"/>
                </template>
            </Column>

            <Column
                field="title"
                header="Name"
                :styles="{ 'max-width': '44%', 'justify-content': 'flex-start', 'cursor': 'default' }">
                <template #body="{data, field}">
                    <table-cell-title>
                        {{ data[field] }}
                    </table-cell-title>
                </template>
            </Column>

            <Column
                header="SR"
                :styles="{'max-width':'5%', 'justify-content':'center'}">
                <template #body="{ data }">
                    <single-roll-button
                        :slot-data="data"
                        :currency="sessionCurrency"
                        @open-s-r="onSingleRollOpened"/>
                </template>
            </Column>

            <Column
                field="bet"
                header="Bet"
                :styles="{'max-width':'17%', 'justify-content':'center'}">
                <template #editor="{ data, field }">
                    <InputNumber
                        v-model="data[field]"
                        mode="decimal"
                        :maxFractionDigits="2"
                        class="p-inputtext-sm"/>
                </template>
            </Column>

            <Column
                field="win"
                header="Win"
                :styles="{'max-width':'17%', 'justify-content':'center'}">
                <template #editor="{ data, field }">
                    <InputNumber
                        v-model="data[field]"
                        class="p-inputtext-sm"
                        mode="decimal"
                        :maxFractionDigits="2"/>
                </template>
            </Column>

            <Column
                :exportable="false"
                header="Del"
                :styles="{'max-width':'5%', 'justify-content':'center'}">
                <template #body="data">
                    <delete-button
                        :slot-data="{data}"
                        :session-id="sessionId"
                        @deleted="fetchSession"/>
                </template>
            </Column>
        </DataTable>

        <slots-manager-wrapper @slots-selected="fetchSession">
            <template #title>
                Add slots from the list
            </template>
            <template #activator="{ toggleDialog }">
                <Button
                    icon="pi pi-plus"
                    label="Add slots"
                    class="p-button-outlined p-button-sm add-button"
                    @click="toggleDialog"/>
            </template>
        </slots-manager-wrapper>
    </div>
</template>

<script>
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import InputNumber from 'primevue/inputnumber'
    import RadioButton from 'primevue/radiobutton'

    import Constants from '@resources/js/constants/general.js'
    import SlotsManagerWrapper from '@pages/slots-manager/SlotsManagerWrapper.vue'
    import TableCellTitle from '../misc/Title.vue'
    import DeleteButton from '../misc/DeleteButton.vue'
    import SingleRollButton from '../misc/SingleRollButton.vue'
    import Button from 'primevue/button'

    export default {
        name: 'BonusHuntTable',

        components: {
            Button,
            SingleRollButton,
            TableCellTitle,
            DeleteButton,
            SlotsManagerWrapper,
            DataTable,
            Column,
            InputNumber,
            RadioButton,
        },

        data() {
            return {
                slots: [],
                activeSlot: 0,
                slotStats: 0,
            }
        },

        computed: {
            sessionId() {
                return this.$route.params.id
            },
            sessionStatus() {
                return this.$store.getters['sessionHB/session'].status
            },
            sessionCurrency() {
                return this.$store.getters['sessionHB/session'].currency
            },
            sessionSlots() {
                return this.$store.getters['sessionHB/slots']
            },
            activeIndex() {
                return this.$store.getters['sessionHB/session'].current_slot
            },
            slotStatsIndex() {
                return this.$store.getters['sessionHB/slotStatsIndex']
            },
            isStatsSlotFollowActive() {
                return this.$store.getters['settings/followToggler']
            },
        },

        mounted() {
            this.setSessionData()
            this.slotStats = this.slotStatsIndex
        },

        methods: {
            async fetchSession() {
                try {
                    await this.$store.dispatch('sessionHB/fetchSession', this.sessionId)
                    this.setSessionData()
                } catch (err) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Loading Session went wrong',
                        life: 1500,
                    })
                }
            },

            setSessionData() {
                this.slots = this.sessionSlots
                this.activeSlot = this.activeIndex
            },

            async updateSessionSlots() { //post new slots array, then fetch whole session, then fetch data in widget
                try {
                    const payload = {
                        slots: this.slots.map(slot => slot.slot_id),
                        active_index: this.activeSlot,
                    }
                    console.log("(BonusBuyTable.vue:218) ---> payload:", payload);

                    await this.$store.dispatch('sessionHB/changeSlots', {id: this.sessionId, payload})
                } catch (error) {
                    this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: 1500})
                } finally {
                    await this.fetchSession()
                }
            },

            onActiveSlotChange() {
                this.updateSessionSlots()
                if (this.isStatsSlotFollowActive) {
                    this.slotStats = this.activeSlot
                    this.onSlotStatsChanged()
                }
            },

            onSingleRollOpened(index) {
                this.slotStats = index
                clearTimeout(this.myTimeout)
            },

            manualSlotStatsChange() {
                clearTimeout(this.myTimeout)
                this.onSlotStatsChanged()
            },

            async onSlotStatsChanged() {
                await this.$store.commit('sessionHB/slotStatsIndex', this.slotStats)
                await this.$store.dispatch('widgetSlot/handleSlotIdChange', this.slots[this.slotStats].slot_id)
            },

            async onRowReorder(event) {
                console.log("(BonusHuntTable.vue:241) ---> event:", event);
                this.slots = await event.value
                if (event.dragIndex === this.activeSlot) {
                    this.activeSlot = await event.dropIndex
                }
                await this.updateSessionSlots()
            },

            onCellEditComplete(event) {
                console.log("(BonusHuntTable.vue:251) ---> event:", event);
                let {data, value, newValue, field} = event
                data[field] = newValue
                if (value !== newValue) {
                    this.onResultInput(data)
                }
            },

            statsSlotFollowActive(index) {
                this.activeSlot = index + 1
                this.myTimeout = setTimeout(() => {
                    this.slotStats = this.activeSlot
                    this.onSlotStatsChanged()
                }, 10000)
            },

            async onResultInput(data) {
                const payload = {
                    session_id: this.sessionId,
                    slot_id: data.slot_id,
                    session_slot_id: data.session_slot_id,
                    bet: data.bet ?? 0,
                }

                if (data.win) {
                    payload.win = data.win
                }

                if (payload.win && this.slots.length !== this.activeSlot + 1) {
                    this.statsSlotFollowActive(data.order)
                }

                try {
                    await this.$store.dispatch('sessionHB/postResults', payload)
                    await this.updateSessionSlots() //post slots array with updated active index
                    await this.setSessionStatus()
                } catch (error) {
                    this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: 1500})
                }
            },

            async setSessionStatus() {
                if (this.sessionStatus !== Constants.STATUS_INIT) {
                    return
                }
                const payload = {
                    'session_id': this.sessionId,
                    'status': Constants.STATUS_IN_PROGRESS,
                }
                try {
                    await this.$store.dispatch('sessions/changeStatus', payload)
                } catch (error) {
                    this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: 1500})
                }
            },
        },
    }
</script>

<style lang="scss" scoped>

    :deep .p-datatable-wrapper {
        overflow-x: hidden;
    }

    .pi-copy {
        color: #8b5cf6;
        margin-left: 8px;
        cursor: pointer
    }

    :deep .p-component-overlay {
        position: absolute
    }

    :deep .p-dialog-content {
        height: 100%;
    }

    :deep .p-inputtext-sm {
        width: 100%;
        height: 28px;
    }

    :deep .p-inputtext.p-component.p-inputnumber-input {
        width: 100%
    }


    .title {
        display: flex;
        justify-content: space-between;
    }

    .add-button {
        margin: 8px
    }
</style>
