<template>
  <div class="creat-user-form">
    <h2>Hello, Admin :)</h2>
    <h3>Let's create new user!</h3>
    <InputText class="p-inputtext-sm"
               v-model="email"
               placeholder="Enter email"
    />
    <i class="p-toolbar-separator"/>
    <i class="p-toolbar-separator"/>
    <InputText class="p-inputtext-sm"
               v-model="password"
               placeholder="Enter password"
    />
    <i class="p-toolbar-separator"/>
    <i class="p-toolbar-separator"/>
    <Button icon="pi pi-save" class="p-button-success p-button-sm"
            @click="createUser"
    />
  </div>
</template>

<script>
  import Button from "primevue/button"
  import InputText from "primevue/inputtext"

  export default {
    name: "CreateUser",

    components: {
      InputText,
      Button,
    },

    data() {
      return {
        email:    null,
        password: null,
      }
    },

    computed:   {
      isAdmin() {
        return this.$store.getters["admin/isAdmin"]
      },
    },
    methods:    {
      createUser() {
        const payload = {
          "email":    this.email,
          "password": this.password,
        }
        this.$store
            .dispatch("admin/createUser", payload)
            .then((response) => {
            })
            .catch((error) => {
              this.$toast.add({severity: "error", summary: "Error", detail: error, life: 2000})
            })
      },
    },
  }

</script>

<style lang="scss" scoped>

</style>