<template>
    <div class="leaders">
        <Card
            v-for="(leader, key) in sessionLeaders"
            :key="key">
            <template #header>
                {{ key }}
            </template>

            <template #title>
                {{ leader.title }}
            </template>

            <template #content>
                <div v-if="isBonusHunt">
                    <span>{{ leader.bet | combiner }}{{ currency }}</span>
                    <span :style="color(key, 'X')">x {{ leader.x | fixDecimal }}</span>
                    <span>=</span>
                    <span :style="color(key,'Win')">{{ leader.win | combiner }}{{ currency }}</span>
                </div>
                <div v-if="!isBonusHunt">
                    <span>{{ leader.price | combiner }}{{ currency }}</span>
                    <span :style="color(key, 'X')">x {{ leader.x_price | fixDecimal }}</span>
                    <span>=</span>
                    <span :style="color(key,'Win')">{{ leader.win | combiner }}{{ currency }}</span>
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
  import Card from 'primevue/card'

  export default {
        name: 'ArchiveSessionLeaderBoard',
        components: {
            Card,
        },
        computed: {
            isBonusHunt() {
              return this.$store.getters['archive/isBonusHunt']
            },

          currency() {
            return this.$store.getters['archive/readableCurrency']
          },

            sessionLeaders() {
                const customKeys = {
                    top_absolute: 'Best Win',
                    top_x: 'Best X',
                    worst_absolute: 'The Worst Win',
                    worst_x: 'The Worst X',
                }
                const nestedObj = {
                    top: this.$store.getters['archive/session'].top,
                    worst: this.$store.getters['archive/session'].worst,
                }

                return Object.keys(nestedObj)
                    .map(key => {
                        const obj = nestedObj[key]
                        return Object.keys(obj)
                            .filter(subKey => obj[subKey].win !== null && obj[subKey].win !== undefined)
                            .map(subKey => {
                                const newKey = customKeys[`${key}_${subKey}`]
                                return {[newKey]: obj[subKey]}
                            })
                    })
                    .reduce((acc, val) => Object.assign(acc, ...val), {})
            },
        },
        methods: {
            color(key, example) {
                return key.includes(example) ? 'color: #8f61f6' : ''
            },
        },
    }
</script>

<style lang="scss" scoped>
    .leaders {
        display: flex;
        flex-direction: row;
        gap: 8px;
        padding: 1rem;
        justify-content: space-around;
    }

    :deep .p-card-header {
        color: #8f61f6;
    }

    :deep .p-card {
        padding: 4px;
        width: 25%;

        .p-card-body {
            padding: 0.5rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            height: 90%;

            .p-card-content {
                padding: 0.25rem 0;
                font-size: 18px
            }
        }
    }
</style>
