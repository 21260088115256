<template>
  <div class="session-wrapper">
    <toolbar-wrapper v-if="$route.name !== 'session-creator'" />
    <router-view/>
  </div>
</template>

<script>
  import ToolbarWrapper from "@components/session-toolbars/ToolbarWrapper.vue"

  export default {
    name: "SessionWrapper",

    components: {
      ToolbarWrapper,
    },
  }
</script>
<style lang="scss" scoped>

</style>