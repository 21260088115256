<script setup>
    import {computed, ref} from "vue";
    import Button from "primevue/button";
    import InputText from "primevue/inputtext";
    import ColorPicker from "primevue/colorpicker";
    import FileUploader from "@components/misc/FileUploader.vue";
    import {emptyPlayer} from "@components/games/slotopoly/defaultSlotopolyEntries.js";
    import {v4 as uuidv4} from 'uuid'
    import FileSelect from "@components/misc/file-upload/FileSelect.vue";

    const props = defineProps(['modelValue'])
    const emit = defineEmits(['editor:save', 'editor:close', 'player:add', 'player:remove', 'update:modelValue'])

    const localPlayers = ref(props.modelValue)

    const players = computed({
        get: () => props.modelValue,
        set: (value) => {
            localPlayers.value = value
            emit("update:modelValue", localPlayers.value);
        }
    })

    const addPlayer = () => {
        players.value.push({...emptyPlayer, id:uuidv4()})
    }

</script>

<template>
    <div class="players-list">

        <div v-for="(player, i) in players" :key="i" class="player">
            <InputText v-model="player.name" :placeholder="player.name" class="p-inputtext-sm"/>
            <InputText v-model.number="player.balance" :placeholder="player.balance" class="p-inputtext-sm"
                       type="number"/>

<!--            <FileUploader @image:uploaded="player.image = $event"/>-->
            <file-select v-model="player.image" type="avatars"/>



            <ColorPicker v-model="player.color" class="p-inputtext-sm"/>
            <Button icon="pi pi-trash" class="p-button-sm ml-4" @click="players.splice(i, 1)"/>
        </div>
        <Button icon="pi pi-plus" class="p-button-sm" @click="addPlayer()" v-if="players.length < 6"/>

    </div>
</template>

<style lang="scss" scoped>
    .players-list {
        display: flex;
        flex-direction: column;
        gap: 8px
    }

    .player {
        display: flex;
        flex-direction: row;
        gap: 8px;

        .p-colorpicker {
            display: flex;
            align-items: center;
        }
    }
</style>