<template>
    <div class="winner text-big"
         :style="{ opacity: winner.slot_id ? 1 : 0.8}">
        <div class="flex justify-content-evenly" v-if="winner.slot_id">
            <span>🏆</span>
            {{winner.slot_title}}
            <span>🏆</span>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            winner: {
                type: Object,
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../../../../assets/css/variables';
    .winner {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0.2em;
        border-radius: $border-radius-inner;
        border: 1px solid white;
        background-color: goldenrod;
        color: white;
        justify-content: center;
    }
</style>
