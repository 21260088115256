<template>
    <div class="widget">
        <div class="text-big">
            Your subscription plan does not include {{type}} widget
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            type: {
                type: String,
                default: 'this',
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../../../assets/css/variables';

    .widget {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        padding: $container-padding;
        color: white;
        background-color: $outer-wrapper-bg-color;
        border-radius: $border-radius-outer;
        cursor: default;
        gap: $container-padding;
        flex: 0 1 auto;
    }
</style>
