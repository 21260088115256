<template>
    <div class="single-roll-wrapper">
        <Toolbar>
            <template #start>
        <span
            v-if="slotData.title"
            class="inline-flex align-items-center">
              <table-cell-title>
                <h3>
                  {{ slotData.title }}
                </h3>
              </table-cell-title>
        </span>
                <h3 v-else>Good luck, mate!</h3>
            </template>

            <template #end>
                <i class="p-toolbar-separator"/>

                <slots-manager-wrapper
                    :session-type="'single_roll'"
                    @slots-selected="onSlotSelected"
                    ref="slotsManager">
                    <template #title>
                        Chose slot for the Single roll
                    </template>
                    <template #activator="{ toggleDialog }">
                        <Button
                            :icon="slotData.id  ? 'pi pi-undo' : 'pi pi-plus'"
                            :label="slotData.id  ? 'Select another slot' : 'Select slot'"
                            class="p-button-outlined p-button-sm add-button"
                            @click="toggleDialog"/>
                    </template>

                </slots-manager-wrapper>
            </template>
        </Toolbar>

        <Toolbar v-if="slotData.id">
            <template #start>
                <CurrencySelect
                    v-model="selectedCurrency"
                    :style="'height: 100%; width: 25%'"/>
                <i class="p-toolbar-separator"/>
                <InputNumber
                    v-model="form.price"
                    class="p-inputtext-sm"
                    placeholder="price"
                    :style="'height: 100%; width: 25%'"
                    mode="decimal"
                    :maxFractionDigits="2"/>
                <i class="p-toolbar-separator"/>

                <InputNumber
                    v-model="form.bet"
                    class="p-inputtext-sm"
                    placeholder="bet"
                    :style="'height: 100%; width: 25%'"
                    mode="decimal"
                    :maxFractionDigits="2"/>
                <i class="p-toolbar-separator"/>

                <InputNumber
                    v-model="form.win"
                    class="p-inputtext-sm"
                    placeholder="win"
                    :style="'height: 100%; width: 25%'"
                    mode="decimal"
                    :maxFractionDigits="2"/>
            </template>
            <template #end>
                <Button
                    icon="pi pi-play"
                    label="Submit"
                    class="p-button-success p-button-sm"
                    @click="saveResult"
                    :style="'height: 100%'"/>
            </template>
        </Toolbar>
        <div v-if="slotHistory.length">
        <DataTable
            :value="slotHistory"
            editMode="row"
            v-model:editingRows="editingRows"
            @row-edit-save="onRowEditSave"
            class="p-datatable-sm"  >

            <Column header="Date">
                <template #body="{data}">
                    {{readableDate(data) }}
                    <!--                    {{ slotProps.data.updated_at | truncate(10) }}-->
                </template>
            </Column>

            <Column header="Price" field="price" :styles="{'max-width':'17%', 'justify-content':'center'}">
                <template #editor="{ data, field }">
                    <InputNumber
                        mode="decimal"
                        :maxFractionDigits="2"
                        v-model="data[field]"
                        class="p-inputtext-sm"/>
                </template>

                <template #body="{data, field}">
                    {{ data[field] }}
                    <!--                    {{ slotProps.data.price | combiner }}-->
                </template>
            </Column>

            <Column header="Bet" field="bet" :styles="{'max-width':'17%', 'justify-content':'center'}">
                <template #editor="{data, field}">
                    <InputNumber
                        mode="decimal"
                        :maxFractionDigits="2"
                        v-model="data[field]"
                        class="p-inputtext-sm"/>
                </template>

                <template #body="{data, field}">
                    {{ data[field] }}
                    <!--                    {{ slotProps.data.bet | combiner }}-->
                </template>
            </Column>

            <Column header="Win" field="win" :styles="{'max-width':'17%', 'justify-content':'center'}">
                <template #editor="{data, field}">
                    <InputNumber
                        mode="decimal"
                        :maxFractionDigits="2"
                        v-model="data[field]"
                        class="p-inputtext-sm"/>
                </template>

                <template #body="{data, field}">
                    {{ data[field] }}
                </template>
            </Column>

            <Column header="Currency" field="currency">
                <template #body="{data, field}">
                    {{ readableRollCurrency(data[field]) }}

                </template>
                <template #editor="{data, field}">
                    <CurrencySelect
                        v-model="data[field]"
                        :style="'height: 100%; width: 75%'"/>
                </template>
            </Column>

            <Column header="X" field="x">
                <template #body="{data, field}">
                    {{ data[field] }}
                </template>
            </Column>

            <Column
                :rowEditor="true"
                :styles="{width:'10%', 'min-width':'8rem'}"
                :bodyStyle="{'text-align':'center'}"/>

            <Column #body="data">
                <SingleRollDeleteButton
                    :slot-data="data"
                    @deleted="fetchSlotHistory"/>
            </Column>

        </DataTable>
        </div>

    </div>
</template>

<script>
    import Dialog from 'primevue/dialog'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import Toolbar from 'primevue/toolbar'
    import Button from 'primevue/button'
    import InputNumber from 'primevue/inputnumber'
    import Dropdown from 'primevue/dropdown'
    import SlotsManagerWrapper from './slots-manager/SlotsManagerWrapper.vue'
    import TableCellTitle from '../components/misc/Title.vue'
    import CurrencySelect from '../components/misc/CurrencySelect.vue'
    import SingleRollDeleteButton from '../components/misc/SingleRollDeleteButton.vue'
    import {readableCurrency} from '../utils/formatters'

    export default {
        name: 'SingleRoll',

        components: {
            SingleRollDeleteButton,
            CurrencySelect,
            TableCellTitle,
            SlotsManagerWrapper,
            DataTable,
            Column,
            Toolbar,
            Button,
            InputNumber,
            Dialog,
            Dropdown,
        },

        data() {
            return {
                form: {},
                selectedCurrency: null,
                editingRows: [],
            }
        },

        computed: {
            slotData() {
                return this.$store.getters['singleRoll/slot']
            },

            slotHistory() {
                return this.$store.getters['singleRoll/slotHistory']
            },

        },

        mounted() {
            if (!this.slotData.id) {
                this.$refs.slotsManager.toggleDialog()
            } else {
                this.fetchSlotHistory()
                this.selectedCurrency = this.slotData.currency
            }
        },

        destroyed() {
            this.$store.dispatch('singleRoll/clearData')
        },

        methods: {
            onSlotSelected() {
                this.fetchSlotHistory()
                this.cleanInputs()
            },

            cleanInputs() {
                this.form = {}
            },

            async fetchSlotHistory() {
                const id = this.slotData.id
                await this.$store.dispatch('singleRoll/fetchSlotHistory', id)
                await this.$store.dispatch('widgetSlot/handleSlotIdChange', id)
            },

            saveResult() {
                const payload = {
                    'slot_id': this.slotData.id,
                    'bet': this.form.bet,
                    'win': this.form.win,
                    'currency': this.selectedCurrency,
                }

                if (this.form.price) {
                    payload['price'] = this.form.price
                }

                if (this.form.bet && this.form.win && this.selectedCurrency) {
                    this.$store
                        .dispatch('singleRoll/postResult', payload)
                        .then((response) => {
                            this.fetchSlotHistory()
                            this.cleanInputs()
                        })
                        .catch((error) => {
                        })
                } else
                    this.$toast.add({
                        severity: 'warn',
                        summary: 'Sorry:(',
                        detail: 'You miss some information',
                        life: 3000,
                    })
            },

            onRowEditSave(e) {
                const value = {
                    'id': e.data.id,
                    'payload': {
                        'bet': e.newData.bet,
                        'price': e.newData.price ?? 0,
                        'win': e.newData.win,
                        'currency': e.newData.currency,
                    },
                }
                this.$store
                    .dispatch('singleRoll/changeEntry', value)
                    .then((response) => {
                        this.fetchSlotHistory()
                    })
                    .catch((error) => {
                    })
            },
            readableRollCurrency(data) {
                return readableCurrency(data)
            },

            readableDate(data) {
                return data.updated_at.slice(0, 10)
            }
        },
    }
</script>

<style lang="scss" scoped>
    h3 {
        margin: 0;
        cursor: default;
    }

    .single-roll-wrapper {
        height: 100%;
    }

    :deep .p-datatable-wrapper {
        height: 100%;
    }

    :deep .p-inputtext-sm {
        width: 100%;
        height: 28px;
    }

    :deep .p-inputtext.p-component.p-inputnumber-input {
        width: 100%
    }
</style>
