import {sessionHBRoutes} from '@api/api-routes'
import {bucksToCents, centsToBucks} from '@utils/moneyConverters'
import {v4 as uuidv4} from 'uuid'
import axios from "@api/axios";

const resetState = (state) => {
    Object.assign(state, getDefaultState())
}

const getDefaultState = () => {
    return {
        session: {},
        slots: [],
        slotStatsIndex: 0,
	}
}

export default {
    namespaced: true,

    state: {
        session: {},
        slots: [],
        slotStatsIndex: 0,
    },

    getters: {
        session: state => state.session,
        slots: state => state.slots,
        slotStatsIndex: state => state.slotStatsIndex,
        slotsIDs: state => state.slots.map(slot => slot.slot_id),

        showWidgets: (state, getters, rootState) => {
            return rootState.settings.showWidgets
        },
    },

    mutations: {
        session: (state, session) => state.session = session,
        slots: (state, slots) => state.slots = slots,
        activeSlot: (state, index) => Vue.set(state.session, 'current_slot', index),
        slotStatsIndex: (state, index) => state.slotStatsIndex = index,
        resetState,
    },

    actions: {
        async createSession({commit, dispatch}, payload) {
            try {
                payload.fe_sign = uuidv4()

                const {data} = await axios.post(sessionHBRoutes.create, payload)
                commit('session', data)

                dispatch('widgetHB/fetchSessionData', data.id, {root: true})

				return data
			} catch (error) {
				throw error
			}
		},

		async fetchSession({getters, commit, dispatch}, id) {
            try {
                const {data} = await axios.get(sessionHBRoutes.session(id))
                commit('slots', await centsToBucks(data.slots))
                commit('session', await centsToBucks(data.session_info))
                if (getters.showWidgets) {
                    dispatch('widgetHB/fetchSessionData', id, {root: true})
                }

                return data
            } catch (error) {
                console.log(error)
                throw error
            }
		},

		async addSlots({state, dispatch}, payload) {
			payload.active_index = state.session.current_slot ?? 0
            const session_id = state.session.id

            try {
                return await axios.post(sessionHBRoutes.addSlots(session_id), payload)
            } catch (error) {
                console.log(error)
                throw error
            }
        },

        async changeSlots({getters, state}, {id, payload}) { //reorder, change active slot

            try {
                return await axios.post(sessionHBRoutes.addSlots(id), payload)
            } catch (error) {
                console.log(error)
                throw error
            }
        },

        async removeSlot({state, dispatch}, session_slot_id) {
            try {
                return await axios.delete(sessionHBRoutes.removeSlot(session_slot_id))
            } catch (error) {
                console.log(error)
                throw error
            }
        },

        async postResults({state}, payload) {
            try {
                const centsPayload = await bucksToCents(payload)
                return await axios.post(sessionHBRoutes.postResult, centsPayload)
            } catch (error) {
                console.log(error)
                throw error
            }
        },
    },
}
