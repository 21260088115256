import {userRoutes, WidgetPublicApiRoutes} from '@api/api-routes'
import axios from "@api/axios";

export default {
    namespaced: true,

    state: {
        showWidgets: false, //test
        settings: {
            generalSettings: {
                backgroundColor: '57cf4c',
                followToggler: true,
                pvpAccordionView: false
            },
            sessionWidgetSettings: {
                width: 400, //ratio: 1:1
                showLosers: false,
            },
            slotWidgetSettings: {
                width: 500, //ratio: compact ? (width / 12.5) : (width * 0.3)
                compact: false,
                borders: 'both',
            },
            gamesSettings: {
                storageSessionId: null
            }
        },

    },

    getters: {
        showWidgets: state => state.showWidgets,
        settings: state => state.settings,

        bgColor: (state, getters) => getters.settings.generalSettings.backgroundColor,
        sessionWidgetWidth: (state, getters) => getters.settings.sessionWidgetSettings.width,
        showLosers: (state, getters) => getters.settings.sessionWidgetSettings.showLosers,
        slotWidgetWidth: (state, getters) => getters.settings.slotWidgetSettings.width,
        compactSlotWidget: (state, getters) => getters.settings.slotWidgetSettings.compact,
        compactSlotWidgetBorders: (state, getters) => getters.settings.slotWidgetSettings.borders,
        followToggler: (state, getters) => getters.settings.generalSettings.followToggler,
        pvpAccordionView: (state, getters) => getters.settings.generalSettings.pvpAccordionView,
        gameStorageSessionId: (state, getters) => state.settings.gamesSettings.storageSessionId,
    },

    mutations: {
        SET_SHOW_WIDGETS: (state, value) => state.showWidgets = value,
        setSetting: (state, newSettings) => state.settings = newSettings,
        setGameStorageSessionId: (state, id) => state.settings.gamesSettings.storageSessionId = id,
    },

    actions: {
        setGameStorageSessionIdAction({commit}, id) {
            commit('setGameStorageSessionId', id)
        },

        setShowWidgets({commit}, value) {
            commit('SET_SHOW_WIDGETS', value)
        },

        updateSettings({getters, commit}, payload) {
            const newSettings = {...getters.settings, ...payload}
            commit('setSetting', newSettings)
        },


        setWidth({commit}, newValue) {
            commit('width', newValue)
        },

        //private api
        async fetchPrivateWidgetSettings({state, commit}) {
            try {
                const {data} = await axios.get(userRoutes.getSettings)
                // commit('SET_SETTINGS', data.widget_settings)
                return data
            } catch (error) {
                console.log(error)
                throw error
            }
        },

        //public api
        async fetchWidgetSettings({state, commit}) {
            try {
                const {data} = await axios.get(WidgetPublicApiRoutes.fetchWidgetSettings)
                commit('setSetting', data.widget_settings)
                commit('user/I_FETCHED', data.user_data, {root: true})
                return data
            } catch (error) {
                console.log(error)
                throw error
            }
        },

        async saveSettings({state, getters}) {
            const payload = {
                widget_settings: getters.settings,
            }
            try {
                return await axios.put(userRoutes.saveSettings, payload)
            } catch (error) {
                throw error
            }
        },
    },
}
