<template>
    <div class="tables-side-by-side">
        <div class="archive-battle-table" v-for="(slot, i) in results" :key="i">
            <DataTable :value="slot" responsiveLayout="scroll" class="p-datatable-sm">
                <Column field="bet" header="Bet">
                    <template #body="slotProps">
                        <span>{{ fixMoney(slotProps.data.bet) }}</span>
                        <span v-if="slotProps.bet">{{ currency }}</span>
                    </template>
                </Column>

                <Column field="price" header="Price">
                    <template #body="slotProps">
                        <span>{{ fixMoney(slotProps.data.price) }}</span>
                        <span v-if="slotProps.data.price">{{ currency }}</span>
                    </template>
                </Column>

                <Column field="x" header="X" :sortable="true">
                    <template #body="slotProps">
                        <div v-if="slotProps.data.x_price">
                            <i
                                :class="`pi pi-arrow-circle-${xArrow(slotProps.data)}`"
                                :style="{ color: xArrow(slotProps.data) === 'up' ? 'green' : 'red' }"
                            />
                            <span> x {{ fixPercents(slotProps.data.x_price) }}</span>
                        </div>
                    </template>
                </Column>

                <Column field="win" header="Win" :sortable="true">
                    <template #body="slotProps">
                        <span>{{ fixMoney(slotProps.data.win) }}</span>
                        <span v-if="slotProps.data.win">{{ currency }}</span>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
</template>

<script setup>
    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';
    import { useFilters } from '@composables/useFilters';

    const props = defineProps({
        results: {
            type: Array,
            required: true,
        },
        currency: {
            type: String,
            required: true,
        },
    });

    const { fixMoney,  fixPercents } = useFilters();

    const xArrow = (data) => {
        return data.x_price >= 1 ? 'up' : 'down';
    };
</script>

<style lang="scss" scoped>
    :deep .top-win {
        color: red;
    }

    :deep .loser-win {
        color: blue;
    }

    .tables-side-by-side {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;

        .archive-battle-table {
            width: 50%;

            &:first-child {
                border-right: 1px solid #8f61f6;
            }
        }
    }
</style>
