<template>
    <div class="battle-section">
        <hr>
        <div class="flex flex-row gap-5 pt-3">
            <span class="p-float-label">
                <InputNumber
                    id="inputBuys"
                    :min="1"
                    :max="6"
                    showButtons
                    v-model="tournamentData.spins"
                    class="p-inputtext-sm"/>
                    <label for="inputBuys">
                        Buys of each bonus
                    </label>
            </span>
        </div>
        <hr>

        <div class="flex h-1rem align-items-center justify-content-between mb-3" >
            <p>Tournament competitors:</p>
            <Button
                class="p-button p-button-sm h-1rem"
                label="Shuffle Competitors"
                icon="pi pi-sync"
                @click="shuffleArray"/>
        </div>

        <VueDraggable
            class="flex flex-column gap-2"
            v-model="tournamentData.slots">

            <div class="competitor"
                 v-for="(slot, index) in tournamentData.slots"
                 :key="index">

                <CompetitorSelector
                    :competitor-order="index+1"
                    session-type="bonus_tournament"
                    v-model="tournamentData.slots[index]">

                    <template #competitor-price>
                        <InputNumber
                            :min="0"
                            :maxFractionDigits="2"
                            showButtons
                            size="12"
                            v-model="tournamentData.slots[index].price"
                            class="p-inputtext-sm"
                            :placeholder="`Bonus #${index+1} price`"/>
                    </template>

                    <template #competitor-bet>
                        <InputNumber
                            v-model="tournamentData.slots[index].bet"
                            class="p-inputtext-sm"
                            :placeholder="`Bonus #${index+1} bet`"
                            mode="decimal"
                            size="12"
                            :min="0"
                            showButtons
                            :maxFractionDigits="2"/>
                    </template>

                    <div class="flex flex-column justify-content-between align-items-center">
                        <span>
                            Group: {{ Math.floor(index / 2) + 1 }}
                        </span>
                        <competitor-literal-icon :letter="icons[index]"/>
                    </div>
                </CompetitorSelector>
            </div>
        </VueDraggable>
    </div>
</template>

<script>
    import Button from 'primevue/button'
    import InputNumber from 'primevue/inputnumber'
    // import draggable from 'vuedraggable'
    import CompetitorSelector from './CompetitorSelector.vue'
    import Dropdown from 'primevue/dropdown'
    import CompetitorLiteralIcon from '../../components/misc/CompetitorLiteralIcon.vue'
    import { VueDraggable } from 'vue-draggable-plus'

    export default {
        components: {CompetitorLiteralIcon, Dropdown, CompetitorSelector, Button, InputNumber,
            // draggable,
            VueDraggable},

        props: {
            modelValue: {
                type: Object,
                default: {},
            },
        },

        data() {
            return {
                icons: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'],
            }
        },

        computed: {
            tournamentData: {
                get() {
                    return this.modelValue
                },
                set(newValue) {
                    this.$emit('update:modelValue', newValue)
                },
            },
        },

        methods: {
            generateTournamentCompetitors() {
                this.tournamentData.slots = Array.from({length: 8}, () => ({price: null, bet: null,}))
            },

            shuffleArray() {
                const original = [...this.tournamentData.slots]

                for (let i = original.length - 1; i > 0; i--) {
                    const j = Math.floor(Math.random() * (i + 1));
                    [original[i], original[j]] = [original[j], original[i]]
                }

                this.tournamentData.slots = original
            },
        },

        created() {
            this.generateTournamentCompetitors()
        },
    }
</script>

<style lang="scss" scoped>

    .competitor {
        cursor: grab;

        &:nth-child(2n) {
            margin-bottom: 16px;
        }
    }
</style>
