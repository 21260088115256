<template>
  <div class="widget">
    <div class="header">
      <div class="info-section">
        <div class="title">{{session.session_type}}</div>
        <div class="text-gray">#</div>
        <div class="number">{{ session.serial }}</div>
      </div>
      <div class="currency">
        <div class="currency-icon">{{ session.currency }}</div>
      </div>
    </div>
    <div class="session-info">
      <div class="bonus-qty">
        <div class="Bonus">Bonus</div>
        <div class="Number">{{ session.bonusQty }}</div>
      </div>
      <div class="win-dep">
        <div class="WinDep">Win / Dep</div>
        <div class="Number">{{ session.winDepRatio }}</div>
      </div>
      <div class="avg">
        <div class="AvgX">Avg. X</div>
        <div class="Number">{{ session.formattedAvgX }}</div>
      </div>
    </div>
    <div class="leader">
      <div class="title" v-show="leaders.leaderWin.show">
        <div class="name">🥇 {{ leaders.leaderWin.title }}</div>
      </div>
      <div class="leader-scores" v-show="leaders.leaderWin.show">
        <div class="bet">{{ leaders.leaderWin.bet }}</div>
        <div class="factor">
          <span class="x-symbol">x</span>
          <span class="value">{{ leaders.leaderWin.x }}</span>
        </div>
        <div class="win">{{ leaders.leaderWin.win }}</div>
      </div>
    </div>
    <div class="session-widget-slots-table  text-small">
      <div class="widget-table-row"
           v-for="(slot) in slots"
           :key="slot.order"
           :class="setActive(slot)"
           :ref="setActive(slot)">
        <span class="title">{{ slot.title }}</span>
        <div class="slot-results">
          <span v-show="!!slot.bet" class="bet">{{ slot.bet }}</span>
          <span v-show="!!slot.win" class="win">{{ slot.win }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SessionWidgetLux',

    props: {
      session: {
        type: Object,
      },
      leaders: {
        type: Object,
      },
      slots: {
        type: Array,
      },
    },

    watch: {
      slots: {
        handler() {
          if (!!this.slots.length) {
            this.scrollToActiveSlot()
          }
        },
        deep: true,
      },
    },

    created() {
      const montserratLink = document.createElement('link');
      montserratLink.href = 'https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap';
      montserratLink.rel = 'stylesheet';

      const interLink = document.createElement('link');
      interLink.href = 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap';
      interLink.rel = 'stylesheet';

      document.head.appendChild(montserratLink);
      document.head.appendChild(interLink);
    },

    methods: {
      setActive(slot) {
        if (slot.is_active === 1) {
          return 'active'
        }
      },

      scrollToActiveSlot() {
        this.$nextTick(() => {
          const targetElement = this.$refs.active[0]
          if (targetElement && targetElement.scrollIntoView) {
            targetElement.scrollIntoView({behavior: 'smooth', block: 'center'})
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>

  .widget {
    width: 432px;
    height: 387px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    display: flex;
  }

  .header {
    display: flex;
    width: 431px;
    height: 43px;
    padding: 12px 17px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    border-radius: 15px;
    background-size: cover;
    background: url(./img.png) no-repeat center center;
  }

  .info-section {
    justify-content: center;
    align-items: center;
    gap: 2px;
    display: flex;
    padding-left: 27%;

    .title {
      color: white;
      font-size: 21px;
      font-family: Montserrat, serif;
      font-weight: 700;
      line-height: 20px;
      word-wrap: break-word;
    }


    .number {
      color: white;
      font-size: 21px;
      font-family: Montserrat, serif;
      font-weight: 700;
      line-height: 20px;
      word-wrap: break-word;
    }
  }


  .currency {
    padding: 5px 7px;
    background: rgba(255, 255, 255, 0.12);
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    gap: 2px;
    display: flex;
  }
  .x-symbol {
    color: rgba(255, 255, 255, 0.35);
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.72px;
  }

  .currency-icon {
    color: white;
    font-size: 21px;
    font-family: Montserrat, serif;
    font-weight: 500;
    line-height: 20px;
    word-wrap: break-word;
  }

  .text-gray {
    color: rgba(255, 255, 255, 0.35);
    font-size: 21px;
    font-family: Montserrat, serif;
    font-weight: 800;
    line-height: 20px;
    word-wrap: break-word;
  }

  .session-info {
    align-self: stretch;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    display: inline-flex;


    .bonus-qty {
      flex: 1 1 0;
      padding: 10px;
      background: rgba(255, 255, 255, 0.06);
      border-radius: 15px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 11px;
      display: inline-flex;
      height: 100%;


      .Bonus {
        color: rgba(255, 255, 255, 0.35);
        font-size: 16px;
        font-family: Inter, sans-serif;
        font-weight: 500;
        line-height: 20px;
        word-wrap: break-word;
      }

      .Number {
        color: white;
        font-size: 18px;
        font-family: Inter, sans-serif;
        font-weight: 500;
        line-height: 20px;
        word-wrap: break-word;
      }
    }


    .win-dep {
      width: 191px;
      padding: 10px;
      background: rgba(255, 255, 255, 0.06);
      border-radius: 15px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 11px;
      display: inline-flex;
      height: 100%;


      .WinDep {
        color: rgba(255, 255, 255, 0.35);
        font-size: 16px;
        font-family: Inter, sans-serif;
        font-weight: 500;
        line-height: 20px;
        word-wrap: break-word;
      }

      .Number {
        color: white;
        font-size: 18px;
        font-family: Inter, sans-serif;
        font-weight: 500;
        line-height: 20px;
        word-wrap: break-word;
      }
    }



    .avg {
      flex: 1 1 0;
      padding: 10px;
      background: rgba(255, 255, 255, 0.06);
      border-radius: 15px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 11px;
      display: inline-flex;
      height: 100%;


      .AvgX {
        //opacity: 0.35;
        color: rgba(255, 255, 255, 0.35);
        font-size: 16px;
        font-family: Inter, sans-serif;
        font-weight: 500;
        line-height: 20px;
        word-wrap: break-word;
      }

      .Number {
        color: white;
        font-size: 18px;
        font-family: Inter, sans-serif;
        font-weight: 500;
        line-height: 20px;
        word-wrap: break-word;
      }
    }
  }


  .leader {
    display: flex;
    width: 433px;
    height: 49px;
    padding: 8px 20px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.06);


    .title {
      height: 20px;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 8px;
      display: flex;
      flex-wrap: nowrap !important;
      white-space: nowrap;
      max-width: 50%;

      .name {
        color: white;
        font-size: 18px;
        font-family: Inter, sans-serif;
        font-weight: 500;
        line-height: 20px;
        text-align: start;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }


  .leader-scores {
    height: 20px;
    justify-content: space-between;
    align-items: flex-start;
    display: flex;
    width: 60%;
  }

  .bet, .win, .value {
    text-align: right;
    color: white;
    font-size: 18px;
    font-family: Inter, sans-serif;
    font-weight: 500;
    line-height: 20px;
    word-wrap: break-word;
  }

  .session-widget-slots-table {
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    width: 433px;
    height: 194px;
    padding: 8px 20px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 11px;
    flex-shrink: 0;
    border-radius: 17px;
    background: rgba(255, 255, 255, 0.06);
  }

  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  .widget-table-row {
    flex-wrap: nowrap !important;
    white-space: nowrap;
    display: flex;
    width: 417px;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    height: 32px;
    padding: 0 8px;


    .title {
      color: white;
      text-align: start;
      text-overflow: ellipsis;
      overflow: hidden;
      font-family: Inter, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 111.111% */
      letter-spacing: -0.72px;
    }

    .slot-results {
      display: flex;
      width: 140px;
      justify-content: space-between;
      align-items: flex-start;
      flex-shrink: 0;
    }

    .bet {
      color: #FFF;
      text-align: right;
      font-family: Inter, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.72px;
    }

    .win {
      color: #FFF;
      text-align: right;
      font-family: Inter, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 111.111% */
      letter-spacing: -0.72px;
    }
    &.active {
      border-radius: 8px;
      height: 32px;
      background: linear-gradient(90deg, #231D43 6.61%, #2F1FFF 99.97%);
    }
  }
</style>
