import {computed, ref} from "vue";
import {useStore} from "vuex";
import {v4 as uuidv4} from 'uuid'


export const useSlotopolyStorage = () => {
    const store = useStore();

    const storageSessionId = computed(() => (store.getters['settings/gameStorageSessionId']));

    const templateTasks = computed({
        get() {
            return store.getters['games/templatedData'].tasks
        },
        set(value) {
            store.dispatch('games/updateTemplatedData', {storageSessionId, tasks: value})
        }
    })

    const templateSlotImages = ref([])
    const templateAvatars = ref([])


    const saveTemplatedData = async () => {
        await store.dispatch('games/updateTemplateGameData', {
            game_id: storageSessionId.value,
            payload: {
                meta: {
                    tasks: templateTasks.value,
                    slotImages: templateSlotImages.value,
                    avatars: templateAvatars.value
                }
            }
        })
    }

    const addTask = () => {
        templateTasks.value.push({id: uuidv4(), text: ""});
    };

    return {
        templateTasks, templateSlotImages, templateAvatars, addTask, saveTemplatedData,
    }
}