<template>
    <session-widget-common-frame v-if="ready">
        <template #title>
            {{ session.title }}
        </template>
        <template #currency>
            {{ readableCurrency(session.currency) }}
        </template>

        <SessionStatisticSection>
            <template #value1>
                {{ playedBonus }}
            </template>

            <template #value2>
                {{ winDepRatio }}
            </template>

            <template #value3>
                {{ fixPercents(session.average_x_price) }}
            </template>
        </SessionStatisticSection>

        <div class="battle-board">
            <p-v-p-competitors-list-item :competitors="slots" />
        </div>

        <p-v-p-round-results :round="slots" :visible-buys="9" />
    </session-widget-common-frame>
</template>

<script setup>
    import { computed } from 'vue';
    import { useStore } from 'vuex';
    import { useFilters } from '@composables/useFilters';

    import SessionWidgetCommonFrame from '../widget-parts/SessionWidgetCommonFrame.vue';
    import SessionStatisticSection from '../widget-parts/SessionStatisticSection.vue';
    import PVPRoundResults from '../widget-parts/PVPRoundResults.vue';
    import PVPCompetitorsListItem from '../widget-parts/PVPCompetitorsListItem.vue';

    const store = useStore();
    const { fixPercents, readableCurrency, fixMoney } = useFilters();

    const session = computed(() => store.getters['widgetBattle/session'].session_info);
    const slots = computed(() => store.getters['widgetBattle/session'].slots);

    const playedBonus = computed(() => `${slots.value[0].spins_finished + slots.value[1].spins_finished} / ${session.value.session_spins_total}`);
    const winDepRatio = computed(() => `${fixMoney(session.value.actual_win)} / ${fixMoney(session.value.target_win)}`);
    const ready = computed(() => !!store.getters['widgetBattle/session']);
</script>

<style lang="scss" scoped>
    @import '../../../../assets/css/variables';

    .battle-board {
        background-color: $inner-wrapper-bg-color;
        padding: $section-padding;
        border-radius: $border-radius-inner;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
</style>
