<template>
    <component :is="component"/>
</template>

<script>
    import BattleWidgetAks from './BattleWidgetAks.vue'
    import BattleWidget from './BattleWidget.vue'

    export default {
        name: 'BattleWidgetWrapper',

        computed: {
            component() {
                return this.$store.getters['user/me'].id === 841
                    ? BattleWidgetAks
                    : BattleWidget
            },
        },
    }
</script>
