<template>
    <div class="player-layer">
        <!-- Слой для активного игрока -->
        <div v-if="activePlayer" class="active-player">
            <div
                    class="player"
                    :class="activePlayer.class"
                    :style="activePlayerStyle"
            >
                <player-chip :player="activePlayer" :size="isWidget ?'xlarge' : 'normal'"/>
            </div>
        </div>

        <!-- Слой для остальных игроков -->
        <div class="secondary-players">
            <div
                    v-for="player in secondaryPlayers"
                    :key="player.id"
                    class="player"
                    :class="player.class"
                    :style="[`--player-color: #${player.color} `]">
                <player-chip :player="player" :size="isWidget ? 'xlarge' : 'normal'"/>
            </div>
        </div>
    </div>
</template>

<script setup>
    import {computed} from 'vue';
    import PlayerChip from './PlayerChip.vue';

    const props = defineProps({
        sectorPlayers: {
            type: Array,
            default: () => [],
        },
        allPlayers: {
            type: Array,
            default: () => [],
        },
        isWidget: {
            type: Boolean,
            default: false
        }
    });

    // Синхронизируем данные игроков с актуальными данными из props.allPlayers
    const playersWithClasses = computed(() => {
        const syncedPlayers = props.sectorPlayers.map((player) => {
            const reactivePlayer = props.allPlayers.find((p) => p.id === player.id);
            return {
                ...player,
                ...(reactivePlayer || {}),
            };
        });

        const hasOtherPlayers = syncedPlayers.length > 1;
        return syncedPlayers.map((player) => ({
            ...player,
            class: {
                active: player.active,
                // secondary: !player.active && hasActivePlayer,
                secondary: !player.active && hasOtherPlayers,
                // secondary: !player.active,
            },
        })).filter(pl => !pl.out);
    });

    // Разделяем игроков на активного и второстепенных
    const activePlayer = computed(() => playersWithClasses.value.find((player) => player.class.active));
    // const singlePlayer = computed(() => !!playersWithClasses.value.length);
    const secondaryPlayers = computed(() => playersWithClasses.value.filter((player) => !player.class.active));

    // Определяем стили для активного игрока
    const activePlayerStyle = computed(() => {
        return {
            transform: props. isWidget ? 'scale(1.5)' : 'scale(1.3)', // Увеличиваем фишку активного игрока
            zIndex: 10, // Размещаем поверх других игроков
            '--player-color': `#${activePlayer.value.color}`,
        };
    });
</script>


<style scoped lang="scss">
    .player-layer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        z-index: 2;
    }

    // Слой для активного игрока (верхний слой)
    .active-player {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        //transform: scale(1.3);
        z-index: 10;
        //transform: scale(1.2);
    }

    .secondary-players {
        display: grid;
        grid-template-rows: repeat(3, minmax(0, 1fr));
        grid-template-columns: repeat(5, minmax(0, 1fr));
        justify-items: stretch;
        align-items: stretch;
        width: 95%;
        height: 100%;
        transform-origin: center;

        .player.secondary {
            transform: scale(0.6);
            max-width: 90%;
            max-height: 90%;
            //filter: grayscale(70%);

            &:nth-of-type(odd) {
                grid-column: 1;
            }

            &:nth-of-type(even) {
                grid-column: 5;
            }

            &:nth-of-type(1),
            &:nth-of-type(2) {
                grid-row: 1;
            }

            &:nth-of-type(3),
            &:nth-of-type(4) {
                grid-row: 2;
            }

            &:nth-of-type(5),
            &:nth-of-type(6) {
                grid-row: 3;
            }
        }
    }

    .player {
        width: 100%;
        height: 100%;
        aspect-ratio: 1;
        transition: transform 0.3s ease;
        padding:2px;
    }

</style>
