<template>
    <div class="session-widget">
        <div class="session-widget-header text-big">
            <div>
                <slot name="title"/>
            </div>
            <div>
                <slot name="currency"/>
            </div>
        </div>

        <slot/>
        <div class="promo-link flex justify-content-center text-small" v-if="showAdd">
            {{promoLink}}
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            showAdd() {
               return !this.$tariffs.hasModule('noAdd')
            },
            promoLink() {
                return import.meta.env.VITE_APP_PROMO_URL
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../../assets/css/variables';

    .session-widget {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        padding: $container-padding;
        color: white;
        background-color: $outer-wrapper-bg-color;
        border-radius: $border-radius-outer;
        cursor: default;
        gap: $container-padding;
        flex: 0 1 auto;
    }

    .session-widget-header {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        padding: 0 calc($section-padding / 2)

        //.title, .currency {
        //    padding: 0.1em 0.3em;
        //    background-color: $inner-wrapper-bg-color;
        //    border-radius: $border-radius-inner;
        //}
    }
</style>
