<template>
    <Card class="shadow-2">
        <template #content>
            <p-v-p-competitors-list-item
                :competitors="round">
                <template #stage_name>
                    {{ stage }}
                </template>
            </p-v-p-competitors-list-item>
        </template>
    </Card>
</template>

<script>
    import Card from 'primevue/card'
    import PVPCompetitorsListItem from '../../widgets/widget-parts/PVPCompetitorsListItem.vue'
    import {tournamentStageTitle} from './utils'

    export default {
        name: 'TournamentRoundCard',
        components: {PVPCompetitorsListItem, Card},
        props: {
            round: {},
        },

        computed: {
            stage() {
                return tournamentStageTitle(this.round[0].t_round)
            },
        },
    }
</script>
<style lang="scss" scoped>

    :deep .p-card-body,
    :deep .p-card-content {
        padding: 4px !important;
    }

    :deep .p-divider {
        margin: 4px !important;
    }
</style>
