<template>
    <div>
        <div v-if="ready">
            <sea-battle-widget
                    v-if='activeGame?.type==="sea-battle"'
                    :game-data="activeGame"
                    v-bind="$attrs"
            />
            <div  v-else-if="activeGame?.type==='slotopoly'">
                <slotopoly-widget
                        :game-data="activeGame"
                        v-bind="$attrs"
                />
            </div>

        </div>

        <span v-else>No active game</span>
    </div>
</template>

<script>
    import SeaBattleWidget from "@widgets/games/SeaBattleWidget.vue";
    import SlotopolyWidget from "@widgets/games/SlotopolyWidget.vue";

    export default {
        name: 'GameWidgetWrapper',
        components: {SlotopolyWidget, SeaBattleWidget},

        computed: {
            activeGame() {
                return this.$store.getters['games/activeGame']
            },
            ready() {
                return this.$store.getters['games/activeGame']?.id
            }

        },
    }
</script>

