import {v4 as uuidv4} from 'uuid'

export const defaultSlotopolyBoard = [
    {
        id: 'corner_1',
        tasks: [],
        image: '',
        modifier: '',
        players: [],
        text: 'Start',
        gridArea: '8 / 1',
        position: 'bottom-left'
    },
    {
        id: 'sector_1',
        tasks: [],
        image: '',
        modifier: '',
        players: [],
        text: 'Sector 1',
        gridArea: '7 / 1',
        position: 'left',
    },
    {
        id: 'sector_2',
        tasks: [],
        image: '',
        modifier: '',
        players: [],
        text: 'Sector 2',
        gridArea: '6 / 1',
        position: 'left'
    },
    {
        id: 'sector_3',
        tasks: [],
        image: '',
        modifier: '',
        players: [],
        text: 'Sector 3',
        gridArea: '5 / 1',
        position: 'left'
    },
    {
        id: 'sector_4',
        tasks: [],
        image: '',
        modifier: '',
        players: [],
        text: 'Sector 4',
        gridArea: '4 / 1',
        position: 'left'
    },
    {
        id: 'sector_5',
        tasks: [],
        image: '',
        modifier: '',
        players: [],
        text: 'Sector 5',
        gridArea: '3 / 1',
        position: 'left'
    },
    {
        id: 'sector_6',
        tasks: [],
        image: '',
        modifier: '',
        players: [],
        text: 'Sector 6',
        gridArea: '2 / 1',
        position: 'left'
    },
    {
        id: 'corner_2',
        tasks: [],
        image: '',
        modifier: '',
        players: [],
        text: 'Prize',
        gridArea: '1 / 1',
        position: 'top-left'
    },
    {
        id: 'sector_7',
        tasks: [],
        image: '',
        modifier: '',
        players: [],
        text: 'Sector 7',
        gridArea: '1 / 2',
        position: 'top'
    },
    {
        id: 'sector_8',
        tasks: [],
        image: '',
        modifier: '',
        players: [],
        text: 'Sector 8',
        gridArea: '1 / 3',
        position: 'top'
    },
    {
        id: 'sector_9',
        tasks: [],
        image: '',
        modifier: '',
        players: [],
        text: 'Sector 9',
        gridArea: '1 / 4',
        position: 'top'
    },
    {
        id: 'sector_10',
        tasks: [],
        image: '',
        modifier: '',
        players: [],
        text: 'Sector 10',
        gridArea: '1 / 5',
        position: 'top'
    },
    {
        id: 'sector_11',
        tasks: [],
        image: '',
        modifier: '',
        players: [],
        text: 'Sector 11',
        gridArea: '1 / 6',
        position: 'top'
    },
    {
        id: 'sector_12',
        tasks: [],
        image: '',
        modifier: '',
        players: [],
        text: 'Sector 12',
        gridArea: '1 / 7',
        position: 'top'
    },
    {
        id: 'corner_3',
        tasks: [],
        image: '',
        modifier: '',
        players: [],
        text: 'Percent',
        gridArea: '1 / 8',
        position: 'top-right'
    },
    {
        id: 'sector_13',
        tasks: [],
        image: '',
        modifier: '',
        players: [],
        text: 'Sector 13',
        gridArea: '2 / 8',
        position: 'right'
    },
    {
        id: 'sector_14',
        tasks: [],
        image: '',
        modifier: '',
        players: [],
        text: 'Sector 14',
        gridArea: '3 / 8',
        position: 'right'
    },
    {
        id: 'sector_15',
        tasks: [],
        image: '',
        modifier: '',
        players: [],
        text: 'Sector 15',
        gridArea: '4 / 8',
        position: 'right'
    },
    {
        id: 'sector_16',
        tasks: [],
        image: '',
        modifier: '',
        players: [],
        text: 'Sector 16',
        gridArea: '5 / 8',
        position: 'right'
    },
    {
        id: 'sector_17',
        tasks: [],
        image: '',
        modifier: '',
        players: [],
        text: 'Sector 17',
        gridArea: '6 / 8',
        position: 'right'
    },
    {
        id: 'sector_18',
        tasks: [],
        image: '',
        modifier: '',
        players: [],
        text: 'Sector 18',
        gridArea: '7 / 8',
        position: 'right'
    },
    {
        id: 'corner_4',
        tasks: [],
        image: '',
        players: [],
        text: 'Jail',
        gridArea: '8 / 8',
        position: 'bottom-right'
    },
    {id: 'sector_19', tasks: [], image: '', players: [], text: 'Sector 19', gridArea: '8 / 7', position: 'bottom'},
    {id: 'sector_20', tasks: [], image: '', players: [], text: 'Sector 20', gridArea: '8 / 6', position: 'bottom'},
    {id: 'sector_21', tasks: [], image: '', players: [], text: 'Sector 21', gridArea: '8 / 5', position: 'bottom'},
    {id: 'sector_22', tasks: [], image: '', players: [], text: 'Sector 22', gridArea: '8 / 4', position: 'bottom'},
    {id: 'sector_23', tasks: [], image: '', players: [], text: 'Sector 23', gridArea: '8 / 3', position: 'bottom'},
    {id: 'sector_24', tasks: [], image: '', players: [], text: 'Sector 24', gridArea: '8 / 2', position: 'bottom'},
]

export const emptyPlayer = {
    id: uuidv4(),
    name: '',
    balance: 0,
    cooldown: 0,
    image: '',
    color: 'ff1488',
    active: false,
    out: false,
    position: 0,
    rollRandom: 0,
    roll: 0,
    activeTask: null
}

export const summMovements = (movements) => {
    return movements.reduce((accumulator, currentValue) => {
        return accumulator + currentValue
    }, 0);
}