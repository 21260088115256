import {
    fixMoney, fixPercents, isBonusHunt, readableCurrency, readableSessionType, toUpperCase,
} from '@utils/formatters'
import {cloneDeep} from 'lodash'

export function sessionInfoFormatterLux(session) {
    const sessionInfo = cloneDeep(session.session_info)

    const isBH = isBonusHunt(sessionInfo.session_type)
    const currency = readableCurrency(sessionInfo.currency)

    const title = sessionInfo.title
    const regex = /#(\d+)/; // Regular expression pattern
    const match = title.match(regex);
    const serial = match[1];
    const session_type = toUpperCase(readableSessionType(sessionInfo.session_type))


    const bonusQty = sessionInfo.total_slots ? `${sessionInfo.played_slots} / ${sessionInfo.total_slots}` : ''

    const formattedTotalWin = sessionInfo.total_win ? fixMoney(sessionInfo.total_win) : ''
    const formattedTotalDep = sessionInfo.deposit ? fixMoney(sessionInfo.deposit) : ''
    const winDepRatio = `${formattedTotalWin} / ${formattedTotalDep}`

    const avgX = sessionInfo.avg_x ? fixPercents(sessionInfo.avg_x) : ''
    const avgXPrice = sessionInfo.total_win_to_total_price ? fixPercents(sessionInfo.total_win_to_total_price) : ''
    const formattedAvgX = isBH ? avgX : avgXPrice

    return {
        title,
        session_type,
        serial,
        currency,
        bonusQty,
        winDepRatio,
        formattedAvgX,
    }
}

export function sessionLeadersFormatterLux(session) {
    const sessionTop = cloneDeep(session.top)

    const isBH = isBonusHunt(session.session_info.session_type)

    const leaderWin = {
        show: !!sessionTop.absolute.win,
        title: toUpperCase(sessionTop.absolute.title),
        bet: `${fixMoney(isBH ? sessionTop?.absolute?.bet : sessionTop?.absolute?.price)}`,
        x: `${fixPercents(isBH ? sessionTop?.absolute?.x : sessionTop?.absolute?.x_price)}`,
        win: `${fixMoney(sessionTop.absolute.win)}`,
    }

    return {
        leaderWin,
    }
}

export function sessionSlotsFormatterLux(session) {

    const isBH = isBonusHunt(session.session_info.session_type)

    const slots = cloneDeep(session.slots)
    slots.forEach(slot => {
        slot.order = `${slot.order + 1}.`
        slot.title = slot.title ? `${slot.is_active ? "🔥" : ''} ${slot.order} ${toUpperCase(slot.title)}` : ''
        slot.bet = `${betFormatter(slot, isBH)}`
        slot.win = slot.win ? `${fixMoney(slot.win)}` : ''
        delete slot.price
    })

    return slots
}

function betFormatter(slot, isBH) {
    const value = isBH ? slot.bet : slot.price
    return value ? `${fixMoney(value)}` : ''
}

