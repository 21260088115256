import { createStore } from 'vuex';

import admin from './modules/admin'
import user from './modules/user'
import settings from './modules/settings'
import slots from './modules/slots'
import sessions from './modules/sessions'
import sessionBattle from './modules/session-battle'
import sessionTournament from './modules/session-tournament'
import sessionHB from './modules/session-hunt-or-buy'
import singleRoll from './modules/singleroll'
import archive from './modules/archive'
import widgetBattle from './modules/widget-battle'
import widgetHB from './modules/widget-hunt-or-buy'
import widgetSlot from './modules/widget-slot'
import games from './modules/games'
import mediaFiles from "@store/modules/media-files.js";


const store = createStore({
	modules: {
		admin,
		user,
		settings,
		slots,
        sessions,
        sessionHB,
        sessionBattle,
        sessionTournament,
        singleRoll,
        archive,
        widgetHB,
        widgetBattle,
        widgetSlot,
        games,
        mediaFiles
	},
})

export default store
