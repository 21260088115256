<template>
    <div class="session-widget">
        <div class="session-widget-header text-big">

            <div class="title">
                {{ titleTranslate(session.title) }}
            </div>
            <div class="currency">
                {{ readableCurrency }}
            </div>
        </div>

        <div class="widget-session-stats text-middle">

            <div class="widget-block-small">
             <span class="title">
              БОНУС
                 </span>
                <span class="value">
                {{ playedBonus }}
            </span>
            </div>

            <div class="widget-block-small middle">
            <span class="title">
                    ВИН/ДЕП
            </span>
                <span class="value">
                {{ winDepRatio }}
            </span>
            </div>

            <div class="widget-block-small">
            <span class="title">
                    РАУНД
            </span>
                <span class="value">
                {{ playedRoundsQty }}
            </span>
            </div>
        </div>


        <div class="competitors-list">
            <slide-changer
                :all-items="rounds"
                :interval="roundsSlideChangeDelay"
                :items-per-view="itemsPerViewInList">
                <template #default="{items}">
                    <div class="rounds-list">
                        <div v-for="round in items" ref="listItem">
                            <div class="flex align-items-center gap-2 rounds-list-round-item" v-if="round.length>1">
                                <div
                                    class="flex justify-content-start text-small text-number">
                                    {{ round[0].t_round }}
                                </div>
                                <div
                                    class="flex flex-column"
                                    style="width:95%">
                                    <div v-for="(slot, i) in round">
                                        <div class="flex justify-content-between ">
                                            <div
                                                class="flex align-items-center gap-1"
                                                style="width: 75%">

                                                <competitor-literal-icon
                                                    class="text-small"
                                                    :style="{ opacity: isWinner(round, i) ? 1 : 0.5}"
                                                    :letter="competitorIcon(slot, i)"/>
                                                <div
                                                    class="text-small px-2 uppercase long-title"
                                                    :style="{ opacity: isWinner(round, i) ? 1 : 0.5}">
                                                    {{ slot.title }}
                                                </div>

                                            </div>

                                            <span
                                                class="pl-2 text-small"
                                                style="width: 20%">
                                                <span v-if="isWinner(round, i) && slot.slot_score"
                                                      style="color:#08AA70">W</span>
                                                <span v-else-if="!isWinner(round, i) && slot.slot_score"
                                                      style="color:#780DCD">L</span>
                                                <span v-else> &nbsp</span>
                                                {{ fixPercents(slot.slot_score ?? 0)  }}
                                            </span>
                                        </div>

                                        <div class="divider" v-if="i===0">
                                            <hr class="divider-line"/>
                                            <div class="divider-text">
                                                {{ stage(round[0].t_round) }}
                                            </div>
                                            <hr class="divider-line"/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-else
                                 class="winner text-big"
                                 :style="{ opacity: round[0].slot_id ? 1 : 0.8}">
                                <div class="flex justify-content-evenly" v-if="round[0].slot_id">
                                    <span>🥇</span>
                                    {{ round[0].slot_title }}
                                    <span>🥇</span>
                                </div>
                            </div>


                        </div>
                    </div>
                </template>
            </slide-changer>
        </div>

        <div class="pvp-results-table" v-if="!!viewMode && viewMode !== 'board' ">
            <div v-for="(slot, index) in currentRound"
                 class="results-round-item"
                 :key="slot.id">
                <div class="flex align-items-center justify-content-between  text-small round-list-header">
                    <competitor-literal-icon
                        :letter="competitorIcon(slot, index)"/>
                    <span>
                    ЦЕНА: {{ slot.t_price ?? slot.price }}
                     </span>
                    <span>
                    СЫГРАНО РАУНДОВ: {{ slot.spins_finished }}
                    </span>

                </div>
                <div class="results">
                    <div v-for="result in formattedSlotResults(slot.results)">
                        <span
                            :style="'text-wrap: nowrap'"
                            class="text-small round-slot-result-item">
                            <span class="text-x-small serial-number-attempt"
                                  :style="{background: index===1 ? '#08AA70' : '#780DCD'}">
                                {{ result.serial }}:
                            </span>
                        {{ fixMoney(result.win) }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import PVPCompetitorsList from '../widget-parts/PVPCompetitorsList.vue'
    import PVPRoundResults from '../widget-parts/PVPRoundResults.vue'
    import SlideChanger from '../widget-parts/SlideChanger.vue'
    import {fixMoney, fixPercents, readableCurrency} from '@utils/formatters.js'
    import PVPWinner from '../widget-parts/PVPWinner.vue'
    import PVPCompetitorsListItem from '../widget-parts/PVPCompetitorsListItem.vue'
    import {tournamentStageTitle} from '../../session-crud-tables/tournament/utils'
    import PVPCompetitorsDivider from '../widget-parts/PVPCompetitorsDivider.vue'
    import CompetitorLiteralIcon from '../../misc/CompetitorLiteralIcon.vue'

    export default {
        name: 'TournamentWidgetAKS',
        components: {
            CompetitorLiteralIcon, PVPCompetitorsDivider,
            PVPCompetitorsListItem, PVPWinner,
            PVPRoundResults,
            PVPCompetitorsList,
            SlideChanger,
        },

        computed: {

            roundsSlideChangeDelay() {
                return this.$store.getters['settings/tournamentSlidesRefreshDelay']
            },

            session() {
                return this.$store.getters['sessionTournament/session']
            },

            finished() {
                return !!this.session?.meta?.winner
            },

            readableCurrency() {
                return readableCurrency(this.session.currency)
            },

            winDepRatio() {
                return `${fixMoney(this.session.actual_win)} / ${fixMoney(this.session.target_win)}`
            },

            playedRoundsQty() {
                return `${this.session.meta.rounds?.active} / ${this.session.meta.rounds?.total}`
            },

            playedBonus() {
                return `${this.session.meta.bonus.active} / ${this.session.meta.bonus.total}`
            },

            viewMode() {
                return this.session.view_mode
            },

            currentViewRound() {
                if (!!this.viewMode && this.viewMode.startsWith('round')) {
                    return parseInt(this.viewMode.slice(-1))
                }
            },

            itemsPerViewInList() {
                return (this.viewMode === 'board' || !this.viewMode) ? 4 : 2
            },

            rounds() {
                if (this.viewMode === 'board' || !this.viewMode) {
                    return this.roundsListWithWinnerPlaceholder
                } else if (this.currentViewRound === 7 && !this.session?.meta?.winner) {
                    return this.finalPlusWinner
                } else if (this.currentViewRound === 7 && !!this.session?.meta?.winner) {
                    return this.roundsListWithWinnerPlaceholder
                } else {
                    return this.scopedRounds
                }
            },

            winnerStats() {
                const mockWinner = {
                    slot_id: null,
                    slot_title: '',
                    icon: '?',
                }
                return [this.session?.meta?.winner ?? mockWinner]

            },

            roundsListWithWinnerPlaceholder() {
                const rounds = this.$store.getters['sessionTournament/fullFilledRoundsList']
                return [...rounds, this.winnerStats]
            },


            roundsWhereOneCompetitorIsDefined() {
                const rounds = this.$store.getters['sessionTournament/fullFilledRoundsList']
                return rounds.filter(round => round.some(competitor => !!competitor.slot_id))
            },

            finalPlusWinner() {
                const rounds = this.$store.getters['sessionTournament/fullFilledRoundsList']
                return [...rounds.slice(6, 7), this.winnerStats]
            },

            scopedRounds() {
                const rounds = this.$store.getters['sessionTournament/fullFilledRoundsList']

                if (this.currentViewRound === 1 || this.currentViewRound === 2) {
                    return rounds.slice(0, 2)
                }
                if (this.currentViewRound === 3 || this.currentViewRound === 4) {
                    return rounds.slice(2, 4)
                }
                if (this.currentViewRound === 5 || this.currentViewRound === 6) {
                    return rounds.slice(4, 6)
                }
                if (this.currentViewRound === 7) {
                    return rounds.slice(5, 7)
                }
            },

            currentRound() {
                const round = this.$store.getters['sessionTournament/roundByNum'](this.currentViewRound)

                if (round) {
                    return round.map(competitor => {
                        return {
                            ...competitor,
                            spins_finished: competitor.results.reduce((count, result) => {
                                return count + (!!result.win ? 1 : 0)
                            }, 0),
                        }
                    })
                }
            },


        },
        methods: {
            fixMoney, fixPercents,

            titleTranslate(title) {
                const replacements = {
                    'BONUS BUY': 'БОНУС БАЙ',
                    'BONUS HUNT': 'БОНУС ХАНТ',
                    'BONUS TOURNAMENT': 'БОНУС ТУРНИР',
                }

                const pattern = new RegExp(Object.keys(replacements).join('|'), 'g')

                return title.replace(pattern, match => replacements[match])
            },


            stage(round) {
                return tournamentStageTitle(round)
            },

            formattedSlotResults(results) {
                let slotResults = results.map((obj, index) => ({
                    ...obj,
                    serial: index + 1,
                }))

                const noWinIndex = slotResults.findIndex(result => result.win === null)
                if (slotResults.length <= 6) {
                    return slotResults
                } else if (noWinIndex <= 6) {
                    return slotResults.slice(0, 6)
                } else
                    return slotResults.slice(noWinIndex - 6, noWinIndex)

            },

            isRoundFinished(round) {
                return round.every(competitor =>
                    competitor.results && Array.isArray(competitor.results) &&
                    competitor.results.every(attempt => attempt.win !== null),
                )
            },


            isWinner(round, index) {
                if (this.isRoundFinished(round)) {
                    const winnerIndex = round.reduce((maxIndex, competitor, currentIndex) => {
                        if (competitor.slot_score === 0) {
                            return maxIndex
                        }

                        if (maxIndex === null || parseFloat(competitor.slot_score) > parseFloat(round[maxIndex].slot_score)) {
                            return currentIndex
                        }

                        return maxIndex
                    }, null)

                    return index === winnerIndex
                } else {
                    return true
                }
            },

            competitorIcon(slot, i) {
                const fallbackPreset = ['A', 'B']
                const letter = slot?.icon ?? slot.color
                return letter.startsWith('#') ? fallbackPreset[i] : letter
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../../../../assets/css/variables';

    .session-widget {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        color: white;
        background-color: #010D11;
        border-radius: 5%;
        cursor: none;
        gap: $container-padding;
        flex: 0 1 auto;
        overflow: hidden
    }

    .session-widget-header {
        display: flex;
        height: 12.5%;
        flex-wrap: nowrap;
        justify-content: space-between;
        background: linear-gradient(89.03deg, #008958 -3.87%, #3B0069 106.39%);
        align-items: center;
        padding: 0.5em;
        border-radius: 0 0 0.5em 0.5em;
    }

    .logo, .currency {
        background: #00000033;
        border-radius: 0.25em;
        padding: 0 0.25em;
    }

    .logo {
        height: 100%;
        width: 33%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.25em;

        > * {
            //background-image: url("./streamer-aks-logo.png");
            width: 100%;
            background-position: center center;
            background-repeat: no-repeat;
            background-clip: padding-box;
            background-size: contain;
        }
    }


    .widget-session-stats {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        margin: 0 0.3em !important;
        gap: $section-padding;
    }

    .widget-block-small {
        width: 25%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 0.3em 0.3em;
        overflow: hidden;

        &.middle {
            width: 50%;
        }

        .title {
            background: linear-gradient(269.28deg, #08AA70 4.14%, #780DCD 113.19%);
            background-clip: border-box;
            width: 100%;
            height: 40%;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            font-size: calc($small-font-size * .75) !important;
        }

        .value {
            background: linear-gradient(269.28deg, rgba(8, 170, 112, 0.15) 4.14%, rgba(120, 13, 205, 0.15) 113.19%);
            background-clip: border-box;
            width: 100%;
            height: 60%;
            display: flex;
            justify-content: center;
            align-items: flex-end;
        }
    }

    .competitors-list {
        padding: 0.2em;
        display: flex;
        gap: 0.2em;
        flex-direction: column;
        justify-content: space-evenly;
        overflow: hidden;
        max-height: min-content;
        transition: max-height 0.3s ease;
    }

    .rounds-list {
        display: flex;
        flex-direction: column;
        gap: 0.6em;
        //height: 100%
    }

    .rounds-list-round-item {
        background: linear-gradient(269.28deg, rgba(8, 170, 112, 0.15) 4.14%, rgba(120, 13, 205, 0.15) 113.19%);
        background-clip: border-box;
        border-radius: 1.2em;
        padding: 0.4em 0;
    }

    .divider {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    .divider-line {
        width: 100%;
        border-width: 1px 0 0 0;
        margin: 3px 0;
        background: linear-gradient(89.03deg, #008958 -3.87%, #3B0069 106.39%);
    }

    .divider:has(.divider-text) {
        .divider-line {
            margin: -1px 0;
        }
    }

    .divider-text {
        color: inherit;
        font-size: 0.4em;
        margin: -1px 4px;
    }


    .pvp-results-table {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.5em;
    }

    .results-round-item {
        border-radius: 1.5em;
        overflow: hidden;
    }

    .round-list-header {
        background: linear-gradient(269.28deg, #08AA70 4.14%, #780DCD 113.19%);
        padding: 0.2em 1.5em;

    }


    .results {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        row-gap: 0.1em;
        column-gap: 0.2em;
        background: linear-gradient(269.28deg, rgba(8, 170, 112, 0.15) 4.14%, rgba(120, 13, 205, 0.15) 113.19%);
        padding: 0.2em 1.5em;

    }

    .round-slot-result-item {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .serial-number-attempt {
        border-radius: 0.2em;
        padding: 0 0.2em;
    }


    .winner {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0.2em;
        border-radius: 1.3em;
        background: linear-gradient(269.28deg, #08AA70 4.14%, #780DCD 113.19%);
        justify-content: center;

        min-height: 50px;
        margin: 0 5%;
        opacity: 0.8;
    }


    * {
        font-family: Montserrat, sans-serif !important
    }
</style>
