import { createApp } from 'vue'

import App from '@/App.vue'
import router from '@/router'
import store from '@store/store'
import VueCookies from 'vue-cookies'
import mdiVue from 'mdi-vue/v2'
import * as mdijs from '@mdi/js'
import GlobalTariffsServiceInstance from './services/GlobalTariffsService';

//PrimeVue
import PrimeVue from 'primevue/config'
import ConfirmationService from 'primevue/confirmationservice'
import Tooltip from 'primevue/tooltip'
import ToastService from 'primevue/toastservice'
import '@css/app.scss'
import 'primevue/resources/themes/lara-light-purple/theme.css'
// import 'primevue/resources/themes/aura-light-green/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'



const Vue = createApp(App)

Vue.directive('tooltip', Tooltip)

Vue.use(router)
Vue.use(store)
Vue.use(PrimeVue)
Vue.use(ConfirmationService)
Vue.use(ToastService)
Vue.use(VueCookies)
Vue.use(mdiVue, {
    icons: mdijs,
})

import { fixDecimal, numberComplexHandler, numberSpacing, toUpperCase, truncate } from '@utils/formatters'
const filters = {
    truncate, // fixed
    fixDecimal, // fixed
    combiner: numberComplexHandler, //fixed
    spacer: numberSpacing,
    allCaps: toUpperCase,
}

// Vue.prototype.$tariffs = new GlobalTariffsService(store);

GlobalTariffsServiceInstance.setVuexInstance(store)
Vue.config.globalProperties.$tariffs = GlobalTariffsServiceInstance
Vue.config.globalProperties.$filters = filters
Vue.config.devtools = true
Vue.config.productionTip = false
Vue.config.performance = true;

Vue.mount('#app')
