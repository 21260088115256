<template>
    <FileUpload
            mode="basic"
            name="files"
            accept="image/*"
            :multiple="false"
            customUpload
            @uploader="customUploader"
            chooseLabel="Выбрать файл"
            uploadLabel="Загрузить"
            class="p-inputtext-sm"
    />
</template>

<script setup>
    import {useStore} from "vuex";
    import FileUpload from "primevue/fileupload";

    const store = useStore();

    const props = defineProps(['type'])
    const emit = defineEmits(['image:uploaded'])

    const customUploader = async (event) => {
        try {
            const file =  await store.dispatch('mediaFiles/upload', {files: event.files, type: props.type})
            console.log("(FileUploader.vue:27) ---> file:", file);
            emit('image:uploaded', file.original_url)
        } catch (error) {
            console.log(error)
        }
    }
</script>
