<template>
    <div class="literal-icon"
         :class="`letter-${letter}`">
        {{ letter }}
    </div>
</template>

<script>
    export default {
        props: {
            letter: {
                type: String,
            },
        },
    }
</script>

<style lang="scss" scoped>
    $allowed-letters: (
        A: #00baa2,
        B: #e5b105,
        C: #ff6035,
        D: #ff006e,
        E: #ff00c6,
        F: #e515f1,
        G: #9b4bf9,
        H: #7026f7
    );

    @function get-color($letter) {
        @if map-has-key($allowed-letters, $letter) {
            @return map-get($allowed-letters, $letter);
        } @else {
            @return black;
        }
    }

    @function get-contrast-color($background-color) {
        $red: red($background-color);
        $green: green($background-color);
        $blue: blue($background-color);
        $brightness: ($red * 0.299) + ($green * 0.587) + ($blue * 0.114);
        @if $brightness > 128 {
            @return #000;
        } @else {
            @return #fff;
        }
    }

    @mixin btn-theme($btn-color) {
        background-color: $btn-color;
        color: white;
        border: none
    }

    .literal-icon {
        background: white;
        height: 1.2em;
        aspect-ratio: 1/1;
        box-sizing: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        padding: 4px;
        font-family: 'Rubik', 'sans-serif';
        font-weight: 700;
        border: 1px solid black;
        color: black;
    }

    @each $letter, $color in $allowed-letters {
        .letter-#{$letter} {
            @include btn-theme($color);
        }
    }
</style>
