<template>
    <div>
        <tournament-players-board
            :all-rounds-open-available="false"
            @select:round="changeActiveRound" />

        <div v-if="activeRound>0"
             class="p-2 w-full flex justify-content-end">
            <Button
                class="p-button-sm "
                @click="showBoard">
                Show Players Board on widget
            </Button>
        </div>

        <tournament-round :round="activeRound"/>
    </div>
</template>

<script>

    import TournamentPlayersBoard from './TournamentPlayersBoard.vue'
    import TournamentRound from './TournamentRound.vue'
    import Button from 'primevue/button'


    export default {
        name: 'TournamentIndex',
        components: {TournamentRound, TournamentPlayersBoard, Button},
        data() {
            return {
                activeRound: 0,
            }
        },
        methods: {
            changeActiveRound(round) {

                this.activeRound = round[1].t_round
                this.$store.dispatch('sessionTournament/changeView', {view_mode: `round:${round[1].t_round}`})
            },

            showBoard() {
                this.$store.dispatch('sessionTournament/changeView', {view_mode: `board`})
            },
        },
    }
</script>

<style lang="scss" scoped>
    .content {
        flex-grow: 1;
        overflow: hidden;
    }
</style>
