<template>
    <div class="session-creator">
        <Card>
            <template #title>
                Setup your game session
            </template>

            <template #content>
                <TabView :activeIndex="activeTab" @update:activeIndex="activeTab = $event">
                    <TabPanel v-for="form in forms" :key="form.title" :header="form.title" :disabled="!form.enabled">
                        <CreateSessionCommonForm v-model="formData.common" v-bind="$attrs">

                            <template #deposit v-if="activeTab===0">
                                <div>
                                    <p>Start deposit:</p>
                                    <InputNumber
                                        :min="0"
                                        :maxFractionDigits="2"
                                        showButtons
                                        v-model="formData.common.deposit"
                                        class="p-inputtext-sm"
                                        placeholder="Enter start deposit"/>
                                </div>
                            </template>
                        </CreateSessionCommonForm>

                        <component :is="form.component" v-model="formData[form.value]"/>

                    </TabPanel>
                </TabView>
            </template>

            <template #footer>
                <Button
                    class="p-button-sm"
                    :class="formReady ? 'p-button-success' : 'p-button-secondary'"
                    iconPos="right"
                    icon="pi pi-play"
                    label="Start"
                    :disabled="!formReady"
                    @click="handleConfirm"/>
            </template>
        </Card>

        <Loading v-if="loading"/>
    </div>
</template>

<script>
    import Card from 'primevue/card'
    import Button from 'primevue/button'
    import TabView from 'primevue/tabview'
    import TabPanel from 'primevue/tabpanel'

    import Loading from '@components/Loading.vue'

    import Constants from '@resources/js/constants/general.js'
    import CreateSessionCommonForm from './CreateSessionCommonForm.vue'
    import CreateSessionBattle from './CreateSessionBattle.vue'
    import CreateSessionTournament from './CreateSessionTournament.vue'
    import InputNumber from 'primevue/inputnumber'

    export default {
        name: 'SessionCreator',

        components: {
            InputNumber,
            TabView,
            TabPanel,
            CreateSessionTournament,
            CreateSessionBattle,
            CreateSessionCommonForm,
            Card,
            Button,
            Loading,
        },
        data() {
            return {
                activeTab: 0,
                loading: false,

                formData: {
                    common: {
                        selectedCurrency: null,
                        deposit: 0,
                    },
                    bonus_battle: {
                        spins: null,
                        slots: [],
                    },
                    bonus_tournament: {
                        slots: [],
                        spins: null,
                    },
                },
            }
        },

        computed: {
            forms() {
                return [
                    {
                        title: 'Bonus Hunt',
                        value: Constants.TYPE_BONUS_HUNT,
                        enabled: this.$tariffs.hasModule('BonusHunt'),
                    },
                    {
                        title: 'Bonus Buy',
                        value: Constants.TYPE_BONUS_BUY,
                        enabled: this.$tariffs.hasModule('BonusBuy'),
                    },
                    {
                        title: 'Bonus Battle',
                        value: Constants.TYPE_BATTLE,
                        enabled: this.$tariffs.hasModule('BonusBattle'),
                        component: 'CreateSessionBattle',
                    },
                    {
                        title: 'Bonus Tournament',
                        value: Constants.TYPE_BONUS_TOURNAMENT,
                        enabled: this.$tariffs.hasModule('BonusTournament'),
                        component: 'CreateSessionTournament',
                    },
                ]
            },

            selectedSessionType() {
                return this.forms[this.activeTab].value
            },

            isBattle() {
                return this.selectedSessionType === Constants.TYPE_BATTLE
            },

            isTournament() {
                return this.selectedSessionType === Constants.TYPE_BONUS_TOURNAMENT
            },

            formReady() {
                const battle = this.formData.bonus_battle
                const tournament = this.formData.bonus_tournament

                const commonFieldsReady = !!this.formData.common.selectedCurrency
                const battleFieldsReady = this.isBattle ? (battle.slots.every(item => !!item.slot.id && !!item.price) && !!battle.spins) : true
                const tournamentFieldsReady = this.isTournament ? (tournament.slots.every(slot => !!slot.id && !!slot.price)) : true

                return commonFieldsReady && battleFieldsReady && tournamentFieldsReady
            },
        },
        methods: {

            handleConfirm() {
                switch (true) {
                    case this.isBattle:
                        this.startBattle()
                        break
                    case this.isTournament:
                        this.startTournament()
                        break
                    default:
                        this.startSession()
                }
            },

            async startSession() {
                this.loading = true

                const payload = {
                    status: Constants.STATUS_INIT,
                    session_type: this.selectedSessionType,
                    currency: this.formData.common.selectedCurrency,
                    target_win: this.formData?.common?.deposit * 100,
                }

                try {
                    const session_info = await this.$store.dispatch('sessionHB/createSession', payload)

                    await this.$store.dispatch('sessions/setActiveSession', session_info.id)

                    await this.$router.push({
                        name: `session-${this.selectedSessionType}`,
                        params: {id: session_info.id},
                    })
                } catch (error) {
                    this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: 1000})
                } finally {
                    this.loading = false
                }
            },

            async startBattle() {
                this.loading = true

                const slots = Array.from(this.formData.bonus_battle.slots, (e) => ({
                    slot_id: e.slot.id,
                    spins: this.formData.bonus_battle.spins,
                    price: e.price,
                    bet: e.bet ?? 0,
                    color: e.color,
                }))

                const deposit = slots.reduce((sum, slot) => sum + (slot.price * slot.spins), 0)

                const payload = {
                    status: Constants.STATUS_INIT,
                    target_win: deposit,
                    currency: this.formData.common.selectedCurrency,
                    slots: slots,
                }

                try {
                    const data = await this.$store.dispatch('sessionBattle/startBattle', payload)

                    await this.$store.dispatch('sessions/setActiveSession', data.session_info.id)

                    await this.$router.push({
                        name: `session-${this.selectedSessionType}`,
                        params: {id: data.session_info.id},
                    })
                } catch (error) {
                    this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: 1000})
                } finally {
                    this.loading = false
                }
            },

            async startTournament() {
                this.loading = true

                const slots = Array.from(this.formData.bonus_tournament.slots, (slot) => ({
                    slot_id: parseInt(slot.id),
                    price: slot.price ?? this.formData.bonus_tournament.price,
                    bet: slot.bet ?? 0,
                }))

                const payload = {
                    currency: this.formData.common.selectedCurrency,
                    slots: slots,
                    buys_per_round: this.formData.bonus_tournament.spins,
                }

                try {
                    const {session_info} = await this.$store.dispatch('sessionTournament/startTournament', payload)

                    await this.$store.dispatch('sessions/setActiveSession', session_info.id)

                    await this.$router.push({
                        name: `session-${this.selectedSessionType}`,
                        params: {id: session_info.id},
                    })

                } catch (error) {
                    this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: 1000})
                } finally {
                    this.loading = false
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    .session-creator {
        overflow-y: auto;
        height: 100vh
    }

    :deep .p-card .p-card-content,
    :deep .p-tabview .p-tabview-panels {
        padding: 0;
    }
</style>
