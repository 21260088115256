<template>
    <Fieldset>
        <template #legend>
            <slot name="session_title"/>
        </template>

        <div class="content flex flex-column">
        <slot name="content_above_competitors"/>

        <div class="competitors-names flex justify-content-between">

        <div class="info-block" v-if="$slots.competitor1_title">
            <div class="value">
                <table-cell-title>
                    <slot name="competitor1_title"/>
                </table-cell-title>
            </div>
        </div>

        <div class="info-block" v-if="$slots.competitor2_title">
            <div class="value">
                <table-cell-title >
                    <slot name="competitor2_title"/>
                </table-cell-title>
            </div>
        </div>
        </div>
            <slot name="content_below_competitors"/>
        </div>
    </Fieldset>
</template>

<script>

    import Fieldset from 'primevue/fieldset'
    import Card from 'primevue/card'
    import TableCellTitle from '../../misc/Title.vue'

    export default {
        name: 'ArchiveBattleHeader',
        components: {
            TableCellTitle,
            Fieldset,
            Card,
        },
    }
</script>

<style lang="scss" scoped>
    .p-fieldset {
        :deep .p-fieldset-legend {
            padding: 0.5rem;
        }
    }

    .info-block {
        border: 2px solid #8f61f6;
        background: #f8f9fa;
        border-radius: 6px;
        width: 40%;
        height: 48px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .value {
            color: #8f61f6;
            font-weight: 600;
            font-size: 16px;
        }
    }
</style>
