<script setup>
import Avatar from "primevue/avatar";

const props = defineProps(['player', 'size'])

</script>

<template>
    <Avatar :image="player.image" class="mr-2" :size="size ?? 'normal'" shape="circle" :style="`background-color: #${props.player.color}; margin: 0 !important;`"/>
</template>
