<template>
    <div class="flex gap-5 p-2 w-100">

        <div style="width:50%"
             v-for="(slot, index) in slots"
             :key="slot.slot_id">
            <Card>

                <template #header>
                    <table-cell-title>
                        {{ slot.title }}
                    </table-cell-title>
                </template>

                <template #content>
                    <div @click="$emit('update:slot', {index: index})">
                        <PVPTable
                            :slot-results="slot.results"
                            @update="$emit('update:result', $event)"/>
                    </div>
                </template>

            </Card>
        </div>

    </div>
</template>

<script>

    import Card from 'primevue/card'
    import PVPTable from './PVPTable.vue'
    import TableCellTitle from '../../misc/Title.vue'

    export default {
        components: {TableCellTitle, PVPTable, Card},
        props: {
            slots: [],
        },
    }
</script>

<style lang="scss" scoped>

    :deep .p-card-body {
        padding: 4px !important;
    }

    :deep .p-card-header {
        font-size: 22px !important;
        padding: 4px !important;
    }
</style>
