<template>
    <div class="competitors-wrapper">
        <Card v-for="i of 2" :key="i">
            <template #content>
                <div
                    v-for="(value, key) in competitor(i)"
                     class="info-block">
                    <div class="title">
                        {{ key }}
                    </div>
                    <div class="value">
                        {{ value }}
                    </div>
                </div>
            </template>

        </Card>
    </div>
</template>

<script>

    import Card from 'primevue/card'
    import {fixMoney, fixPercents} from '@utils/formatters'
    import TableCellTitle from '../../misc/Title.vue'

    export default {
        name: 'ArchiveBattleCompetitorsStats',
        components: {
            TableCellTitle,
            Card,
        },
        props: {
            slots: {},
            currency: {},
        },

        computed: {
            comp1() {
                return this.slots[0]
            },
            comp2() {
                return this.slots[1]
            },
            comp1Stats() {
                const comp1 = this.comp1.results
                return {
                    'Total Win': `${fixMoney(this.getSum(comp1, 'win')) || '-'} ${this.currency}`,
                    'Total X (SCORE)': fixPercents(this.getSum(comp1, 'x_price')) || '-',
                    'Avg Win': `${fixMoney(this.getAvg(comp1, 'win')) || '-'} ${this.currency}`,
                    'Avg X': fixPercents(this.getAvg(comp1, 'x_price')) || '-',
                    'Max Win': `${fixMoney(this.getMax(comp1, 'win')) || '-'} ${this.currency}`,
                    'Max X': fixPercents(this.getMax(comp1, 'x_price')) || '-',
                    'Min Win': `${fixMoney(this.getMin(comp1, 'win')) || '-'} ${this.currency}`,
                    'Min X': fixPercents(this.getMin(comp1, 'x_price')) || '-',
                }
            },
            comp2Stats() {
                const comp2 = this.comp2.results
                return {
                    'Total Win': `${fixMoney(this.getSum(comp2, 'win')) || '-'} ${this.currency}`,
                    'Total X (SCORE)': fixPercents(this.getSum(comp2, 'x_price')) || '-',
                    'Avg Win': `${fixMoney(this.getAvg(comp2, 'win')) || '-'} ${this.currency}`,
                    'Avg X': fixPercents(this.getAvg(comp2, 'x_price')) || '-',
                    'Max Win': `${fixMoney(this.getMax(comp2, 'win')) || '-'} ${this.currency}`,
                    'Max X': fixPercents(this.getMax(comp2, 'x_price')) || '-',
                    'Min Win': `${fixMoney(this.getMin(comp2, 'win')) || '-'} ${this.currency}`,
                    'Min X': fixPercents(this.getMin(comp2, 'x_price')) || '-',
                }
            },
        },
        methods: {
            getSum(results, key) {
                const values = results.filter((o) => o[key]).map((o) => parseFloat(o[key]))
                return values.reduce((a, b) => a + b, 0)
            },
            getAvg(results, key) {
                const values = results.filter((o) => o[key]).map((o) => parseFloat(o[key]))
                const sum = values.reduce((a, b) => a + b, 0)
                return sum / values.length
            },
            competitor(n) {
                return n < 2 ? this.comp1Stats : this.comp2Stats
            },
            getMax(results, key) {
                const values = results.filter((o) => o[key]).map((o) => o[key])
                return Math.max(...values)
            },
            getMin(results, key) {
                const values = results.filter((o) => o[key]).map((o) => o[key])
                return Math.min(...values)
            },
        },
    }
</script>

<style lang="scss" scoped>
    .competitors-wrapper {
        display: flex;
        justify-content: space-between;
        padding: 1rem;
    }

    :deep .p-card {
        width: 40%;

        .p-card-body {
            padding: 0.5rem;
        }

        .p-card-content {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            justify-items: center;
            row-gap: 8px;
            padding: 0;
        }
    }

    .info-block {
        padding: 4px;
        border: 1px solid #8f61f6;
        background: #f8f9fa;
        border-radius: 6px;
        display: flex;
        gap: 4px;
        flex-direction: column;
        width: 150px;
        align-items: center;

        .title {
            color: #8f61f6;
        }

        .value {
            color: black;
            font-weight: 600;
            font-size: 14px;
        }
    }
</style>
