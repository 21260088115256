<template>
    <div class="rounds-list">

        <div v-for="round in rounds" ref="listItem">
            <p-v-p-competitors-list-item
                v-if="round.length>1"
                :competitors="round">

                <template #round_number>
                    {{ round[0].t_round }}
                </template>

                <template #stage_name>
                    {{stage(round[0].t_round)}}
                </template>

            </p-v-p-competitors-list-item>

            <p-v-p-winner
                v-else
                :winner="round[0]"
                :style="{height: `${listItemHeight}px`}"/>
        </div>
    </div>
</template>

<script>
    import PVPCompetitorsListItem from './PVPCompetitorsListItem.vue'
    import PVPWinner from './PVPWinner.vue'
    import {tournamentStageTitle} from '../../session-crud-tables/tournament/utils'

    export default {
        components: {PVPCompetitorsListItem, PVPWinner},
        props: {
            rounds: {
                type: Array,
            },
        },

        data() {
            return {
                listItemHeight: 0,
            }
        },
        mounted() {
            this.updateListItemHeight()
        },
        watch: {
            rounds() {
                this.updateListItemHeight()
            },
        },

        methods: {
            updateListItemHeight() {
                this.$refs?.listItem && this.$nextTick(() => {
                    this.listItemHeight = this.$refs?.listItem[0]?.clientHeight
                })
            },

            stage(round) {
                return tournamentStageTitle(round)
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../../assets/css/variables';

    .rounds-list {
        display: flex;
        flex-direction: column;
        gap: 0.6em;
        height: 100%
    }
</style>
