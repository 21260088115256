<template>
    <session-widget-common-frame>

        <template #title>
            {{ session.title }}
        </template>
        <template #currency>
            {{ session.currency }}
        </template>

        <SessionStatisticSection>
            <template #value1>
                {{ session.bonusQty }}
            </template>

            <template #value2>
                {{ session.winDepRatio }}
            </template>

            <template #value3>
                {{ session.formattedAvgX }}
            </template>
        </SessionStatisticSection>

        <div class="session-widget-leaders text-small" v-if="!showLosers">
            <div class="session-widget-leaders-row" v-show="leaders.leaderWin.show">
                <img class="icon" src="@resources/assets/icons/crown-g.svg"/>
                <span class="title">{{ leaders.leaderWin.title }}</span>
                <span class="bet">{{ leaders.leaderWin.bet }}</span>
                <span class="win">{{ leaders.leaderWin.win }}</span>
            </div>
            <div class="session-widget-leaders-row" v-show="leaders.leaderX.show">
                <img class="icon" src="@resources/assets/icons/crown-s.svg"/>
                <span class="title">{{ leaders.leaderX.title }}</span>
                <span class="bet">{{ leaders.leaderX.bet }}</span>
                <span class="win">{{ leaders.leaderX.x }}</span>
            </div>
        </div>

        <div class="session-widget-leaders text-small" v-else>
            <div class="session-widget-leaders-row" v-show="leaders.leaderWin.show">
                <img class="icon" src="@resources/assets/icons/crown-g.svg"/>
                <span class="title losers">{{ leaders.leaderWin.title }}</span>
                <span class="bet">{{ leaders.leaderWin.bet }}</span>
                <span class="bet">{{ leaders.leaderWin.x }}</span>
                <span class="win">{{ leaders.leaderWin.win }}</span>
            </div>
            <div class="session-widget-leaders-row" v-show="leaders.loser.show">
                <img class="icon" src="@resources/assets/icons/poop.svg"/>
                <span class="title losers">{{ leaders.loser.title }}</span>
                <span class="bet">{{ leaders.loser.bet }}</span>
                <span class="bet">{{ leaders.loser.x }}</span>
                <span class="win">{{ leaders.loser.win }}</span>
            </div>
        </div>

        <div
            class="session-widget-slots-table text-middle"
            ref="slots-table">
            <div class="widget-table-row"
                 v-for="(slot) in slots"
                 :key="slot.order"
                 :class="setActive(slot)"
                 :ref="setActive(slot)">
                <span class="serial text-number">{{ slot.order }}</span>
                <span class="title">{{ slot.title }}</span>
                <span class="bet text-number">{{ slot.bet }}</span>
                <span class="win text-number">{{ slot.win }}</span>
            </div>
        </div>

    </session-widget-common-frame>
</template>

<script>

    import SessionWidgetCommonFrame from '../widget-parts/SessionWidgetCommonFrame.vue'
    import SessionStatisticSection from '../widget-parts/SessionStatisticSection.vue'

    export default {
        name: 'SessionWidget',
        components: {SessionStatisticSection, SessionWidgetCommonFrame},

        props: {
            showLosers: {
                type: Boolean,
                default: false,
            },
            session: {
                type: Object,
            },
            leaders: {
                type: Object,
            },
            slots: {
                type: Array,
            },
        },
        watch: {
            slots: {
                handler() {
                    if (!!this.slots.length) {
                        this.scrollToActiveSlot()
                    }
                },
                deep: true,
            },
        },

        methods: {
            setActive(slot) {
                return slot.is_active > 0 ? 'active' : 'listItem'
            },

            scrollToActiveSlot() {
                this.$nextTick(() => {
                    const targetElement = this.$refs.active[0]
                    if (targetElement && targetElement.scrollIntoView) {
                        targetElement.scrollIntoView({behavior: 'smooth', block: 'center'})
                    }
                })
            },
        },
    }
</script>

<style lang="scss" scoped>

    @import '../../../../assets/css/variables';
    $slots-rows-gap: calc($section-padding * 0.75);
    $active-slot-font-size: calc($middle-font-size * 1.2);

    .session-widget-leaders {
        width: 100%;
        min-height: calc(3 * $middle-font-size + calc(2 * $slots-rows-gap));
        display: flex;
        gap: $container-padding;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        background-color: $inner-wrapper-bg-color;
        padding: $section-padding;
        border-radius: $border-radius-inner;
    }

    .session-widget-leaders-row {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .icon {
            width: 5%;
            text-align: start;
            margin-right: 0.25em;
        }

        .title {
            width: 45%;
            text-align: start;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            &.losers {
                width: 35%;
            }
        }

        .bet {
            width: 25%;
            text-align: center;
        }

        .win {
            width: 25%;
            text-align: end;
        }
    }


    .session-widget-slots-table {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        align-items: center;
        gap: $slots-rows-gap;
        width: 100%;
        height: calc(7 * $middle-font-size + calc($active-slot-font-size * 1.1) + calc(6 * $slots-rows-gap) + calc(4 * $section-padding));
        flex: 1 1 auto;
        padding: $section-padding;
        overflow-y: scroll;
        overflow-x: hidden;
        background-color: $inner-wrapper-bg-color;
        border-radius: $border-radius-inner;
    }

    ::-webkit-scrollbar {
        width: 0;
        background: transparent;
    }

    .widget-table-row {
        display: flex;
        flex-wrap: nowrap !important;
        flex-direction: row;
        justify-content: space-between;
        white-space: nowrap;
        gap: 3px;
        width: 100%;
        padding: 0;
        opacity: 0.8;

        .serial {
            text-align: start;
        }

        .title {
            width: 45%;
            text-align: start;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .bet {
            width: 25%;
            text-align: center;
        }

        .win {
            width: 25%;
            text-align: end;
        }


        &.active {
            align-items: center;
            font-size:  $active-slot-font-size!important;
            border: 2px solid #433a5e;
            border-radius: 0.3em;
            padding: 0;
            transition: transform 0.3s ease;
            transform: scale(1.1);
            width: 94%;
            opacity: 1;

            //&:before {
            //    content: "⇢";
            //    font-size: 0.75em;
            //}
            //
            //&:after {
            //    content: "⇠";
            //    font-size: 0.75em;
            //}

            :deep .title {
                width: 46%;
            }
        }
    }
</style>
