import axios from "@api/axios";
import {v4 as uuidv4} from 'uuid'
import {GamesRoutes, userRoutes, WidgetPublicApiRoutes} from '@api/api-routes';

export default {
    namespaced: true,

    state: {
        games: {},
        activeGame: null,
        templatedData: {}
    },
    getters: {
        games: state => state.games,
        activeGame: state => state.activeGame,
        templatedData: state => state.templatedData,

    },
    mutations: {
        games: (state, games) => state.games = games,
        activeGameUpdate: (state, gameData) => state.activeGame = {...state.activeGame, ...gameData},
        deleteActiveGame: (state) => state.activeGame = null,
        updateTemplatedData: (state, gameData) => state.templatedData = {...state.templatedData, ...gameData},
    },
    actions: {
        async getGamesList({commit}) {
            try {
                const {data} = await axios.get(GamesRoutes.getGamesList)
                commit('games', data)
            } catch (error) {
                console.log(error)
            }
        },

        async getGame({commit}, game_id) {
            try {
                const {data} = await axios.get(GamesRoutes.getGame(game_id))
                commit('activeGameUpdate', data)
                return data
            } catch (error) {
                console.log(error)
            }
        },

        async getActiveGame({commit}) {
            try {
                const {data} = await axios.get(GamesRoutes.getActiveGame)
                commit('activeGameUpdate', data)
            } catch (error) {
                console.log(error)
            }
        },

        async getWidgetData({commit}) {
            try {
                const {data} = await axios.get(WidgetPublicApiRoutes.fetchActiveGame)
                commit('activeGameUpdate', data)
            } catch (error) {
                console.log(error)
            }
        },

        async createGame({commit}, payload) {
            try {
                const {data} = await axios.post(GamesRoutes.createGame, {...payload, fe_key: uuidv4()})
                commit('activeGameUpdate', data)
                return data

            } catch (error) {
                console.log(error)
            }
        },

        async updateGame({commit}, {game_id, payload}) {
            try {
                const {data} = await axios.put(GamesRoutes.updateGame(game_id), payload)
                commit('activeGameUpdate', data)
            } catch (error) {
                console.log(error)
            }
        },

        async finishGame({commit}, game_id) {
            try {
                await axios.post(GamesRoutes.finishGame(game_id))
                commit('deleteActiveGame')
            } catch (error) {
                console.log(error)
            }
        },

        async createTemplateGameData({commit}) {
            const payload = {
                type: 'slotopoly',
                meta: {
                    tasks: [],
                    slotImages: [],
                    avatars: []
                }
            }
            try {
                const {data} = await axios.post(GamesRoutes.createGame, {...payload, fe_key: uuidv4()})
                commit('updateTemplatedData', data)
                commit('settings/setGameStorageSessionId', data.id, {root: true})
                return data
            } catch (error) {
                console.log(error)
            }
        },

        async updateTemplateGameData({commit, rootGetters}, {game_id, payload}) {
            try {
                const {data} = await axios.put(GamesRoutes.updateGame(game_id), payload)
                commit('updateTemplatedData', data)
                return data
            } catch (error) {
                console.log(error)
            }
        },

        async loadTemplateGameData({commit}, game_id) {
            try {
                const {data} = await axios.get(GamesRoutes.getGame(game_id))
                commit('updateTemplatedData', data.meta)
                return data
            } catch (error) {
                console.log(error)
            }
        },

        async initTemplateGameData({ dispatch}) {
            try {
                const {data} = await axios.post(userRoutes.fetch_me)
                const game_id = data.widget_settings?.gamesSettings?.storageSessionId
                !!game_id
                    ? await dispatch('loadTemplateGameData',game_id)
                    : await dispatch('createTemplateGameData')
            } catch (error) {
                console.log(error)
            }
        },
    }
}
