<template>
    <div class="flex gap-2 align-items-start justify-content-start">
        <Button v-for="game in gamesList"
                :label="game.title"
                class="p-button"
                @click="handleClick(game)"
        />
    </div>
</template>

<script>
    import Button from 'primevue/button'
    import router from "@/router.js";

    export default {
        name: 'GamesCatalog',
        components: {
            Button
        },
        methods: {
            handleClick(game) {
                console.log("(GamesCatalog.vue:23) ---> game:", game);
                router.push(
                        {path: game.path})
                        .catch(err => {
                            console.error("navigation error:", err);
                        })
            }
        },


        computed: {
            gamesList() {
                return [
                    {
                        title: 'Sea Battle',
                        type: 'sea_battle',
                        path: '/games/sea-battle'
                    },
                    {
                        title: 'Slotopoly',
                        type: 'slotopoly',
                        path: '/games/slotopoly'
                    }
                ]
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
