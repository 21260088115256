import {adminRoutes} from '@api/api-routes'
import axios from "@api/axios"

export default {
	namespaced: true,

	getters: {
		isAdmin: (state, rootGetters, rootState) => {
			return rootState.user.me.role === "admin"
		},
	},
	actions: {
		createUser({state}, payload) {
			return new Promise((resolve, reject) => {
				axios.post(adminRoutes.createUser, payload)
					.then((response) => {
						resolve(response)
					})
					.catch((error) => {
						reject(error)
						console.log(error)
					})
			})
		},
	},
}
