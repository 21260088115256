<template>
  <div class="faq-page">
    <!-- <div class="players" v-if="ruLocale"> -->
    <div class="players">
      <h3>Widget functionality overview</h3>
      <youtube :video-id="'GsHDYQ1ds9A'" :fitParent="true" ></youtube>
      <br>
      <h3>Settings manual</h3>
      <youtube :video-id="'IAVH1KXYe4c'" :fitParent="true" ></youtube>
    </div>
    <!-- <div class="players" v-else>
      add ENGLISH Video Manuals
      <br>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "FAQ",

  computed: {
    ruLocale() {
      return this.$store.getters['user/isRuzke']
    }
  }
}
</script>

<style lang="scss" scoped>
.faq-page {
  height: 100vh;
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
}

.players {
  width: 95%;
}
</style>