import {slotsRoutes} from '@api/api-routes'
import axios from "@api/axios";
import {uniqBy} from 'lodash'

export default {
    namespaced: true,

    state: {
        allSlots: [],
        providers: [],
        searchResults: [],
        loading: false,
    },

    getters: {
        allSlots: state => state.allSlots,
        searchResults: state => state.searchResults,
        loading: state => state.loading,
        providers: state => uniqBy(state.providers, 'attributes.title_norm'),
    },

    actions: {
        fetchSearched({commit}, payload) {
            commit('SET_LOADING', true)

            return new Promise((resolve, reject) => {
                axios.get(slotsRoutes.fetchSearched({
                        searchValue: payload.searchValue,
                        page: payload.page ?? 1,
                        id: payload.id,
                    }))
                    .then((response) => {
                        resolve(response)
                        commit('SET_FOUNDED_SLOTS', response.data.data)
                        commit('SET_LOADING', false)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },
        async createSlot({dispatch}, payload) {
            try {
                const response = await axios.post(slotsRoutes.create, payload)
                dispatch('fetchSearched', response.data.title)
                return response
            } catch (error) {
                throw error
            }
        },

        fetchProviders({commit}) {

            return new Promise((resolve, reject) => {
                axios.get(slotsRoutes.slot_providers)
                    .then((response) => {
                        commit('SET_SLOT_PROVIDERS', response.data.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },

        clearSearchResults({commit}) {
            commit('CLEAR_SEARCH_RESULTS')
        },
    },

    mutations: {
        SET_FOUNDED_SLOTS: (state, response) => state.searchResults = response,
        CLEAR_SEARCH_RESULTS: (state) => state.searchResults = [],
        SET_LOADING: (state, value) => state.loading = value,
        SET_SLOT_PROVIDERS: (state, response) => state.providers = response,
    },
}
