<template>
    <div class="archive-session">
        <archive-battle-header>
            <template #session_title>
                {{ session.title }} | {{ fixDate(session.updated_at) }}
            </template>

            <template #content_above_competitors>
                <div class="flex gap-8 p-3">
                    <div v-for="group in roundsByGroups" :key="group.id">
                        <div class="flex flex-column gap-4 h-full justify-content-around cursor-pointer">
                            <TournamentRoundCard
                                v-for="(round, i) in group"
                                :class="selectedRound(round)"
                                :key="i"
                                :round="round"
                                @click="setRoundToTable(round)" />
                        </div>
                    </div>
                </div>
            </template>

            <template #competitor1_title v-if="round.length">
                {{ round_competitor1 }}
            </template>

            <template #competitor2_title v-if="round.length">
                {{ round_competitor2 }}
            </template>
        </archive-battle-header>
        <archive-battle-table :results="round" :currency="sessionCurrency" />

    </div>
</template>

<script setup>
    import { computed, ref } from 'vue';
    import { useStore } from 'vuex';
    import TournamentRoundCard from '../../components/session-crud-tables/tournament/TournamentRoundCard.vue';
    import ArchiveBattleTable from '../../components/archive-session/battle/ArchiveBattleTable.vue';
    import ArchiveBattleHeader from '../../components/archive-session/battle/ArchiveBattleHeader.vue';
    import { useFilters } from '@composables/useFilters';

    const store = useStore();
    const { fixDate, readableCurrency } = useFilters();

    const round = ref([]);
    const round_competitor1 = ref(null);
    const round_competitor2 = ref(null);

    const session = computed(() => store.getters['sessionTournament/session']);
    const sessionCurrency = computed(() => readableCurrency(session.value?.currency));
    const roundsByGroups = computed(() => store.getters['sessionTournament/roundsByGroups']);

    const setRoundToTable = (selectedRound) => {
        console.log("(ArchiveTournamentSession.vue:59) ---> selectedRound:",selectedRound );
        round_competitor1.value = selectedRound[0].title;
        round_competitor2.value = selectedRound[1].title;

        const competitor = (i) =>
            Array.from({ length: selectedRound[0].results.length }, (_, index) => ({
                win: selectedRound[i].results[index].win,
                bet: selectedRound[i].t_bet,
                price: selectedRound[i].t_price,
                x_price: selectedRound[i].results[index].win / selectedRound[i].t_price,
            }));

        round.value = [competitor(0), competitor(1)];
    };

    const selectedRound = (selectedRound) => {
        return selectedRound[0].title === round_competitor1.value && selectedRound[1].title === round_competitor2.value
            ? 'selected-round'
            : '';
    };
</script>

<style lang="scss" scoped>
    .archive-session {
        padding: 8px;
        overflow-y: auto;
        height: 100vh;
    }

    .selected-round {
        border: 2px solid #8f61f6;
    }
</style>
