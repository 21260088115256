import {widgetHBRoutes} from '@api/api-routes'
import axios from "@api/axios"
import {centsToBucks} from '@utils/moneyConverters'

export default {
    namespaced: true,

    state: {
        session: {},
        ready: false,

    },
    getters: {
        session: state => state.session,
        ready: state => state.ready,
        firstSlot: state => {
            return state.session.slots[0].slot_id
        },
    },
    mutations: {
        session: (state, session) =>
            state.session = centsToBucks(session),
        ready: (state, boolean) =>
            state.ready = boolean,
    },

    actions: {
        async fetchSessionData({commit}, id) {
            try {
                const {data} = await axios.get(widgetHBRoutes.fetchSession(id))
                commit('session', data)
                commit('ready', true)
                return data

            } catch (error) {
                console.log(error)
                throw error
            }
        },
    }
}
