export const adminRoutes = {
    createUser: 'api/admin/user',
}

export const userRoutes = {
    login: '/api/auth/login',
    logout: '/api/auth/logout',
    fetch_me: '/api/auth/me',
    refresh: '/api/auth/refresh',
    saveSettings: '/api/widget-settings',
    getSettings: '/api/widget-settings',
}

export const sessionsCommonRoutes = {
    fetchList: '/api/v1/sessions',
    changeStatus: '/api/sessions/status',
    delete: (session_id) => `/api/sessions/${session_id}`,
    edit: (session_id) => `/api/sessions/${session_id}`,
}

export const sessionHBRoutes = {
    create: '/api/sessions',
    session: (session_id) => `/api/sessions/${session_id}`,
    addSlots: (session_id) => `/api/sessions/${session_id}/slots`,
    removeSlot: (session_slot_id) => `/api/sessions/slots/${session_slot_id}`,
    postResult: '/api/sessions/session-slot-result',
}
export const sessionBattleRoutes = {
    create: `/api/sessions/battle`,
    getSession: (session_id) => `/api/sessions/battle/${session_id}`,
    postResult: (session_id) => `/api/sessions/battle/spin-result/${session_id}`,
}

export const sessionTournamentRoutes = {
    create: '/api/sessions/tournaments',
    getSession: (session_id) => `/api/sessions/tournaments/${session_id}`,
    postResults: (session_id) => `/api/sessions/tournaments/${session_id}/result`, //apply PATCH on this endpoint
    changeViewMode: (session_id) => `/api/sessions/tournaments/${session_id}/view-mode`, //apply PATCH on this endpoint
    changeRoundPrice: (session_id) => `/api/sessions/tournaments/${session_id}/round-price`, //apply PATCH on this endpoint
}

export const singleRollRoutes = {
    postResult: `/api/slots/single-roll-result`,
    changeEntry: (roll_id) => `/api/slot-results/${roll_id}`, //apply PUT and DELETE on this endpoint
    fetchHistory: (slot_id) => `/api/slots/${slot_id}/history`,
}

export const slotsRoutes = {
    create: '/api/slots',
    fetchAll: '/api/v1/slots',
    slot_providers: '/api/v1/slot_providers',
    fetchSearched: (
        {
            searchValue,
            page,
            session_id,
        },
    ) => `/api/v1/slots?page[number]=${page}&page[size]=15&filter[title]=${searchValue}&filter[exclude_session_id]=${session_id}`,
}

export const widgetHBRoutes = {
    fetchSession: (session_id) => `/api/widget/session/${session_id}`,
}

export const widgetBattleRoutes = {
    fetchBattle: (session_id) => `/api/widget/battle/${session_id}`,
}

export const widgetSlotRoutes = {
    fetchSlot: (slot_id) => `/api/widget/slot/${slot_id}`,
}

export const WidgetPublicApiRoutes = {
    getActiveSession: `/api/widget/session/active`,
    setActiveSession: (session_id) => `/api/widget/session/active/${session_id}`,
    getActiveSlot: `/api/widget/slot/selected`,
    setActiveSlot: (slot_id) => `/api/widget/slot/selected/${slot_id}`,

    //with public token
    fetchWidgetSettings: '/api/public/widget/settings',
    fetchSlotWidget: '/api/public/widget/slot',
    fetchAnyActiveSession: '/api/public/widget/session',
    fetchActiveGame: '/api/public/widget/game',

}

export const GamesRoutes = {
    createGame: `/api/games`, //POST
    getGamesList: `/api/games`, //GET
    updateGame: (game_id) => `/api/games/${game_id}`, //PUT
    getGame: (game_id) => `/api/games/${game_id}`, //GET
    finishGame: (game_id) => `/api/games/${game_id}/finish`, //POST
    getActiveGame: `/api/games/active`,

    getWidget: (game_id) => `/api/public/widget/games/${game_id}`
}

export const FileStorageRoutes = {
    upload: '/api/files/upload',
    get: `/api/files`,
}

export const apiRoutes = {
    admin: adminRoutes,
    user: userRoutes,
    sessionsCommon: sessionsCommonRoutes,
    sessionHB: sessionHBRoutes,
    sessionBattle: sessionBattleRoutes,
    singleRoll: singleRollRoutes,
    slots: slotsRoutes,
    widgetHB: widgetHBRoutes,
    widgetBattle: widgetBattleRoutes,
    widgetSlot: widgetSlotRoutes,
    widgetPublicApi: WidgetPublicApiRoutes,
    filesStorage: FileStorageRoutes,
}
