<template>
    <div>
        <slot name="activator" :toggleDialog="toggleDialog"/>

        <Dialog
            class="dialog-add"
            position="left"
            v-model:visible="isDialogOpen"
            :style="{ width: '50%' }"
            modal>

            <template #header>
                <h3>
                    <slot name="title"/>
                </h3>
            </template>

            <SlotsManagerAddSlot
                :single="isSingleSelection"
                ref="addSlotTable"
                @new-selected="setSelected"/>

            <template #footer>
                <div class="slots-manager-footer">

                    <SlotsManagerCreateNewSlot
                        @slot:created="searchSlot"
                        @slot:duplicate="searchSlot"/>

                    <Button
                        v-if="!isSingleSelection"
                        class="p-button-success p-button-sm"
                        label="Add selected"
                        icon="pi pi-play"
                        :disabled="buttonDisabled"
                        @click="handleAddClick"/>
                </div>
            </template>
        </Dialog>
    </div>
</template>

<script>
    import Dialog from 'primevue/dialog'
    import Button from 'primevue/button'

    import SlotsManagerAddSlot from './SlotsManagerAddSlot.vue'
    import Constants from '@resources/js/constants/general.js'
    import SlotsManagerCreateNewSlot from './SlotsManagerCreateNewSlot.vue'

    export default {
        name: 'SlotsManagerWrapper',
        components: {
            SlotsManagerCreateNewSlot,
            Dialog,
            Button,
            SlotsManagerAddSlot,
        },
        props: {
            sessionType: {
                default: null,
                type: String,
            },
        },
        data() {
            return {
                selectedSlots: [],
                isDialogOpen: false,
            }
        },
        computed: {
            isSingleRoll() {
                return this.sessionType === Constants.TYPE_SINGLE_ROLL
            },

            isBonusBattle() {
                return this.sessionType === Constants.TYPE_BATTLE
            },

            isTournament() {
                return this.sessionType === Constants.TYPE_BONUS_TOURNAMENT
            },

            isSingleSelection() {
                return this.isSingleRoll || this.isBonusBattle || this.isTournament
            },

            buttonDisabled() {
                return Array.isArray(this.selectedSlots) && this.selectedSlots.length === 0
                    || typeof this.selectedSlots === 'object' && Object.keys(this.selectedSlots).length === 0
            },

            slotsInSessionIDs() {
                return this.$store.getters['sessionHB/slotsIDs']
            },
        },
        methods: {
            toggleDialog() {
                this.isDialogOpen = !this.isDialogOpen
            },

            async setSelected(data) {
                this.selectedSlots = data
                if (this.isSingleSelection) {
                    await setTimeout(() => this.handleAddClick(), 300)
                }
            },

            searchSlot(slotName) {
                this.$refs.addSlotTable.setSearchValue(slotName)
                this.$refs.addSlotTable.fetchSlots()
            },

            handleAddClick() {
                switch (true) {
                    case this.isSingleRoll:
                        this.addSingleRollSlot()
                        break
                    case this.isBonusBattle || this.isTournament:
                        this.addBattleOrTournamentCompetitor()
                        break
                    default:
                        this.addSlots()
                }
            },

            async addSlots() {
                const currentSlots = this.slotsInSessionIDs
                const newSlots = this.selectedSlots.map(slot => slot.id)
                const payload = {slots: [...currentSlots, ...newSlots]}

                try {
                    await this.$store.dispatch('sessionHB/addSlots', payload)
                } catch (error) {
                    this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: 1000})
                } finally {
                    this.$emit('slots-selected')
                    this.toggleDialog()
                }
            },

            addBattleOrTournamentCompetitor() {
                this.$emit('slots-selected', {...this.selectedSlots})
                this.toggleDialog()
            },

            async addSingleRollSlot() {
                const payload = {
                    id: this.selectedSlots.id,
                    title: this.selectedSlots.attributes.title,
                }
                try {
                    await this.$store.dispatch('singleRoll/addSlot', payload)
                } catch (error) {
                    this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: 1000})
                } finally {
                    this.$emit('slots-selected')
                    this.toggleDialog()
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    :deep .p-component-overlay {
        position: absolute
    }

    .dialog-add {
        height: 100%;
        width: 65%;

        .p-dialog {
            height: 100%;
            width: 95%;
        }
    }

    :deep .add-button {
        margin: 8px !important;
    }

    :deep .p-dialog-content {
        height: 100%;
    }

    :deep .p-dialog-header {
        padding: 0.5rem 1.5rem !important;
    }

    .slots-manager-footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }
</style>
