import {sessionsCommonRoutes, WidgetPublicApiRoutes} from '@api/api-routes'
import axios from "@api/axios";
import {bucksToCents, centsToBucks} from '@utils/moneyConverters'

export default {
    namespaced: true,

    state: {
        sessionsList: [],
        activeSession: {},
    },

    getters: {
        sessionsList: state => state.sessionsList,
        activeSession: state => state.activeSession,
    },
    mutations: {
        sessionsList: (state, sessions) => {
            state.sessionsList = centsToBucks(sessions)
        },
        activeSession: (state, session) => {
            state.activeSession = session
        },
    },

    actions: {
        async fetchSessionsList({commit}, params) {
            try {
                const {data} = await axios.get(sessionsCommonRoutes.fetchList, params)
                commit("sessionsList", data.data)
                return data.data
            } catch (error) {
                throw error
            }
        },

        async editSession({getters, commit, dispatch}, {id, payload}) {
            try {
                const {data} = await axios.put(sessionsCommonRoutes.edit(id), {
                    ...bucksToCents(payload),
                })

                return data
            } catch (error) {
                throw error
            }
        },

        async deleteSession({commit}, id) {
            try {
                const {data} = await axios.delete(sessionsCommonRoutes.delete(id))
                commit("sessionHB/resetState", {root: true})
                commit("sessionBattle/resetState", {root: true})
                return data
            } catch (error) {
                throw error
            }
        },

        async changeStatus({commit}, payload) {
            try {
                const {data} = await axios.put(sessionsCommonRoutes.changeStatus, payload)
                return data
            } catch (error) {
                throw error
            }
        },

        async setActiveSession({commit}, session_id) {
            try {
                return await axios.post(WidgetPublicApiRoutes.setActiveSession(session_id))
            } catch (error) {
                console.log(error)
                throw error
            }
        },
        async getPrivateActiveSession({commit}) {
            try {
                return await axios.get(WidgetPublicApiRoutes.getActiveSession)
            } catch (error) {
                console.log(error)
                throw error
            }
        },

        async getPublicActiveSession({commit}) {
            try {
                return await axios.get(WidgetPublicApiRoutes.fetchAnyActiveSession)
            } catch (error) {
                console.log(error)
                throw error
            }
        },
    },
}
