import {WidgetPublicApiRoutes} from '@api/api-routes'
import axios from "@api/axios";
import {centsToBucks} from '@utils/moneyConverters'

export default {
    namespaced: true,

    state: {
        slot: {},
        ready: false,
    },

    getters: {
        slot: state => state.slot,
        showWidgets: (state, getters, rootState) => {
            return rootState.settings.showWidgets
        },
        ready: state => state.ready,
    },

    mutations: {
        slot: (state, response) =>
            state.slot = centsToBucks(response),
        ready: (state, ready) => state.ready = ready,
    },

    actions: {
        async handleSlotIdChange({getters, dispatch}, id) {
            await dispatch('setSlotId', id)
            if (getters.showWidgets) {
                await dispatch('getPrivateSelectedSlot', id)
            }
        },

        async setSlotId({commit}, id) {
            try {
                return await axios.post(WidgetPublicApiRoutes.setActiveSlot(id))
            } catch (error) {
                console.log(error)
                throw error
            }
        },

        async getPublicSelectedSlot({commit}) {
            try {
                const {data} = await axios.get(WidgetPublicApiRoutes.fetchSlotWidget)
                commit('slot', data)
                commit('ready', true)
                return data
            } catch (error) {
                console.log(error)
                throw error
            }
        },

        async getPrivateSelectedSlot({commit}) {
            try {
                const {data} = await axios.get(WidgetPublicApiRoutes.getActiveSlot)
                commit('slot', data)
                commit('ready', true)
                return data
            } catch (error) {
                console.log(error)
                throw error
            }
        },
    }
}

