<template>
  <Accordion
      :multiple="false"
      @tab-open="$emit('update:slot', $event)">
    <AccordionTab
        v-for="slot in slots"
        :key="slot.slot_id">
      <template #header>
        <table-cell-title>
          {{ slot.title }}
        </table-cell-title>
      </template>
      <PVPTable
          :slot-results="slot.results"
          @update="$emit('update:result', $event)"/>
    </AccordionTab>
  </Accordion>
</template>

<script>
  import Accordion from 'primevue/accordion'
  import AccordionTab from 'primevue/accordiontab'
  import PVPTable from './PVPTable.vue'
  import TableCellTitle from '../../misc/Title.vue'

  export default {
    components: {TableCellTitle, PVPTable, AccordionTab, Accordion},
    props: {
      slots: [],
    },
  }
</script>

<style lang="scss" scoped>
  .bonus-battle {
    overflow-y: auto;
    height: 90vh
  }

  :deep .p-accordion-content {
      padding: 0 !important;
  }

  :deep .p-accordion-header-link {
      padding: 8px !important;
  }
</style>
