<template>
  <div class="session-dashboard">

    <div class="card-new" @click="startSession">
      <i class="pi pi-plus" style="font-size: 3rem"></i>
      <p>Create new session</p>
    </div>

    <h3>Sessions in progress:</h3>
    <SessionsInProgressList :sessions="sessionsInProgress"/>

    <h3>Archive:</h3>
    <ArchiveSessionsList :sessions="finishedSessions"/>

    <Loading v-if="loading"/>

  </div>
</template>

<script>

  import Loading from '@components/Loading.vue'
  import ArchiveSessionsList from './ArchiveSessionsList.vue'
  import SessionsInProgressList from './SessionsInProgressList.vue'
  import Constants from '@resources/js/constants/general.js'

  export default {
    name: 'SessionsDashboard',

    components: {
      Loading,
      ArchiveSessionsList,
      SessionsInProgressList,
    },
    data() {
      return {
        loading: false,
      }
    },
    computed: {
      isAuthenticated() {
        return this.$store.getters['user/isAuthenticated']
      },
      sessionsList() {
        return this.$store.getters['sessions/sessionsList']
      },
      sessionsInProgress() {
        return this.sessionsList.filter((session) => session.attributes.status !== Constants.STATUS_FINISHED)
      },
      finishedSessions() {
        return this.sessionsList.filter((session) => session.attributes.status === Constants.STATUS_FINISHED)
      },
    },

    mounted() {
      if (this.isAuthenticated) {
        this.getSessionsList()
      }
    },

    methods: {
      async getSessionsList() {
        this.loading = true
        try {
          await this.$store.dispatch('sessions/fetchSessionsList')
        } catch (error) {
          console.log('getSessionsList', error)
          this.loading = false
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Loading sessions went wrong',
            life: 1000,
          })
        } finally {
          this.loading = false
        }
      },

      startSession() {
        this.$router.push({name: 'session-creator'})
      },
    },
  }
</script>

<style lang="scss" scoped>
  .session-dashboard {
    padding: 8px;
    overflow-y: auto;
    height: 100vh
  }

  .card-new {
    border-radius: 6px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 8px;
    width: 150px;
    height: 150px;
    text-align: center;
    font-size: 16px;
    gap: 8px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 4px, rgba(0, 0, 0, 0.23) 0px 2px 4px;
    justify-content: center;
    background-color: #8f61f6;
    color: white;

    p {
      margin: 0;
    }
  }
</style>
