<template>
    <Toolbar>
        <template #start>
            {{ sessionTitle }}
            <i class="p-toolbar-separator"/>
            <label>Currency: </label>
            <i class="p-toolbar-separator"/>
            {{ readableSessionCurrency }}
            <i class="p-toolbar-separator"/>
            <label>Total Dep: </label>
            <i class="p-toolbar-separator"/>
            {{ deposit | spacer }}

        </template>

        <template #end>
            <Button icon="pi pi-ellipsis-v"
                    class="p-button-text p-button-sm"
                    @click="toggleMenu"/>
            <Menu ref="menu" :model="menuItems" :popup="true" :style="'width: 110px'"/>
            <i class="p-toolbar-separator"/>
            <Button label="Finish"
                    iconPos="right"
                    icon="pi pi-check-circle"
                    class="p-button-sm"
                    @click.stop="finishConfirm"/>
        </template>
    </Toolbar>
</template>

<script>
    import Toolbar from 'primevue/toolbar'
    import Button from 'primevue/button'
    import Menu from 'primevue/menu'

    import Constants from '@resources/js/constants/general.js'
    import {readableCurrency} from '@utils/formatters'
    import {depositFormatter} from '../widgets/session-hunt-or-buy/utils'

    export default {
        name: 'InfoToolbar',
        components: {
            Toolbar,
            Button,
            Menu,
        },
        props: {
            sessionData: {
                required: true,
            },
        },
        data() {
            return {
                menuItems: [
                    {
                        label: 'Edit',
                        icon: 'pi pi-pencil',
                        command: () => {
                            this.$emit('edit-start')
                        },
                    },
                    {
                        label: 'Delete',
                        icon: 'pi pi-trash',
                        command: () => {
                            this.deleteConfirm()
                        },
                    },
                ],
            }
        },
        computed: {
            sessionTitle() {
                return this.sessionData.title
            },

            readableSessionCurrency() {
                return readableCurrency(this.sessionData.currency)
            },

            deposit() {
                return depositFormatter(this.sessionData)
            },
        },

        methods: {
            toggleMenu(event) {
                this.$refs.menu.toggle(event)
            },

            finishConfirm() {
                this.$confirm.require({
                    message: 'Are you ready to finish this session ?',
                    icon: 'pi pi-info-circle',
                    acceptClass: 'p-button-success',
                    acceptIcon: 'pi pi-check',
                    acceptLabel: 'Finish',
                    rejectClass: 'p-button-text',
                    rejectLabel: 'No',
                    accept: () => {
                        this.finishSession()
                    },
                })
            },

            deleteConfirm() {
                this.$confirm.require({
                    message: 'Are you sure want to delete  this session ?',
                    icon: 'pi pi-info-circle',
                    acceptClass: 'p-button-success',
                    acceptIcon: 'pi pi-trash',
                    acceptLabel: 'Delete',
                    rejectClass: 'p-button-text',
                    rejectLabel: 'No',
                    accept: () => {
                        this.deleteSession()
                    },
                })
            },

            deleteSession() {
                this.$store
                    .dispatch('sessions/deleteSession', this.sessionData.id)
                    .then((response) => {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Deleted',
                            detail: 'Session deleted',
                            life: 1000,
                        })
                        this.$router.push({name: 'dashboard'})
                        this.clearSessionsData()
                    })
                    .catch((error) => {
                        this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: 2000})
                    })
            },

            async finishSession() {
                const payload = {
                    'session_id': this.sessionData.id,
                    'status': Constants.STATUS_FINISHED,
                }
                try {
                    await this.$store.dispatch('sessions/changeStatus', payload)
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Congratulations!',
                        detail: 'You finished your game',
                        life: 1000,
                    })
                    await this.$router.push({name: 'dashboard'})
                    this.clearSessionsData()

                } catch (error) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: error,
                        life: 1000,
                    })
                }
            },

            clearSessionsData() {
                this.$store.commit('sessionHB/resetState')
                this.$store.commit('sessionBattle/resetState')
                this.$store.commit('sessions/activeSession', {})
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
