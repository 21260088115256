<template>
    <div class="pvp-results-table">
        <div v-for="(slot, index) in round"
             :key="slot.id">
            <div class="flex align-items-center gap-4 opacity-50 text-small mb-1">
                <competitor-literal-icon
                    :letter="competitorIcon(slot, index)"/>
                <span>
                    Price: {{ slot.t_price ?? slot.price }}
                </span>
                <span>
                    Played: {{ slot.spins_finished }}
                </span>

            </div>
            <div class="results">
                <div v-for="result in formattedSlotResults(slot.results)">
                <span
                    :style="'text-wrap: nowrap'"
                    class="text-middle">
                    <span class="text-white-alpha-50 text-small">
                        {{ result.serial }}:
                    </span>
                    {{ fixMoney(result.win) }}
                </span>
                </div>
            </div>
            <hr v-if="index===0" class="opacity-70 hr-thin">
        </div>
    </div>
</template>

<script>
    import CompetitorLiteralIcon from '../../misc/CompetitorLiteralIcon.vue'
    import {fixPercents, fixMoney} from "@utils/formatters.js";

    export default {
        components: {CompetitorLiteralIcon},
        props: {
            round: [],
            visibleBuys: {
                type: Number,
                default: 6,
            },
        },
        methods: {
            fixMoney,
            competitorIcon(slot, i) {
                const fallbackPreset = ['A', 'B']
                const letter =  slot?.icon ?? slot.color
                return letter.startsWith('#') ? fallbackPreset[i] : letter
            },

            formattedSlotResults(results) {
                let slotResults = results.map((obj, index) => ({
                    ...obj,
                    serial: index + 1,
                }))

                const noWinIndex = slotResults.findIndex(result => result.win===null)
                if (slotResults.length <= this.visibleBuys) {
                    return slotResults
                } else if (noWinIndex <= this.visibleBuys) {
                    return slotResults.slice(0, this.visibleBuys)
                } else
                    return slotResults.slice(noWinIndex - this.visibleBuys, noWinIndex)

            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../../../../assets/css/variables';

    .pvp-results-table {
        padding: $section-padding;
        width: 100%;
        border-radius: $border-radius-inner;
        background-color: $inner-wrapper-bg-color;
    }

    .results {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        row-gap: 0.1em;
        column-gap: 0.2em;
    }
</style>
