<template>
    <div>
        <BoardRedactor v-if="redactorOpened" @start-game="startGame"/>
        <div v-show="gameControllerOpened">
            <GameController ref="playBoard" :board="board" :ships="ships" @shoot="updateGame"/>
        </div>
    </div>
</template>

<script setup>
    import {computed, ref} from 'vue';
    import {useStore} from "vuex";
    import BoardRedactor from '@components/games/sea-battle/BoardRedactor.vue';
    import GameController from '@components/games/sea-battle/GameController.vue';

    const store = useStore();

    const emit = defineEmits(['game:start', 'game:update', 'game:finish']);


    const redactorOpened = ref(true);
    const gameControllerOpened = ref(false);
    const board = ref([]);
    const ships = ref([]);
    const activeGame = computed(() => store.getters["games/activeGame"]);

    const startGame = (boardData, shipsData) => {
        const boardArray = Object.values(boardData);
        board.value = boardArray.map((_, colIndex) => boardArray.map(row => row[colIndex]));
        ships.value = shipsData;
        createGame();
        redactorOpened.value = false;
        gameControllerOpened.value = true;
    };

    const setActiveGame = () => {
        board.value = store.getters["games/activeGame"].meta?.board || store.getters["games/activeGame"].meta.startPositions.board;
        ships.value = store.getters["games/activeGame"].meta.startPositions.ships;
        redactorOpened.value = false;
        gameControllerOpened.value = true;
    };

    const clearGameData = () => {
        board.value = [];
        ships.value = [];
        redactorOpened.value = true;
        gameControllerOpened.value = false;
    }

    const createGame = () => {
        emit('game:update', {
            startPositions:
                {
                    board: board.value,
                    ships: ships.value
                },
        })
    };

    const updateGame = (shootData) => {
        emit('game:update', {
            shootData:
                {
                    shoots: {
                        ...activeGame.value?.meta?.shootData?.shoots,
                        ...shootData?.shoots
                    },
                    stats: {
                        ...activeGame.value?.meta?.shootData?.stats,
                        ...shootData?.stats
                    }
                },
            startPositions:
                {
                    board: board.value,
                    ships: ships.value
                },
            board: shootData?.board,
            lastShoot: shootData?.lastShoot
        });
    };

    const finishGame = () => {
        redactorOpened.value = true;
        gameControllerOpened.value = false;
        board.value = [];
        ships.value = [];
        // emit('game:finish');
    };
    defineExpose({
        finishGame,
        setActiveGame,
        clearGameData
    })
</script>
