<template>
    <div :style="bgColor">
        <component :is="widgetComponent" :style="sessionWidgetStyle" v-if="!isGameWidget"/>
        <GameWidgetWrapper :show-stats="false" v-if="isGameWidget" :style="{ '--spot-size': '1rem' }"/>

        <SlotWidget v-if="!isGameWidget"/>
    </div>
</template>

<script>
    import SlotWidget from '@components/widgets/slot-statistics-widget/SlotStatsWidgetWrapper.vue'
    import BattleWidget from '@widgets/bonus-battle/BattleWidgetWrapper.vue';
    import TournamentWidget from '@widgets/tournament/TournamentWidgetWrapper.vue';
    import SessionWidgetWrapper from '@widgets/session-hunt-or-buy/SessionWidgetWrapper.vue';

    import {isBattle, isHuntOrBuy, isTournament} from '@utils/formatters'
    import GameWidgetWrapper from "@widgets/games/GameWidgetWrapper.vue";


    export default {
        name: 'WidgetContainer',

        components: {
            GameWidgetWrapper,
            SlotWidget,
        },

        data() {
            return {
                SessionWidgetReady: false,
            }
        },

        computed: {

            isGameWidget() {
                return this.$route.name === 'games'
            },

            sessionWidgetStyle() {
                const sessionWidgetWidth = this.$store.getters['settings/sessionWidgetWidth']
                const fontFactor = sessionWidgetWidth / 400
                return {
                    width: sessionWidgetWidth + 'px',
                    'max-height': sessionWidgetWidth + 'px',
                    // 'aspect-ratio' : '1/1.2',
                    'font-size': (fontFactor * 18) + 'px',
                }
            },

            activeSession() {
                return this.$store.getters['sessions/activeSession']
            },

            isBattle() {
                return isBattle(this.activeSession.session_type)
            },

            isTournament() {
                return isTournament(this.activeSession.session_type)
            },

            widgetComponent() {
                if (this.isBattle) {
                    return BattleWidget;
                }
                if (this.isTournament) {
                    return TournamentWidget;
                }
                return SessionWidgetWrapper;
            },

            bgColor() {
                const color = this.$store.getters['settings/bgColor']
                if (color !== '') return {'background-color': '#' + color}
            },

        },


        methods: {
            async getActiveSession() {
                const {data} = await this.$store.dispatch('sessions/getPrivateActiveSession')
                const activeSessionType = data?.session_info?.session_type
                this.$store.commit('sessions/activeSession', {
                    session_type: activeSessionType,
                    session_id: data?.session_info?.id,
                })

                if (isBattle(activeSessionType)) {
                    await this.$store.commit('widgetBattle/session', data)
                } else if (isTournament(activeSessionType)) {
                    await this.$store.commit('sessionTournament/session', data)
                } else if (isHuntOrBuy(activeSessionType)) {
                    await this.$store.commit('widgetHB/session', data)
                    this.$store.commit('widgetHB/ready', true)
                }
            },

            async getSelectedSlot() {
                await this.$store.dispatch('widgetSlot/getPrivateSelectedSlot')
            },
        },

        async mounted() {
            await this.getActiveSession()
            await this.getSelectedSlot()
        },

    }
</script>

<style lang="scss" scoped>

</style>
