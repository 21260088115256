import {sessionBattleRoutes, sessionHBRoutes} from '@api/api-routes'
import axios from '@api/axios'
import {centsToBucks} from '@utils/moneyConverters'
import {isBonusHunt, readableCurrency} from '@utils/formatters'

export default {
	namespaced: true,

	state: {
		session: {},
	},
	getters: {
		session: state => state.session,
        isBonusHunt: state => isBonusHunt(state.session?.session_info?.session_type),
        readableCurrency: state => readableCurrency(state.session.session_info?.currency) ?? '',
	},
	mutations: {
		session: (state, value) => state.session = value,
	},
	actions: {
		async fetchGeneralSession({commit}, id) {
			try {
				const {data} = await axios.get(sessionHBRoutes.session(id))
				commit('session', centsToBucks(data))
				return data
			} catch (error) {
				throw error
			}
		},
		async fetchBattleSession({commit}, id) {
			try {
				const {data} = await axios.get(sessionBattleRoutes.getSession(id))
				commit("session", centsToBucks(data))
				return data
			} catch (error) {
				throw error
			}
		},
	},
}
