<template>
    <component :is="component"/>
</template>

<script>
    import TournamentWidgetAks from './TournamentWidgetAks.vue'
    import TournamentWidget from './TournamentWidget.vue'

    export default {
        name: 'TournamentWidgetWrapper',

        computed: {
            component() {
                return this.$store.getters['user/me'].id === 841
                    ? TournamentWidgetAks
                    : TournamentWidget
            },
        },
    }
</script>
