<template>
  <div class="bonus-battle">
    <PVPWrapper
        :slots="slots"
        @update:result="postResult"/>
  </div>
</template>

<script>
    import Constants from '@resources/js/constants/general.js'
    import PVPWrapper from '../pvp/PVPWrapper.vue'


    export default {
    name: 'BonusBattle',

    components: {
      PVPWrapper,
    },

    computed: {
      slots() {
        return this.$store.getters['sessionBattle/slots']
      },

      sessionStatus() {
        return this.$store.getters['sessionBattle/session'].status
      },

      sessionId() {
        return this.$store.getters['sessionBattle/session'].id
      },
    },

    methods: {

      async postResult(event) {
        let {data, value, newValue, field} = event
        data[field] = newValue
        if (value !== newValue) {
          try {
            const payload = {
              id: data.id,
              win: newValue,
            }

            await this.$store.dispatch('sessionBattle/postResult', payload)
            await this.setSessionStatus()
          } catch (error) {
            this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: 1500})
          }
        }
      },

      async setSessionStatus() {
        if (this.sessionStatus !== Constants.STATUS_INIT) {
          return
        }
        const payload = {
          'session_id': this.sessionId,
          'status': Constants.STATUS_IN_PROGRESS,
        }
        try {
          await this.$store.dispatch('sessions/changeStatus', payload)
        } catch (error) {
          this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: 1500})
        }
      },
    },
  }
</script>

