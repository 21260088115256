<template>
  <div>
    <editable-toolbar
      v-if="editable"
      @edit-complete="finishEdit"
      :session-data="sessionData"/>
    <info-toolbar
      v-else
      @edit-start="startEdit"
      :session-data="sessionData"/>
  </div>
</template>

<script>
  import EditableToolbar from './EditableToolbar.vue'
  import InfoToolbar from './InfoToolbar.vue'
  import Constants from '@resources/js/constants/general.js'

  export default {
    name: 'ToolbarWrapper',
    components: {
      InfoToolbar,
      EditableToolbar,
    },

    data() {
      return {
        editable: false,
      }
    },

    computed: {
      sessionData() {
        const type = this.$store.getters['sessions/activeSession']?.session_type

        switch (type) {
          case Constants.TYPE_BATTLE:
            return this.$store.getters['sessionBattle/session']
          case Constants.TYPE_BONUS_BUY:
            return this.$store.getters['sessionHB/session']
          case Constants.TYPE_BONUS_HUNT:
            return this.$store.getters['sessionHB/session']
          case Constants.TYPE_BONUS_TOURNAMENT:
            return this.$store.getters['sessionTournament/session']
        }
      },
    },

    methods: {
        startEdit() {
        this.editable = true
      },
      finishEdit() {
        this.editable = false
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
