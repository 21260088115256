import {createRouter, createWebHistory} from 'vue-router'
import store from '@store/store'
import Constants from '@constants/general'

//system
import Forbidden from './pages/system/403.vue'
import NotFound from './pages/system/404.vue'

//admin
import CreateUser from './pages/admin/CreateUser.vue'

//sessions
import SessionWrapper from './pages/SessionWrapper.vue'
import BonusBuyTable from './components/session-crud-tables/BonusBuyTable.vue'
import BonusHuntTable from './components/session-crud-tables/BonusHuntTable.vue'
import BonusBattleWrapper from './components/session-crud-tables/battle/BonusBattleWrapper.vue'
import BonusTournamentIndex from './components/session-crud-tables/tournament/TournamentIndex.vue'
import TournamentPlayersBoard from './components/session-crud-tables/tournament/TournamentPlayersBoard.vue'
import TournamentRound from './components/session-crud-tables/tournament/TournamentRound.vue'

import SessionCreator from './pages/create-session/SessionCreator.vue'

//archive
import ArchiveBattleSession from './pages/archive-session/ArchiveBattleSession.vue'
import ArchiveGeneralSession from './pages/archive-session/ArchiveGeneralSession.vue'
import ArchiveTournamentSession from './pages/archive-session/ArchiveTournamentSession.vue'

//pages
import DashBoard from './pages/dashboard/DashBoard.vue'
import WidgetSettings from './pages/WidgetSettings.vue'
import SingleRoll from './pages/SingleRoll.vue'
import FAQ from './pages/FAQ.vue'
import Auth from './pages/Auth.vue'

//Not Casino Activities
import GamesIndex from "@pages/games/GamesIndex.vue";
import GamesCatalog from "@pages/games/GamesCatalog.vue";

//landing
import LandingPage from './pages/landing-page/LPIndex.vue'

//OBS pages
import OBSSessionWidget from '@pages/obs-widgets/OBSSessionWidget.vue'
import OBSSlotWidget from '@pages/obs-widgets/OBSSlotWidget.vue'
import OBSGameWidget from "@pages/obs-widgets/OBSGameWidget.vue";

const routes = [
    {
        path: '/',
        name: 'dashboard',
        redirect: '/dashboard'
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: DashBoard,
    },

    {
        path: '/session',
        name: 'session',
        component: SessionWrapper,
        props: true,
        children: [],
        beforeEnter: (to, from, next) => {
            checkActiveSession(to, from, next, store)
        },
    },
    {
        path: '/session',
        name: 'session-wrapper',
        component: SessionWrapper,
        props: true,
        children: [
            {
                path: 'bonus-buy/:id',
                name: `session-${Constants.TYPE_BONUS_BUY}`,
                component: BonusBuyTable,
                props: true,
                beforeEnter: async (to, from, next) => {
                    await preFetch(to, from, next, sessionHB)
                    next()
                },
            },
            {
                path: 'bonus-hunt/:id',
                name: `session-${Constants.TYPE_BONUS_HUNT}`,
                component: BonusHuntTable,
                props: true,
                beforeEnter: async (to, from, next) => {
                    await preFetch(to, from, next, sessionHB)
                    next()
                },
            },
            {
                path: 'bonus-battle/:id',
                name: `session-${Constants.TYPE_BATTLE}`,
                component: BonusBattleWrapper,
                props: true,
                beforeEnter: async (to, from, next) => {
                    await preFetch(to, from, next, sessionBattle)
                    next()
                },
            },
            {
                path: 'bonus-tournament/:id',
                name: `session-${Constants.TYPE_BONUS_TOURNAMENT}`,
                component: BonusTournamentIndex,
                props: true,
                beforeEnter: async (to, from, next) => {
                    await preFetch(to, from, next, sessionTournament)
                    next()
                },
            },
            {
                path: 'session-creator',
                name: 'session-creator',
                component: SessionCreator,
                props: true,
            },
        ],
    },
    //make it nested
    {
        path: '/games',
        name: 'games',
        component: GamesCatalog,
        props: true,
    },
    {
        path: '/games/sea-battle',
        name: 'Sea Battle',
        component: GamesIndex,
        props: {gameType: 'sea_battle'}
    },
    {
        path: '/games/slotopoly',
        name: 'Slotopoly',
        component: GamesIndex,
        props: {gameType: 'slotopoly'}

    },

    {
        path: '/archive/bonus-buy/:id',
        name: `archive-${Constants.TYPE_BONUS_BUY}`,
        component: ArchiveGeneralSession,
        props: true,
        beforeEnter: async (to, from, next) => {
            await preFetch(to, from, next, archiveHB)
            next()
        },
    },
    {
        path: '/archive/bonus-hunt/:id',
        name: `archive-${Constants.TYPE_BONUS_HUNT}`,
        component: ArchiveGeneralSession,
        props: true,
        beforeEnter: async (to, from, next) => {
            await preFetch(to, from, next, archiveHB)
            next()
        },
    },
    {
        path: '/archive/bonus-battle/:id',
        name: `archive-${Constants.TYPE_BATTLE}`,
        component: ArchiveBattleSession,
        props: true,
        beforeEnter: async (to, from, next) => {
            await preFetch(to, from, next, archiveBattle)
            next()
        },
    },
    {
        path: '/archive/bonus-tournament/:id',
        name: `archive-${Constants.TYPE_BONUS_TOURNAMENT}`,
        component: ArchiveTournamentSession,
        props: true,
        beforeEnter: async (to, from, next) => {
            await preFetch(to, from, next, sessionTournament)
            next()
        },
    },
    {
        path: '/single-roll',
        name: 'single-roll',
        component: SingleRoll,
        props: true,
    },
    {
        path: '/faq',
        name: 'faq',
        component: FAQ,
    },
    {
        path: '/settings',
        name: 'settings',
        component: WidgetSettings,
        props: true,
    },
    {
        path: '/promo',
        name: 'Casino Widget',
        component: LandingPage,
        meta: {layout: 'landing'},
    },

    {
        path: '/admin',
        name: 'create-user',
        component: CreateUser,
        props: true,
        beforeEnter: (to, from, next) => {
            checkAdminRights(to, from, next)
        },
    },
    {
        path: '/auth',
        name: 'Authentication',
        component: Auth,
    },
    {
        path: '/logout',
        name: 'logout',
        beforeEnter: (to, from, next) => {
            store.dispatch('user/logout')
                .then(() => {
                    next('/auth')
                })
        },
    },
    {
        path: '/session-widget',
        name: 'session-widget',
        component: OBSSessionWidget,
        props: true,
        meta: {layout: 'obs'},
    },
    {
        path: '/slot-widget',
        name: 'slot-widget',
        component: OBSSlotWidget,
        props: true,
        meta: {layout: 'obs'},
    },
    {
        path: '/game-widget',
        name: 'game-widget',
        component: OBSGameWidget,
        props: route => ({
            publicToken: route.query.public_token
        }),
        meta: {layout: 'obs'},
    },
    // {
    //     path: '/game-widget-extra',
    //     name: 'game-widget-extra',
    //     component: OBSGameWidget,
    //     props: route => ({
    //         publicToken: route.query.public_token
    //     }),
    //     meta: {layout: 'obs'},
    // },
    {
        path: '/forbidden',
        name: 'forbidden',
        component: Forbidden,
    },
    {
        path: '/404',
        name: 'NotFound',
        component: NotFound,
    },
    // {
    //     path: '/:pathMatch(.*)*',
    //     component: NotFound,
    // },
]

const router = createRouter({
    history: createWebHistory(),
    base: import.meta.env.APP_URL,
    routes
})


function checkAdminRights(to, from, next) {
    const isAdmin = store.getters['admin/isAdmin']
    if (isAdmin) {
        next()
    } else {
        next({path: '/forbidden'})
    }
}

function checkActiveSession(to, from, next, store) {
    const session = store.getters['sessions/activeSession']

    if (from.path && from.path.includes('archive')) {
        next({path: '/'});
        return
    }

    if (session.session_id) {
        next({
            name: `session-${session.session_type}`,
            params: {
                id: parseInt(session.session_id),
            },
        })

    } else {
        next({path: '/'})
    }
}

const sessionHB = 'sessionHB/fetchSession'
const sessionBattle = 'sessionBattle/fetchBattle'
const sessionTournament = 'sessionTournament/fetchTournament'
const archiveHB = 'archive/fetchGeneralSession'
const archiveBattle = 'archive/fetchBattleSession'

async function preFetch(to, from, next, fetchFunction) {
    const session_id = to.params.id
    try {
        const {session_info} = await store.dispatch(fetchFunction, session_id)
        await setActiveSession(session_info)
    } catch (error) {
        handleError(error, next)
    }
}

async function setActiveSession(session_info) {
    await store.dispatch('sessions/setActiveSession', parseInt(session_info.id))

    const session = {
        session_type: session_info.session_type,
        session_id: parseInt(session_info.id),
    }
    store.commit('sessions/activeSession', session)
}

function handleError(error, next) {
    if (error.response) {
        if (error.response.status === 403) {
            next({name: 'forbidden'})
        } else if (error.response.status === 404) {
            next({name: 'NotFound'})
        } else {
            console.log(error)
            next({name: 'dashboard'})
        }
    } else {
        console.log(error)
        next({name: 'dashboard'})
    }
}

router.beforeEach((to, from, next) => {

    // const isAuthenticated = store.getters['user/isAuthenticated']
    // const isAuthPath = to.path === '/auth'
    // const urlNotFound = !router.options.routes.find(route => route.path === to.path)
    // const excludedRoutes = ['/session-widget', '/slot-widget', '/promo']
    //
    // if (isAuthenticated || isAuthPath || excludedRoutes.includes(to.path)) {
    //     next()
    // } else if (urlNotFound) {
    //     next({name: 'NotFound'})
    // } else {
    //     next({name: 'Authentication'})
    // }
    //TODO guard disabled for tests
    next()
})

export default router
