<template>
    <div class="flex align-items-center gap-2">
        <div v-if="$slots.round_number" class="flex justify-content-start text-middle text-number">
            <slot name="round_number" />
        </div>

        <div class="flex flex-column" style="width:95%">
            <div v-for="(slot, i) in competitors" :key="i">
                <div class="flex justify-content-between">
                    <div class="flex align-items-center gap-1" style="width: 85%">
                        <competitor-literal-icon
                            class="text-middle"
                            :style="{ opacity: isWinner(competitors, i) ? 1 : 0.5 }"
                            :letter="competitorIcon(slot, i)"
                        />
                        <div
                            class="text-middle px-2 uppercase long-title"
                            :style="{ opacity: isWinner(competitors, i) ? 1 : 0.5 }"
                        >
                            {{ slot.title }}
                        </div>
                    </div>

                    <span
                        class="pl-2 border-left-1 border-white text-middle"
                        style="width: 15%"
                        :style="{ color: colorByProfit(slot), opacity: isWinner(competitors, i) ? 1 : 0.75 }"
                    >
            {{ fixPercents(slot.slot_score ?? 0) }}
          </span>
                </div>
                <p-v-p-competitors-divider v-if="i === 0">
                    <slot name="stage_name" />
                </p-v-p-competitors-divider>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { computed, toRefs } from 'vue';
    import CompetitorLiteralIcon from '../../misc/CompetitorLiteralIcon.vue';
    import { profitabilityColorCoding } from '../../session-crud-tables/tournament/utils';
    import PVPCompetitorsDivider from './PVPCompetitorsDivider.vue';
    import { useFilters } from '@composables/useFilters';

    const props = defineProps({
        competitors: {
            type: Array,
            required: true,
        },
    });

    const { competitors } = toRefs(props);
    const { fixPercents } = useFilters();

    const isRoundFinished = computed(() =>
        competitors.value.every(
            (competitor) =>
                competitor.results &&
                Array.isArray(competitor.results) &&
                competitor.results.every((attempt) => attempt.win !== null)
        )
    );

    const colorByProfit = (competitor) => {
        return profitabilityColorCoding(competitor);
    };

    const isWinner = (round, index) => {
        if (isRoundFinished.value) {
            const winnerIndex = round.reduce((maxIndex, competitor, currentIndex) => {
                if (competitor.slot_score === 0) {
                    return maxIndex;
                }
                if (
                    maxIndex === null ||
                    parseFloat(competitor.slot_score) > parseFloat(round[maxIndex].slot_score)
                ) {
                    return currentIndex;
                }
                return maxIndex;
            }, null);
            return index === winnerIndex;
        } else {
            return true;
        }
    };

    const competitorIcon = (slot, i) => {
        const fallbackPreset = ['A', 'B'];
        const letter = slot?.icon ?? slot.color;
        return letter.startsWith('#') ? fallbackPreset[i] : letter;
    };
</script>

<style lang="scss" scoped>
    @import '../../../../assets/css/variables';
</style>
