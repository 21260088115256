<template>
    <div class="widget-session-stats text-middle">

        <div class="widget-block-small">
        <span>
          <slot name="title1">
              BONUS
          </slot>
        </span>
            <span class="value">
                <slot name="value1"/>
            </span>
        </div>

        <div class="widget-block-small middle">
            <span>
                <slot name="title2">
                    WIN / DEP
                </slot>
            </span>
            <span class="value">
                <slot name="value2"/>
            </span>
        </div>

        <div class="widget-block-small">
            <span>
                <slot name="title3">
                    AVG X
                </slot>
            </span>
            <span class="value">
                <slot name="value3"/>
            </span>
        </div>
    </div>
</template>

<script>
    export default {}
</script>

<style lang="scss" scoped>
    @import '../../../../assets/css/variables';

    .widget-session-stats {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        border-radius: $border-radius-inner;
        background-color: $inner-wrapper-bg-color;
        padding: $section-padding 0;
    }

    .widget-block-small {
        width: 25%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.3em;
        justify-content: center;

        &.middle {
            width: 50%;
            border-left: 1px solid #EED6D3;
            border-right: 1px solid #EED6D3;
        }
    }

</style>
