<template>
    <div class="file-manager-wrapper">
        <Dialog v-model:visible="isFileGalleryOpened"
                header="Галерея изображений"
                modal position="left"
                dismissableMask
                @update:visible="isFileGalleryOpened = false"
        >
            <file-uploader @image:uploaded="selectImage" :type="type"/>

            <div class="gallery">
                <img v-for="image in images"
                     :key="image.id"
                     :src="image.original_url"
                     class="gallery-item"
                     alt="image"
                     @click="selectImage(image.original_url)">
            </div>
        </Dialog>

            <img :src="image" class="image-preview" alt="image" @click="isFileGalleryOpened = true" :style="imageSize">



    </div>
</template>

<script setup>
    import {computed, onMounted, ref} from "vue";
    import {useStore} from "vuex";
    import Dialog from "primevue/dialog";
    import FileUploader from "@components/misc/FileUploader.vue";
    import placeholder from "@public/images/img_placeholder.png";

    const props = defineProps(['modelValue','type', 'size']);
    const emit = defineEmits(['update:modelValue']);

    const store = useStore();

    const isFileGalleryOpened = ref(false);

    const image = computed(() =>{
        return props.modelValue || placeholder
    })

    const images = computed(() => store.getters[`mediaFiles/${props.type}`]);

    const selectImage = (url) => {
        emit('update:modelValue', url);
    }

    const imageSize = computed(() => {
        return {
            maxWidth: props.size ?? '3em',
            maxHeight: props.size ?? '3em',
        }
    })

</script>

<style lang="scss" scoped>
    .file-manager-wrapper {
        display: flex;
        align-items: center;
    }

    .image-preview {
        //max-width: 3em;
        //max-height: 3em;
        cursor: pointer;
        border: 2px solid transparent;
        &:hover {
            border: 2px solid #8f61f6;
        }
    }

    .gallery {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: auto;
        justify-items: center;
        gap: 8px;

        .gallery-item {
            cursor: pointer;
            width: 150px;
            height: 150px;
            border: 2px solid transparent;
            &:hover {
                border: 2px solid #8f61f6;
            }
        }
    }

</style>