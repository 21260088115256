export const STATUS_INIT = "init"
export const STATUS_CREATED = "created"
export const STATUS_IN_PROGRESS = "in_progress"
export const STATUS_FINISHED = "finished"

export const TYPE_BONUS_HUNT = "bonus_hunt"
export const TYPE_BONUS_BUY = "bonus_buy"
export const TYPE_REGULAR = "regular"
export const TYPE_BATTLE = "bonus_battle"
export const TYPE_SINGLE_ROLL = "single_roll"
export const TYPE_BONUS_TOURNAMENT = 'bonus_tournament'

export const CURRENCY_USD = "usd"
export const CURRENCY_RUB = "rub"
export const CURRENCY_EUR = "eur"

export default Object.freeze({
    STATUS_CREATED: STATUS_CREATED,
    STATUS_INIT: STATUS_INIT,
    STATUS_IN_PROGRESS: STATUS_IN_PROGRESS,
    STATUS_FINISHED: STATUS_FINISHED,

    TYPE_BONUS_HUNT: TYPE_BONUS_HUNT,
    TYPE_BONUS_BUY: TYPE_BONUS_BUY,
    TYPE_REGULAR: TYPE_REGULAR,
    TYPE_BATTLE: TYPE_BATTLE,
    TYPE_SINGLE_ROLL: TYPE_SINGLE_ROLL,
    TYPE_BONUS_TOURNAMENT: TYPE_BONUS_TOURNAMENT,

    CURRENCY_USD: CURRENCY_USD,
    CURRENCY_RUB: CURRENCY_RUB,
    CURRENCY_EUR: CURRENCY_EUR,
})
