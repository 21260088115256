<template>
    <div class="landing">
        <header class="flex flex-column md:flex-row justify-content-between px-2 md:px-1">
            <a href="https://casinowidget.pro">
                <div class="block text-3xl md:text-6xl font-bold text-white">CasinoWidget</div>
            </a>


            <div class="flex justify-content-between">
                <div class="lang-changer flex justify-content-center align-items-center cursor-pointer mr-4">
                  <span
                      @click="lang='eng'"
                      class="font-bold text-white text-s md:text-m"
                      :class="lang==='eng' ? 'opacity-100' : 'opacity-60'">
                    ENG
                  </span>
                    <span class="font-semibold text-white"> / </span>
                    <span
                        @click="lang='ru'"
                        class="mr-2 font-bold text-white text-s md:text-m"
                        :class="lang==='ru' ? 'opacity-100' : 'opacity-60'">
                    RU
                  </span>
                </div>
                <a href="https://t.me/oMOBYo" target="_blank" class="flex justify-content-center align-items-center">
                    <i class="pi pi-telegram mr-2"></i>
                    <div class="block text-m md:text-xl font-bold text-primary flex justify-content-center align-items-center"
                         v-if="lang==='ru'">Запросить демо
                    </div>
                    <div class="block text-xl font-bold text-primary flex justify-content-center align-items-center"
                         v-else>Request Demo
                    </div>
                </a>
            </div>
        </header>
        <landing-page-r-u-s v-if="lang==='ru'"/>
        <landing-page-e-n-g v-else/>
    </div>
</template>

<script>
    import LandingPageENG from './LandingPageENG.vue'
    import LandingPageRUS from './LandingPageRUS.vue'
    import Button from 'primevue/button'

    export default {
        components: {Button, LandingPageRUS, LandingPageENG},
        data() {
            return {
                lang: 'eng',
            }
        },
        mounted() {
            this.langCheck()
        },

        methods: {
            langCheck() {
                const language = window.navigator.userLanguage || window.navigator.language
                if (language === 'ru-RU' || language === 'RU-ru') {
                    this.lang = 'ru'
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    header {
        background-color: #57cf4c;
        position: sticky;
        top: 0;
        z-index: 9;

        a {
            text-decoration: none;
        }
    }
</style>
