<template>
    <div class="card"
    @click="$emit('click')">
        <div class="card-header">
<!--            <p style="font-size: 12px">{{ session.attributes.created_at | truncate(10) }}</p>-->
            <p style="font-size: 12px">{{ session.attributes.created_at  }}</p>
        </div>
        <p>{{ session.attributes.title }}</p>
        <div class="chips">
<!--            <div class="chip">AVG X {{ session.attributes.average_x | fixDecimal(1) }}</div>-->
            <div class="chip">AVG X {{ session.attributes.average_x }}</div>
<!--            <div class="chip">WIN {{ session.attributes.actual_win | combiner }}{{ currency }}</div>-->
            <div class="chip">WIN {{ session.attributes.actual_win }}{{ currency }}</div>
        </div>
        <div class="card-footer">
        </div>
    </div>
</template>

<script>
  import Constants from '@resources/js/constants/general.js'

  export default {
        name: 'SessionListItemCard',
        props: {
            session: {
                required: true,
            },
        },
        computed: {
            currency() {
                if (this.session.attributes.currency === Constants.CURRENCY_USD) return '$'
                if (this.session.attributes.currency === Constants.CURRENCY_EUR) return '€'
                if (this.session.attributes.currency === Constants.CURRENCY_RUB) return '₽'
                else return ''
            },
        },
    }
</script>

<style lang="scss" scoped>
    .card {
        border-radius: 6px;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 8px;
        width: 150px;
        height: 150px;
        text-align: center;
        font-size: 16px;
        gap: 8px;
        cursor: pointer;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px, rgba(0, 0, 0, 0.23) 0px 1px 3px;

        &:hover {
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px, rgba(0, 0, 0, 0.23) 0px 1px 3px;
        }

        p {
            margin: 0;
        }
    }

    .card-header {
        display: flex;
        justify-content: flex-start;
        color: #8f61f6;
        width: 100%;
    }

    .card-footer {
        display: flex;
        justify-content: flex-end;
        color: #8f61f6;
        width: 100%;
    }

    .chips {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        width: 100%;

        .chip {
            padding: 4px;
            border-radius: 4px;
            background-color: #8f61f6;
            font-size: 14px;
        }
    }
</style>
