<template>
    <div class="flex flex-row align-items-end gap-8 pt-2">
        <div>
            <p>Currency:</p>
            <currency-selector v-model="formData.selectedCurrency"/>
        </div>

        <slot name="deposit"/>
    </div>

</template>

<script>
    import InputNumber from 'primevue/inputnumber'
    import CurrencySelector from '../../components/misc/CurrencySelect.vue'

    export default {
        components: {CurrencySelector, InputNumber},
        props: {
            modelValue: {
                type: Object,
                default: {},
            },
        },
        computed: {
            formData: {
                get() {
                    return this.modelValue
                },
                set(newValue) {
                    this.$emit('update:modelValue', newValue)
                },
            },
        },
    }
</script>

