<template>
    <div>
        <!--first frame-->
        <div class="intro-section">
            <div class="diagonal-background"/>
            <div class="grid grid-nogutter h-full">
                <div class="col-12 lg:col-6 overflow-hidden flex-order-1 lg:flex-order-0 h-25rem lg:h-screen flex justify-content-center">
                    <div class="img-slider h-full p-1 lg:p-8">
                        <div class="image block h-full"/>
                    </div>
                </div>
                <div
                    class="col-12 lg:col-6 p-2 lg:p-6 text-center lg:text-left flex align-items-center h-18rem md:h-full">
                    <section>
                        <span class="block text-3xl md:text-6xl font-bold mb-1">Real-time Widgets</span>
                        <div class="text-3xl md:text-6xl text-primary font-bold mb-3">for your stream</div>
                        <p class="mt-0 mb-4 text-700 line-height-3">Show your audience the full statistics of your game and
                            the
                            results of past victories!</p>
                    </section>
                </div>
            </div>
        </div>

        <!--divider-->
        <div class="col max-h-1 bg-primary opacity-70"></div>

<!--        features section-->
        <div class="surface-section px-4 py-4 md:px-6 lg:px-8 text-center">
            <div class="mb-1 font-bold text-2xl">
                <span class="text-primary">One solution for all types of gaming</span>
            </div>
            <br>
            <div class="grid">
                <div class="col-12 md:col-4 mb-1  px-5">
          <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
            <i class="text-5xl text-purple-500">BH & BB</i>
          </span>
                    <div class="text-900  font-medium">Bonus Hunt and Bonus Buy</div>
                    <span class="text-700 text-sm line-height-3">Classic games mode</span>
                </div>
                <div class="col-12 md:col-4 mb-1 px-5">
          <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
            <i class="text-5xl text-purple-500">Battle</i>
          </span>
                    <div class="text-900  font-medium">Bonus Buy Battle</div>
                    <span
                        class="text-700 text-sm line-height-3">Competition between two slots on your choose</span>
                </div>
                <div class="col-12 md:col-4 mb-1 px-5">
          <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
            <i class="text-5xl text-purple-500">Tournament</i>
          </span>
                    <div class="text-900  font-medium">Bonus Tournament</div>
                    <span class="text-700 text-sm line-height-3">Competition between 8 slots. Which is the best luck?</span>
                </div>
            </div>
        </div>
        <!--        divider-->
        <div class="col max-h-1 bg-primary opacity-70"></div>


<!--        tariffs-->
        <div class="surface-ground px-4 py-4 md:px-6 lg:px-8">
            <div class="text-900 font-bold text-3xl md:text-6xl mb-4 text-center">Pricing Plans</div>

            <div class="flex flex-column md:flex-row justify-content-evenly align-items-stretch">
                <div class="p-2 h-100 tariff-card">
                        <div class="shadow-2 p-3 h-full flex flex-column surface-card" style="border-radius: 6px">
                            <div class="text-900 font-medium text-xl mb-2">Classic</div>
                            <div class="text-600">Bonus Hunt & Bonus Buy widgets</div>
                            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
                            <div class="flex align-items-center">
                                <span class="font-bold text-2xl text-900">$25</span>
                                <span class="font-bold text-2xl text-600">/$20*</span>
                                <span class="font-bold text-2xl text-600">/$17.5**</span>
                                <span class="ml-2 font-medium text-600">per month</span>
                            </div>

                            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
                            <ul class="list-none p-0 m-0 flex-grow-1">
                                <li class="flex align-items-center mb-3">
                                    <i class="pi pi-check-circle text-green-500 mr-2"></i>
                                    <span>Bonus Buy Widget</span>
                                </li>
                                <li class="flex align-items-center mb-3">
                                    <i class="pi pi-check-circle text-green-500 mr-2"></i>
                                    <span>Bonus Hunt Widget</span>
                                </li>
                                <li class="flex align-items-center mb-3">
                                    <i class="pi pi-check-circle text-green-500 mr-2"></i>
                                    <span>Statistics storage</span>
                                </li>
                                <li class="flex align-items-center mb-3">
                                    <i class="pi pi-exclamation-circle  mr-2"></i>
                                    <span>Our promo link</span>
                                </li>
                            </ul>
                            <hr class="mb-3 mx-0 border-top-1 border-none surface-border mt-auto" />
                            <a href="https://t.me/oMOBYo" target="_blank">
                                <Button label="Request Demo" class="p-3 w-full mt-auto" />
                            </a>
                        </div>
                    </div>

                <div class="p-2 h-100 tariff-card">
                        <div class="shadow-2 p-3 h-full flex flex-column surface-card" style="border-radius: 6px">
                            <div class="text-900 font-medium text-xl mb-2">All Games</div>
                            <div class="text-600">All game-types widgets</div>
                            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
                            <div class="flex align-items-center">
                                <span class="font-bold text-2xl text-900">$50</span>
                                <span class="font-bold text-2xl text-600">/$40*</span>
                                <span class="font-bold text-2xl text-600">/$35**</span>
                                <span class="ml-2 font-medium text-600">per month</span>
                            </div>

                            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
                            <ul class="list-none p-0 m-0 flex-grow-1">
                                <li class="flex align-items-center mb-3">
                                    <i class="pi pi-check-circle text-green-500 mr-2"></i>
                                    <span>Bonus Buy Widget</span>
                                </li>
                                <li class="flex align-items-center mb-3">
                                    <i class="pi pi-check-circle text-green-500 mr-2"></i>
                                    <span>Bonus Hunt Widget</span>
                                </li>
                                <li class="flex align-items-center mb-3">
                                    <i class="pi pi-check-circle text-green-500 mr-2"></i>
                                    <span>Bonus Battle Widget</span>
                                </li>
                                <li class="flex align-items-center mb-3">
                                    <i class="pi pi-check-circle text-green-500 mr-2"></i>
                                    <span>Bonus Tournament Widget</span>
                                </li>
                                <li class="flex align-items-center mb-3">
                                    <i class="pi pi-check-circle text-green-500 mr-2"></i>
                                    <span>Statistics storage</span>
                                </li>
                            </ul>
                            <hr class="mb-3 mx-0 border-top-1 border-none surface-border mt-auto" />
                            <a href="https://t.me/oMOBYo" target="_blank">
                                <Button label="Request Demo" class="p-3 w-full mt-auto" />
                            </a>
                        </div>
                    </div>

                <div class="p-2 h-100 tariff-card">
                        <div class="shadow-2 p-3 h-full flex flex-column surface-card" style="border-radius: 6px">
                            <div class="text-900 font-medium text-xl mb-2">Full stats</div>
                            <div class="text-600">All widgets + personal slot's statistics</div>
                            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
                            <div class="flex align-items-center">
                                <span class="font-bold text-2xl text-900">$75</span>
                                <span class="font-bold text-2xl text-600">/$60*</span>
                                <span class="font-bold text-2xl text-600">/$52.5**</span>
                                <span class="ml-2 font-medium text-600">per month</span>
                            </div>

                            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
                            <ul class="list-none p-0 m-0 flex-grow-1">
                                <li class="flex align-items-center mb-3">
                                    <i class="pi pi-check-circle text-green-500 mr-2"></i>
                                    <span>Bonus Buy Widget</span>
                                </li>
                                <li class="flex align-items-center mb-3">
                                    <i class="pi pi-check-circle text-green-500 mr-2"></i>
                                    <span>Bonus Hunt Widget</span>
                                </li>
                                <li class="flex align-items-center mb-3">
                                    <i class="pi pi-check-circle text-green-500 mr-2"></i>
                                    <span>Bonus Battle Widget</span>
                                </li>
                                <li class="flex align-items-center mb-3">
                                    <i class="pi pi-check-circle text-green-500 mr-2"></i>
                                    <span>Bonus Tournament Widget</span>
                                </li>
                                <li class="flex align-items-center mb-3">
                                    <i class="pi pi-check-circle text-green-500 mr-2"></i>
                                    <span>Slot Statistics Widget</span>
                                </li>
                                <li class="flex align-items-center mb-3">
                                    <i class="pi pi-check-circle text-green-500 mr-2"></i>
                                    <span>Statistics storage</span>
                                </li>
                            </ul>
                            <hr class="mb-3 mx-0 border-top-1 border-none surface-border mt-auto" />
                            <a href="https://t.me/oMOBYo" target="_blank">
                                <Button label="Request Demo" class="p-3 w-full mt-auto" />
                            </a>
                        </div>
                    </div>

                <div class="p-2 h-100 tariff-card">
                        <div class="shadow-2 p-3 h-full flex flex-column surface-card" style="border-radius: 6px">
                            <div class="text-900 font-medium text-xl mb-2">Data maniac</div>
                            <div class="text-600">All widgets + API access to your data </div>
                            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
                            <div class="flex align-items-center">
                                <span class="font-bold text-2xl text-900">$100</span>
                                <span class="font-bold text-2xl text-600">/$80*</span>
                                <span class="font-bold text-2xl text-600">/$70**</span>
                                <span class="ml-2 font-medium text-600">per month</span>
                            </div>

                            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
                            <ul class="list-none p-0 m-0 flex-grow-1">
                                <li class="flex align-items-center mb-3">
                                    <i class="pi pi-check-circle text-green-500 mr-2"></i>
                                    <span>Bonus Buy Widget</span>
                                </li>
                                <li class="flex align-items-center mb-3">
                                    <i class="pi pi-check-circle text-green-500 mr-2"></i>
                                    <span>Bonus Hunt Widget</span>
                                </li>
                                <li class="flex align-items-center mb-3">
                                    <i class="pi pi-check-circle text-green-500 mr-2"></i>
                                    <span>Bonus Battle Widget</span>
                                </li>
                                <li class="flex align-items-center mb-3">
                                    <i class="pi pi-check-circle text-green-500 mr-2"></i>
                                    <span>Bonus Tournament Widget</span>
                                </li>
                                <li class="flex align-items-center mb-3">
                                    <i class="pi pi-check-circle text-green-500 mr-2"></i>
                                    <span>Slot Statistics Widget</span>
                                </li>
                                <li class="flex align-items-center mb-3">
                                    <i class="pi pi-check-circle text-green-500 mr-2"></i>
                                    <span>Statistics storage</span>
                                </li>
                                <li class="flex align-items-center mb-3">
                                    <i class="pi pi-check-circle text-green-500 mr-2"></i>
                                    <span>Generic API</span>
                                </li>
                            </ul>
                            <hr class="mb-3 mx-0 border-top-1 border-none surface-border mt-auto" />
                            <a href="https://t.me/oMOBYo" target="_blank">
                                <Button label="Request Demo" class="p-3 w-full mt-auto" />
                            </a>
                        </div>
                    </div>

                <div class="p-2 h-100 tariff-card">
                        <div class="shadow-2 p-3 h-full flex flex-column surface-card" style="border-radius: 6px; opacity: 0.7">
                            <div class="text-900 font-medium text-xl mb-2">Premium</div>
                            <div class="text-600">In development</div>
                            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
                            <div class="flex align-items-center">
                                <span class="font-bold text-2xl text-900">$280</span>
                                <span class="font-bold text-2xl text-600">/$225*</span>
                                <span class="font-bold text-2xl text-600">/$200**</span>
                                <span class="ml-2 font-medium text-600">per month</span>
                            </div>

                            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
                            <ul class="list-none p-0 m-0 flex-grow-1">
                                <li class="flex align-items-center mb-3">
                                    <i class="pi pi-check-circle text-green-500 mr-2"></i>
                                    <span>Bonus Buy Widget</span>
                                </li>
                                <li class="flex align-items-center mb-3">
                                    <i class="pi pi-check-circle text-green-500 mr-2"></i>
                                    <span>Bonus Hunt Widget</span>
                                </li>
                                <li class="flex align-items-center mb-3">
                                    <i class="pi pi-check-circle text-green-500 mr-2"></i>
                                    <span>Bonus Battle Widget</span>
                                </li>
                                <li class="flex align-items-center mb-3">
                                    <i class="pi pi-check-circle text-green-500 mr-2"></i>
                                    <span>Bonus Tournament Widget</span>
                                </li>
                                <li class="flex align-items-center mb-3">
                                    <i class="pi pi-check-circle text-green-500 mr-2"></i>
                                    <span>Slot Statistics Widget</span>
                                </li>
                                <li class="flex align-items-center mb-3">
                                    <i class="pi pi-check-circle text-green-500 mr-2"></i>
                                    <span>Statistics storage</span>
                                </li>
                                <li class="flex align-items-center mb-3">
                                    <i class="pi pi-check-circle text-green-500 mr-2"></i>
                                    <span>Generic API</span>
                                </li>
                                <li class="flex align-items-center mb-3">
                                    <i class="pi pi-check-circle text-green-500 mr-2"></i>
                                    <span>Public accessible page with your stats</span>
                                </li>
                                <li class="flex align-items-center mb-3">
                                    <i class="pi pi-check-circle text-green-500 mr-2"></i>
                                    <span>Twitch/Youtube/Kick integration</span>
                                </li>
                            </ul>
                            <hr class="mb-3 mx-0 border-top-1 border-none surface-border mt-auto" />
                            <a href="https://t.me/oMOBYo" target="_blank">
                                <Button label="Request Demo" class="p-3 w-full mt-auto" disabled/>
                            </a>
                        </div>
                    </div>

            </div>
            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
            <span class="font-bold text-sm text-900">* - 6 month billing </span>
            <br/>
            <span class="font-bold text-sm text-900">** - 12 month billing</span>
        </div>

<!--        divider-->
        <div class="col max-h-1 bg-primary opacity-70"></div>

<!--        interface frame-->
        <div class="grid grid-nogutter surface-section text-800">
            <div class="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center ">
                <section>
                    <span class="block text-3xl md:text-6xl font-bold mb-1">Handy interface</span>
                    <div class="text-3xl md:text-6xl text-primary font-bold mb-3">to enter winnings,</div>
                    <p class="mt-0 mb-4 text-700 line-height-3">editing your typos and managing slots in the game</p>
                </section>
            </div>
            <div class="col-12 md:col-6 overflow-hidden">
                <img src="@resources/assets/images/lp/table.png" alt="UI" class="md:ml-auto block md:h-full">
            </div>
        </div>

<!--        divider-->
        <div class="col max-h-1 bg-primary opacity-70"></div>
        <br>
<!--        currency frame-->
        <div class="surface-section px-4 py-2 md:px-6 lg:px-8 text-center">
            <div class="mb-1 font-bold text-2xl">
                <span class="text-primary text-center">Multi-currency</span>
                <br>
                <span class="text-900 text-center">Play even on foreign sites</span>
            </div>
            <br>
            <div class="grid">
                <div class="col-4 mb-1 px-5">
          <span class="p-2 shadow-2 mb-1 inline-block surface-card" style="border-radius: 10px">
            <i class="text-5xl text-purple-500">$</i>
          </span>
                </div>
                <div class="col-4 mb-1 px-5">
          <span class="p-2 shadow-2 mb-1 inline-block surface-card" style="border-radius: 10px">
            <i class="text-5xl text-purple-500">€</i>
          </span>
                </div>
                <div class="col-4 mb-1 px-5">
          <span class="p-2 shadow-2 mb-1 inline-block surface-card" style="border-radius: 10px">
            <i class="text-5xl text-purple-500">₽</i>
          </span>
                </div>
                <br>
                <div class="col-12 font-bold text-2xl">
                    <span class="text-900 text-center">All data will be saved in the currency of your choice </span>
                    <span class="text-900 text-center">and ready for conversion at the current exchange rate</span>
                </div>
                <br>
            </div>
        </div>


<!--        divider-->
        <div class="col max-h-1 bg-primary opacity-70"></div>

<!--        history frame-->
        <div class="grid grid-nogutter surface-section text-800">
            <div class="col-12 md:col-6 overflow-hidden">
                <img src="@resources/assets/images/lp/hystory.png" alt="Hystory" class="md:ml-auto block md:h-full"
                     style="clip-path: polygon(0% 0px, 58% 0%, 74% 100%, 0px 100%)">
            </div>
            <div class="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center ">
                <section>
                    <span class="block text-3xl md:text-6xl font-bold mb-1">History of all gaming sessions</span>
                    <div class="text-3xl md:text-6xl text-primary font-bold mb-3">by dates, categories, winnings.</div>
                    <p class="mt-0 mb-4 text-700 line-height-3">Keep a history of your victories and track your
                        progress</p>
                </section>
            </div>
        </div>

<!--        divider-->
        <div class="col max-h-1 bg-primary opacity-70"></div>

<!--        summary frame-->
        <div class="grid grid-nogutter surface-section text-800">
            <div class="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center ">
                <section>
                    <span class="block text-3xl md:text-6xl font-bold mb-1">Simple Widget Settings</span>
                    <div class="text-3xl md:text-6xl text-primary font-bold mb-3">Hundreds of pre-added slots</div>
                    <div class="text-3xl md:text-6xl text-primary font-bold mb-3">Privacy of your data</div>
                </section>
            </div>
            <div class="col-12 md:col-6 overflow-hidden">
                <img src="@resources/assets/images/lp/settings.png" alt="Settings" class="md:ml-auto block md:h-full">
            </div>
        </div>

<!--        divider-->
        <div class="col max-h-1 bg-primary opacity-70"></div>

<!--        CTA-->
        <div class="surface-section px-4 py-8 md:px-6 lg:px-8">
            <div class="text-700 text-center">
                <div class="text-900 font-bold text-5xl mb-3">Liked?</div>
                <div class="text-700 text-2xl mb-5">Apply for demo access and try it yourself!</div>
                <a href="https://t.me/oMOBYo">
                    <Button icon="pi pi-telegram"
                            class="font-bold px-5 py-3 p-button-raised p-button white-space-nowrap"></Button>
                </a>
                <a href="mailto:slotterapplication@gmail.com">
                    <Button icon="pi pi-at"
                            class="font-bold px-5 py-3 p-button-raised p-button white-space-nowrap"></Button>
                </a>
            </div>
        </div>

    </div>
</template>

<script>
    import Button from 'primevue/button'
    import 'primeflex/primeflex.css'


    export default {

        components: {
            Button,
        },
    }
</script>

<style lang="scss" scoped>
    .intro-section {
        position: relative;
        height: 100vh;
        overflow: hidden;
    }

    .diagonal-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #57cf4c;
        clip-path: polygon(0 0, 68% 0, 87% 100%, 0% 100%);
        z-index: -1;
    }

    .img-slider {
        width: 100%;

        @media (600px < width) {
            width: 70%;
        }

        .image {
            background: url(~@resources/assets/images/lp/widget-slides/bb.png) no-repeat center;
            background-size: 100%;
            animation: 10s slides infinite step-end;
            transform: none;
            display: block;
        }
    }

    @keyframes slides {
        25% {
            background: url(~@resources/assets/images/lp/widget-slides/battle.png) no-repeat center;
            background-size: 100%;
        }

        50% {
            background: url(~@resources/assets/images/lp/widget-slides/tournament.png) no-repeat center;
            background-size: 100%;
        }

        75% {
            background: url(~@resources/assets/images/lp/widget-slides/stats.png) no-repeat center;
            background-size: 100%;
        }

        100% {
            background: url(~@resources/assets/images/lp/widget-slides/bb.png) no-repeat center;
            background-size: 100%;
        }
    }

    .tariff-card {
        position: relative;
        top: 0;
        transition: top ease 0.5s;
        &:hover {
            top: -10px;
        }
    }

    a {
        text-decoration: none;
    }
</style>
