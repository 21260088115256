<template>
    <div class="sessions-grid">
        <session-list-item
            v-for="session in sessions"
            :key="session.id"
            :session="session"
            @click="openSession(session)"/>
    </div>
</template>

<script>
    import SessionListItem from '../../components/dashboard/SessionListItem.vue'

    export default {
        name: 'ArchiveSessionsList',
        components: {
            SessionListItem,
        },

        props: {
            sessions: {
                type: Array,
                required: true,
                default: [],
            },
        },
        methods: {
            openSession(session) {
                this.$router.push(
                    {
                        name: `archive-${session.attributes.session_type}`,
                        params: {id: session.id},
                    })
            },
        },
    }
</script>

<style lang="scss" scoped>
    .sessions-grid {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
    }


</style>
