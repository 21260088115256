<template>
    <div class="title">
    <span ref="title">
      <slot/>
    </span>
        <i class="pi pi-copy"
           @click.stop="copyTitle"/>
    </div>
</template>

<script>
    export default {
        name: 'TableCellTitle',

        methods: {
            async copyTitle() {
                const input = this.$refs['title'].innerText
                try {
                    await navigator.clipboard.writeText(input)
                } catch (err) {
                    console.error('Failed to copy: ', err)
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    .title {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
    }

    .pi-copy {
        color: #8b5cf6;
        margin-left: 8px;
        cursor: pointer
    }
</style>
