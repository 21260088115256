<template>
    <div class="slot-stats-widget" v-if="ready">
        <div class="slot-stats-widget-header">
            <span class="text-big long-title">{{ slotData.title }}</span>
            <span class="text-small">{{ slotData.provider }}</span>
        </div>
        <div class="slot-stats-data-table">
            <div class="slot-data-table-column">
                <div class="slot-data-table-column-header">
                    <i class="pi pi-info-circle"></i>
                    <span class="text-small text-white">Slot info</span>
                </div>
                <div class="table-row text-small text-white">
                    <span>Potential: </span>
                    <span>{{ slotData.potential }} x</span>
                </div>
                <div class="table-row text-small text-white">
                    <span>RTP: </span>
                    <span>{{ slotData.rtp }}%</span>
                </div>
            </div>
            <div class="divider"/>
            <div class="slot-data-table-column">
                <div class="slot-data-table-column-header">
                    <i class="pi pi-user"></i>
                    <span class="text-small text-white">Personal</span>
                </div>
                <div class="table-row text-small text-white">
                    <span class="title">Max win: </span>
                    <span class="bet" v-show="slotData.topWin.bet">
                        ({{ slotData.topWin.bet }} {{ slotData.topWin.currency }}) </span>
                    <span class="win">
                        {{ slotData.topWin.win }} {{ slotData.topWin.currency }}</span>
                </div>
                <div class="table-row text-small text-white">
                    <span class="title">Max X: </span>
                    <span class="bet" v-show="slotData.topX.bet">
                        ({{ slotData.topX.bet }} {{ slotData.topX.currency }}) </span>
                    <span class="win" v-show="slotData.topX.bet">x {{ slotData.topX.x }}</span>
                </div>
                <div class="table-row text-small text-white" v-show="slotData.avgX.x">
                    <span class="title">Avg X: </span>
                    <span class="bet">({{ slotData.avgX.bets }}) </span>
                    <span class="win">x {{ slotData.avgX.x }}</span>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'SlotWidget',

        props: {
            slotData: {
                type: Object,
            },
        },

        computed: {
            ready() {
                return !!this.slotData.title
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../../../../assets/css/variables';


    .slot-stats-widget {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border-radius: $border-radius-outer;
        background-color: $outer-wrapper-bg-color;
        padding: $container-padding;
        cursor: default
    }

    .slot-stats-widget-header {
        display: flex;
        flex-direction: row;
        padding: 0 calc($section-padding / 2);
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 20%;
        color: white;
    }


    .slot-stats-data-table {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        width: 100%;
        height: 75%;
        gap: 2%;
        padding: $section-padding;
        background: $inner-wrapper-bg-color;
        border-radius: $border-radius-inner;
        align-items: center;
    }

    .divider {
        width: 1px;
        height: 85%;
        background: #eed6d3;
    }

    .slot-data-table-column {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 2%;
        height: 100%;
        align-items: flex-start;
        justify-content: space-between;
    }


    .slot-data-table-column-header {
        display: flex;
        flex-direction: row;
        gap: 1em;
        align-items: center;
        margin-bottom: 2%;
        width: 100%;
        justify-content: center;
        color: white;

        i {
            font-size: 0.85em;
            color: white;
        }

    }

    .table-row {
        display: flex;
        align-items: flex-end;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .title {
            width: 30%;
            text-align: start;
        }

        .bet {
            width: 35%;
            text-align: center;
        }

        .win {
            width: 35%;
            text-align: end;
        }
    }
</style>
