<template>
    <div>
        <DataTable :value="slots" responsiveLayout="scroll" class="p-datatable-sm">
            <Column
                field="order"
                header="№"
                :sortable="true"
                :styles="{ 'max-width': '5%', 'justify-content': 'center' }">
                <template #body="slotProps">
                    {{ slotProps.data.order + 1 }}
                </template>
            </Column>

            <Column field="title" header="Title" :sortable="true">
                <template #body="slotProps">
                    <table-cell-title>
                        {{ slotProps.data.title }}
                    </table-cell-title>
                </template>
            </Column>

            <Column :exportable="false" header="SR" :styles="{ 'max-width': '5%', 'justify-content': 'center' }">
                <template #body="slotProps">
                    <single-roll-button :slot-data="slotProps.data" :currency="currency" />
                </template>
            </Column>

            <Column field="bet" header="Bet" :sortable="true">
                <template #body="slotProps">
          <span>
            {{ fixMoney(slotProps.data.bet) }}
          </span>
                    <span v-if="slotProps.data.bet">
            {{ currency }}
          </span>
                </template>
            </Column>

            <Column field="price" header="Price" :sortable="true" :hidden="isBonusHunt">
                <template #body="slotProps">
          <span>
            {{ fixMoney(slotProps.data.price) }}
          </span>
                    <span v-if="slotProps.data.price">
            {{ currency }}
          </span>
                </template>
            </Column>

            <Column field="win" header="Win" :sortable="true">
                <template #body="slotProps">
          <span>
            {{ fixMoney(slotProps.data.win) }}
          </span>
                    <span v-if="slotProps.data.win">
            {{ currency }}
          </span>
                </template>
            </Column>

            <Column field="x" header="X" :sortable="true" v-if="isBonusHunt">
                <template #body="slotProps">
                    {{ fixDecimal(slotProps.data.x) }}
                </template>
            </Column>

            <Column field="x_price" header="X" :sortable="true" v-if="!isBonusHunt">
                <template #body="slotProps">
                    {{ fixDecimal(slotProps.data.x_price) }}
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script setup>
    import { computed } from 'vue';
    import { useStore } from 'vuex';
    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';
    import TableCellTitle from '../../misc/Title.vue';
    import SingleRollButton from '../../misc/SingleRollButton.vue';
    import { useFilters } from '@composables/useFilters';

    const store = useStore();
    const { fixMoney, fixDecimal } = useFilters();

    const slots = computed(() => store.getters['archive/session'].slots);
    const currency = computed(() => store.getters['archive/readableCurrency']);
    const isBonusHunt = computed(() => store.getters['archive/isBonusHunt']);
</script>
