<template>
    <div class="page-not-found flex flex-column justify-content-center align-items-center" >
        <div class="headline">
            <h3>
                404. There is no such page :(
            </h3>
        </div>
      <br/>

        <Button
            class="p-button-sm"
            iconPos="right"
            icon="pi pi-undo"
            label="Dashboard"
            @click="$router.push({path: `/`})"/>
    </div>

</template>

<script>
  import Button from 'primevue/button'
  import router from '../../router'

  export default {
    name: "NotFound",
    methods: {
        router() {
            return router
        }
    },
    components: {Button}
}
</script>
