<template>
    <div class="login" :style="dynamicWidth">
        <Card>
            <template #title>
                Login to system
            </template>
            <template #content>
                <form class="auth-form-body">
                    <label for="input-email-login">Email</label>
                    <InputText
                        id="input-email-login"
                        class="p-inputtext-sm"
                        v-model="form.email"
                        placeholder="Enter email"/>

                    <label for="input-password-login">Password</label>
                    <Password
                        id="input-password-login"
                        class="p-inputtext-sm"
                        v-model="form.password"
                        :feedback="false"
                        toggle-mask/>

                    <Button class="p-button-sm"
                            @click="onSubmit"
                            variant="success"
                            label="Log In">
                    </Button>
                </form>
                <br>
            </template>

        </Card>
    </div>
</template>

<script>
    import Card from 'primevue/card';
    import InputText from 'primevue/inputtext';
    import Password from 'primevue/password';
    import Button from 'primevue/button';

    export default {
        name: "Auth",

        components: {
            Card,
            Password,
            InputText,
            Button,
        },
        data() {
            return {
                formStatus: null,
                form: {
                    email: '',
                    password: '',
                },
            };
        },
        computed: {
            dynamicWidth() {
                return this.$store.getters["settings/showWidgets"] ? {"width": "64%"} : {"width": "100%"}
            }
        },
        methods: {
            onSubmit() {
                const userData = {
                    username: this.form.email,
                    password: this.form.password,
                };
                this.$store
                    .dispatch('user/login', userData)
                    .then(() => {
                        this.fetchMe();
                    })
                    .catch((error) => {
                        console.log("(Auth.vue:93) ---> error:", error);
                    });
            },
            fetchMe() {
                this.$store
                    .dispatch('user/fetchMe')
                    .then(() => {
                        this.$router.push({name: 'dashboard'})
                    })
                    .catch((error) => {
                        console.log("(Auth.vue:93) ---> error:", error);
                    });
            },
        }
    }
</script>

<style lang="scss" scoped>
    .auth-form-body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px
    }
    .login {
        position: absolute;
        left: 0;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.3);
        padding: 8%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }

    .p-card {
        width: 45%;
    }

    .p-field {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0;
    }

    .p-invalid {
        color: red
    }

    .link-to-faq {
        color: #8b5cf6;
        cursor: pointer;
    }
</style>
