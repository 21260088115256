<template>
    <div class="archive-session">
        <archive-battle-header>

            <template #session_title>
                {{ session.session_info.title }} | {{ session.session_info.updated_at | truncate(10) }}
            </template>

            <template #competitor1_title>
                {{ session.slots[0].title }}
            </template>

            <template #competitor2_title>
                {{ session.slots[1].title }}
            </template>

            <template #content_below_competitors>
                <archive-battle-competitors-stats
                    :slots="competitors"
                    :currency="currency"/>
                <archive-battle-table
                    :results="results"
                    :currency="currency"/>
            </template>
        </archive-battle-header>

    </div>
</template>

<script>
import ArchiveBattleHeader from '../../components/archive-session/battle/ArchiveBattleHeader.vue'
import ArchiveBattleCompetitorsStats from '../../components/archive-session/battle/ArchiveBattleCompetitorsStats.vue'
import ArchiveBattleTable from '../../components/archive-session/battle/ArchiveBattleTable.vue'

export default {
    name: 'ArchiveBattleSession',
    components: {
        ArchiveBattleHeader,
        ArchiveBattleCompetitorsStats,
        ArchiveBattleTable,
    },
    computed: {
        session() {
            return this.$store.getters["archive/session"]
        },

        competitors() {
            return this.$store.getters[`archive/session`]?.slots
        },

        results() {
            return [
                this.competitors[0]?.results,
                this.competitors[1]?.results,
            ]
        },

        currency() {
            return this.$store.getters['archive/readableCurrency']
        },
    }
}
</script>

<style lang="scss" scoped>
.archive-session {
    padding: 8px;
    overflow-y: auto;
    height: 100vh
}
</style>
