<template>
    <Toolbar class="p-1">
        <template #start>
            <div class="w-full flex flex-row justify-content-between">
                <slots-manager-wrapper
                    :session-type="sessionType"
                    @slots-selected="onSlotSelected">

                    <template #activator="{ toggleDialog }">
                        <Button
                            :icon="!!competitor?.id ? 'pi pi-undo' : 'pi pi-plus'"
                            :label="!!competitor?.id ? `${competitor?.title}`: `Select`"
                            class=" p-button-sm"
                            :class="{'p-button-text': !!competitor?.id}"
                            @click="toggleDialog"/>
                    </template>

                    <template #title>
                        Select competitor #{{ competitorOrder }}
                    </template>

                </slots-manager-wrapper>

                <slot name="competitor-price"/>
            </div>

        </template>

        <template #end>
            <div class="w-full flex flex-row justify-content-between">
                <slot name="competitor-bet"/>
                <slot/>
            </div>
        </template>


    </Toolbar>
</template>

<script>
    import Button from 'primevue/button'
    import Toolbar from 'primevue/toolbar'
    import SlotsManagerWrapper from '../slots-manager/SlotsManagerWrapper.vue'

    export default {
        components: {SlotsManagerWrapper, Button, Toolbar},

        props: {
            modelValue: {
                type: Object,
                default: {},
            },
            sessionType: {
                type: String,
                required: true,
            },
            competitorOrder: {
                type: Number,
            },
        },
        computed: {
            competitor: {
                get() {
                    return this.modelValue
                },
                set(newValue) {
                    this.$emit('update:modelValue', newValue)
                },
            },
        },
        methods: {
            onSlotSelected(slot) {
                this.competitor = {
                    id: slot.id,
                    title: slot.attributes.title,
                }
            },
        },
    }
</script>


<style lang="scss" scoped>
    :deep .p-toolbar-group-left,
    :deep .p-toolbar-group-right {
        width: 45%;
        justify-content: space-between;
    }
</style>
