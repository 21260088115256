const keys = [
    'actual_win', 'target_win', 'total_win',
    'total_price', 'total_bet', 'bet',
    'win', 'price', 'avg_bet',
    'avg_price', 'avg_win', 'max_win', 'deposit', 't_price', 't_bet',
]

function centsToBucks(input) {
    if (Array.isArray(input)) {
        return input.map(item => centsToBucks(item, keys))
    }

    if (typeof input === 'object' && input !== null) {
        centsToBucksRecursive(input)
    }

    return input
}

function centsToBucksRecursive(obj) {
    for (let key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            centsToBucksRecursive(obj[key])
        } else if (keys.includes(key)) {
            if (obj[key] !== null && obj[key] !== undefined) {
                obj[key] /= 100
            }
        }
    }
}

function bucksToCents(input) {
    if (Array.isArray(input)) {
        return input.map(item => bucksToCents(item, keys))
    }

    if (typeof input === 'object' && input !== null) {
        bucksToCentsRecursive(input)
    }

    return input
}

function bucksToCentsRecursive(obj) {
    for (let key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            bucksToCentsRecursive(obj[key])
        } else if (keys.includes(key)) {
            if (obj[key] !== null && obj[key] !== undefined) {
                obj[key] *= 100
            }
        }
    }
}

export {bucksToCents, centsToBucks}
