import axios from 'axios';
// import store from '@store/store';
// import router from '@/router';

const axiosInstance = axios.create({
    baseURL: import.meta.env.APP_URL
});

// Headers
axiosInstance.defaults.headers.common.Accept = 'application/vnd.api+json';

// Interceptors
// Auto Bearer Authentication
axiosInstance.interceptors.request.use((config) => {
    let token
    if (config.url.includes('public')) {
        token = localStorage.getItem('publicToken')
    } else {
        token = localStorage.getItem('accessToken')
    }

    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }

    return config;
});

// Auto token refresh
// axios.interceptors.response.use((response) => response, async (error) => {
//     const originalRequest = error.config;
//     if (error.response.status === 401 && !originalRequest._retry) {
//         if (!localStorage.getItem('accessToken')) {
//             if (router.currentRoute.path !== 'login') {
//                 await router.push({ name: 'main' });
//             }
//             return Promise.reject(error);
//         }
//
//         if (!store.getters['user/isRefreshOngoing']) {
//             await store.dispatch('user/refreshToken')
//                 .then(() => {
//                     return axios(originalRequest);
//                 }).catch(()=> {
//                     router.push({ name: 'main' });
//                 });
//         }
        // else {
        //
        //   // If multiple requests other than first-401-failed request => wait for refresh event to fire
        //   await new Promise((resolve, reject) => {
        //     Vue.bus.once('tokenRefreshFinished', (eventPayload) => {
        //       if (eventPayload.success === true) {
        //         originalRequest._retry = true;
        //         resolve(axios(originalRequest));
        //       } else {
        //         router.push({ name: 'main' });
        //         reject('Token refresh went bad');
        //       }
        //     });
        //   });
        // }
    // }

//     return Promise.reject(error);
// });

// function forceAuth() {
//     window.EventBus.dispatchEvent('forceAuth')
// }

export default axiosInstance
