<template>
    <div class="forbidden-page">
        <div class="headline">
            <h3>
                Sorry, this session doesn't available for you
            </h3>
        </div>

        <Button
            class="p-button-sm"
            iconPos="right"
            icon="pi pi-undo"
            label="Dashboard"
            @click="$router.push({name: `dashboard`})"/>
    </div>

</template>

<script>
import Button from "primevue/button";
import router from "../../router";

export default {
    name: "Forbidden",
    methods: {
        router() {
            return router
        }
    },
    components: {Button}
}
</script>

<style lang="scss" scoped>

</style>
