<template>
    <div class="nav-menu">
        <navigation-item
            v-for="item in navigationItems"
            class="nav-item"
            :key="item.title"
            :item="item"/>
    </div>
</template>

<script>

    import NavigationItem from './NavigationItem.vue'

    export default {
        name: 'NavigationMenu',

        components: {
            NavigationItem,
        },
        computed: {
            gamesAvailable() {
                return this.$store.getters['user/me'].id === 799 || this.$store.getters['user/isTester']
            },

            navigationItems() {
                return [
                    {
                        title: 'Dashboard',
                        route: 'dashboard',
                        icon: 'pi pi-th-large',
                        enabled: true,
                    },
                    {
                        title: 'Session',
                        route: 'session',
                        icon: 'pi pi-video',
                        enabled: true,
                    },
                    {
                        title: 'Single Roll',
                        route: 'single-roll',
                        icon: 'pi pi-bolt',
                        enabled: true,
                    },
                    {
                        title: 'Settings',
                        route: 'settings',
                        icon: 'pi pi-cog',
                        enabled: true,
                    },
                    {
                        title: 'Games',
                        route: 'games',
                        icon: 'pi pi-bullseye',
                        enabled: this.gamesAvailable,
                        // enabled: true,
                    },
                    {
                        title: 'FAQ',
                        route: 'faq',
                        icon: 'pi pi-question',
                        enabled: true,
                    },
                    {
                        title: 'Sign out',
                        route: 'logout',
                        icon: 'pi pi-sign-out',
                        enabled: true,
                    },
                ]
            },
        },
    }
</script>

<style lang="scss" scoped>

    .nav-menu {
        width: 80px;
        height: 100%;
        display: grid;
        grid-template-rows: repeat(auto-fill, 80px);
        border-right: 1px solid #8f61f6;
    }

    .nav-item:nth-last-child(1) {
        position: absolute;
        bottom: 0;
    }

    .nav-item:nth-last-child(2) {
        position: absolute;
        bottom: 80px;
    }
</style>
