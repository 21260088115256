<template>
    <div class="container">
        <div class="redactor">
            <div class="board-container">
                <div class="cords letters">
                    <div class="letter" v-for="l in  numbersCords" :key="l">{{ l }}</div>
                </div>
                <div class="cords numbers">
                    <div class="number" v-for="n in lettersCords" :key="n">{{ n }}</div>
                </div>
                <div class="flex">
                <div class="board">
                    <div v-for="(row, rowIndex) in board" :key="rowIndex" class="row">
                        <div v-for="(cell, cellIndex) in row"
                             :key="cellIndex" class="spot"
                             :class="getCellClass(cell, rowIndex, cellIndex)"
                             @click="cellClick(cell, rowIndex, cellIndex)">
                            {{ cell }}
                        </div>
                    </div>
                </div>
                <br/>
<!--                Shoots:-->
<!--                <div class="shoots-history">-->

<!--                </div>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'GameController',

        data() {
            return {
                boardAvailable: true,
                gameBoard: [],
                shootsStats: {
                    hit: 0,
                    miss: 0
                },
                shoots: [],
                lastShoot: {
                    x: null,
                    y: null
                }
            }
        },

        computed: {
            board() {
              return this.$store.getters["games/activeGame"]?.meta?.board || this.$store.getters["games/activeGame"]?.meta?.startPositions?.board || [];
            },

            ships() {
              return this.$store.getters["games/activeGame"].meta.startPositions.ships || [];
            },

            lettersCords() {
                // return ['А', 'Б', 'В', 'Г', 'Д', 'Е', 'Ж', 'З', 'И', 'К'];
                return ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];
            },

            numbersCords() {
                return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
            },

            shipsCoords() {
                return this.ships.map(ship => ship.map(coord => Object.values(coord)))
            }
        },

        methods: {

            getCellClass(cell,  rowIndex, cellIndex) {
                const isLastShoot = this.lastShoot.x === cellIndex && this.lastShoot.y === rowIndex
                if (cell === 'x') {
                    return `hit ${isLastShoot ? 'last-shoot' : ''}`
                } else if (cell === 'X') {
                    return `killed ${isLastShoot ? 'last-shoot' : ''}`
                } else if (cell === '*') {
                    return `miss ${isLastShoot ? 'last-shoot' : ''}`
                } else if (cell === 's') {
                    return `ship ${isLastShoot ? 'last-shoot' : ''}`;
                } else {
                    return '';
                }
            },

            cellClick(cell, rowIndex, cellIndex) {

                if (!this.boardAvailable) {
                    return
                }
                this.boardAvailable = false

                if (cell === 's') {
                    this.board[rowIndex][cellIndex] = 'x'
                    this.shootsStats.hit++
                    this.markSurroundingCells(rowIndex, cellIndex)
                }

                if (['B', '~'].includes(cell)) {
                    this.board[rowIndex][cellIndex] = '*'
                    this.shootsStats.miss++
                }

                if (['x', '*', 'X'].includes(cell)) {
                    this.boardAvailable = true
                    return
                }

                this.lastShoot.x = cellIndex
                this.lastShoot.y = rowIndex

                const cellUpdated = this.board[rowIndex][cellIndex]
                const shootNumber = this.shootsStats.hit + this.shootsStats.miss

                const shootData = {
                    stats: {...this.shootsStats, total: shootNumber},
                    shoots: {
                        [shootNumber]: {
                            cell: cellUpdated,
                            rowIndex,
                            cellIndex
                        }
                    },
                    board: this.board,
                    lastShoot: {
                       x: cellIndex,
                       y: rowIndex
                    }
                }
                this.sendShoot(shootData)

                const timeout = setTimeout(() => {
                    this.boardAvailable = true
                }, 500)
            },

            markSurroundingCells(rowIndex, cellIndex) {
                const dx = [-1, -1, -1, 0, 0, 1, 1, 1];
                const dy = [-1, 0, 1, -1, 1, -1, 0, 1];
                const rows = this.board.length;
                const cols = this.board[0].length;

                let isShipSunk = false;
                let shipIndex = -1;

                for (let i = 0; i < this.shipsCoords.length; i++) {
                    const ship = this.shipsCoords[i];
                    for (const [x, y] of ship) {
                        if (y === rowIndex && x === cellIndex) {
                            shipIndex = i;
                            isShipSunk = ship.every(coord => this.board[coord[1]][coord[0]] === 'x');
                            break;
                        }
                    }
                    if (isShipSunk) break;
                }

                if (isShipSunk) {
                    // this.alertHit('ubit')
                    const ship = this.shipsCoords[shipIndex];
                    for (const [x, y] of ship) {
                        for (let i = 0; i < 8; i++) {
                            const ny = x + dx[i];
                            const nx = y + dy[i];
                            if (nx >= 0
                                && nx < rows
                                && ny >= 0
                                && ny < cols
                                && this.board[nx][ny] !== 's'
                                && this.board[nx][ny] !== 'x'
                                && this.board[nx][ny] !== 'X') {

                                this.board[nx][ny] = '*'
                            }

                            this.board[y][x] = 'X'
                        }
                    }
                    return
                }
            },

            sendShoot(shootData) {
                this.$emit('shoot', shootData)
            }
        },
        watch: {
            board: {
                handler() {
                    if (this.board?.length > 0) {
                        this.gameBoard = this.board
                    }
                },
                deep: true,
            },
        },
    }
</script>

<style scoped>
    .container {
        min-width: 100%;
    }

    .container h2 {
        font-size: 3.2rem;
        text-shadow: 0 2px 2px black;
        text-align: right;
        text-decoration: underline;
    }

    .options {
        display: flex;
        justify-content: space-around;
        margin-top: 2rem;
    }

    .options button {
        font-size: 2rem;
        text-shadow: 0 2px 2px black;
        text-align: center;
        outline: none;
    }

    .options button:hover,
    .options button:focus {
        color: rgb(35, 137, 218);
    }

    .redactor {
        --spot-size: 2rem;
        display: flex;
        justify-content: space-evenly;
    }


    .cords {
        display: grid;
        text-shadow: 0 2px 2px black;
        font-size: 2.5rem;
        justify-items: end;
        gap: 4px;
    }

    .letters {
        justify-content: space-evenly;
        grid-template-columns: repeat(10, var(--spot-size));
        grid-column: 2 / 3;
    }

    .numbers {
        grid-template-rows: repeat(10, var(--spot-size));
        align-content: space-evenly;
    }

    .letter,
    .number {
        width: var(--spot-size);
        height: var(--spot-size);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .board-container {
        display: grid;
        grid-template: var(--spot-size) 1fr / var(--spot-size) 1fr;
    }

    .board {
        display: grid;
        border: 4px solid rgb(0, 44, 102);
        background-color: rgb(0, 44, 102);
    }

    .row {
        display: flex;
    }

    .spot {
        width: 4rem;
        height: 4rem;
        background-color: white;
        position: relative;
        font-size: 2rem;
        text-decoration: double;
        color: white;
        border: 1px solid black;
        display: flex;
        justify-content: center;
        align-items: center;

        &.last-shoot {
            border: 4px solid gold !important;
        }
        &.ship {
            background-color: grey;
            color: grey;
        }

        &.hit {
            background-color: pink;
            color: pink;

        }

        &.killed {
            background-color: red;
            color: white;
        }

        &.miss {
            background-color: rgb(35, 137, 218);
            color: white;
        }


        &:hover {
            box-shadow: inset 1px 1px 3px 3px greenyellow;
            cursor: crosshair;
        }


    }


    @keyframes pulse {
        100% {
            transform: scale(1.2);
            color: rgb(200, 48, 48);
            box-shadow: inset 0 0 10px 2px rgb(255, 255, 0),
            0 0 10px 2px rgb(255, 255, 0);
        }
    }
</style>
