<template>
    <div>
        <div class="side-panel-active-component">
            <RouterView/>
        </div>
        <ConfirmDialog/>
    </div>
</template>

<script>
    import ConfirmDialog from "primevue/confirmdialog"

    export default {
        name: "MainInterfaceContainer",
        components: {
            ConfirmDialog,
        },
    }

</script>

<style lang="scss" scoped>

    .side-panel-active-component {
        width: 100%;
    }
</style>
