<template>
    <div
        class="nav-menu-item"
        :class="{'active':isActive, 'disabled':!item.enabled}"
        @click="handleClick">
        <div class="link">
            <i :class="item.icon"/>
            <span>
            {{ item.title }}
          </span>
        </div>
    </div>
</template>

<script>
    import router from "@/router.js";

    export default {
        name: 'NavigationItem',

        props: {
            item: {
                type: Object,
                required: true,
            },
        },

        computed: {
            isActive() {
                if (this.item.route) {
                    return this.$route.matched.some(record => record.name && record.name.includes(this.item.route))
                }
            },
        },

        methods: {
            handleClick() {
                if (this.item.enabled) {
                    router.push({name: this.item.route})
                        .catch(err => {
                            console.error("navigation error:", err);
                        })
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    .nav-menu-item {
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        align-items: center;
        padding: 16px;
        cursor: pointer;

        &.active {
            background-color: #8f61f6;

            .link {
                color: white;
            }
        }

        &.disabled {
            .link {
                color: gray;
            }
        }

        .link {
            display: flex;
            flex-direction: column;
            gap: 4px;
            align-items: center;
            color: #8f61f6;
            text-decoration: none;
            font-size: 12px;

            i {
                font-size: 24px;
            }

            span {
                text-align: center;
            }
        }
    }
</style>
