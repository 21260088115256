import {userRoutes} from '@api/api-routes'
import axios from "@api/axios";

export default {
  namespaced: true,
  state: {
    accessToken: localStorage.getItem('accessToken') || null,
    status: null,
    me: JSON.parse(localStorage.getItem('me')) || JSON.stringify({}),
    tokenRefreshFailed: false,
    tokenRefreshOngoing: false,
    isRuzke: false,
    testersIds: [1,6,4,7,8]
  },
  getters: {
      isAuthenticated: (state) => !!state.accessToken,
      authStatus: (state) => state.status,
      isTokenRefreshFailed: (state) => state.tokenRefreshFailed,
      me: (state) => state.me,
      isRefreshOngoing: (state) => state.tokenRefreshOngoing,
      isRuzke: (state) => state.isRuzke,
      isTwitchEnabled: (state) => state.me && state.me.is_twitch_enabled,
      isTester: (state) => {
          return state.testersIds.includes(state.me.id)
      },
  },
  actions: {
    setUsersLocale({commit}) {
      commit('SET_USERS_LOCALE')
    },
    async login({ commit, dispatch }, userData) {
      return new Promise((resolve, reject) => { // The Promise used for router redirect in login
        commit('AUTH_REQUEST');

        const backendData = {
          grant_type: 'password',
          // client_secret: process.env.VUE_APP_API_OAUTH_SECRET,
          // client_id: process.env.VUE_APP_API_OAUTH_CLIENT,
        };

        const payload = { ...userData, ...backendData };

        axios.post(userRoutes.login, payload)
        .then((response) => {
          commit('AUTH_SUCCESS', response.data);
          commit('SET_TOKEN_REFRESH_FAILED', false);
          resolve(response);
        })
        .catch((error) => {
          commit('AUTH_ERROR', error);
          commit('AUTH_LOGOUT');
          reject(error);
        });
      });
    },
    logout({ commit }) {
      console.log('logout')
      commit('AUTH_LOGOUT');
    },
    async fetchMe({ commit }) {
      return new Promise((resolve, reject) => {
        axios.post(userRoutes.fetch_me)
          .then((response) => {
            commit('I_FETCHED', response.data);
            if (response.data.widget_settings) {commit('settings/setSetting', response.data.widget_settings, { root: true })}
            resolve(response);
          }).catch((error) => {
            reject(error);
          });
      });
    },
    async refreshToken({ state, commit }) {
      return new Promise((resolve, reject) => {
        if (state.tokenRefreshOngoing) {
          reject('already ongoing');
        }
        commit('REFRESH_PROGRESS');

        axios.post(api.default.refresh)
            .then((response) => {
              commit('AUTH_SUCCESS', response.data);
              // Vue.bus.emit('tokenRefreshFinished', { success: true })
              resolve(response);
            })
            .catch((error) => {
              commit('AUTH_ERROR', error);
              commit('AUTH_LOGOUT');
              // Vue.bus.emit('tokenRefreshFinished', { success: false })
              reject(error);
            }).finally(() =>{
          commit('REFRESH_STOP_PROGRESS');
        });
      });
    },
  },
  mutations: {
    AUTH_REQUEST: (state) => {
      state.status = 'loading';
    },
    AUTH_SUCCESS: (state, data) => {
        console.log("(user.js:101) ---> data:", data);
      localStorage.setItem('accessToken', data.access_token);
      state.accessToken = data.access_token;
      state.status = 'authenticated';
    },
    AUTH_LOGOUT: (state) => {
      localStorage.removeItem('accessToken');
      state.accessToken = '';
      state.status = 'unauthenticated';
      state.me = JSON.stringify({});
      localStorage.setItem('me', state.me);
    },
    AUTH_ERROR: (state) => {
      state.status = 'error';
    },
    I_FETCHED: (state, data) => {
      localStorage.setItem('me', JSON.stringify(data));
      state.me = data;
    },
    SET_TOKEN_REFRESH_FAILED: (state, value) => {
      state.tokenRefreshFailed = value;
    },
    SET_MY_STATS: (state, value) => {
      state.myStats = value;
    },
    REFRESH_PROGRESS: (state) => {
      state.tokenRefreshOngoing = true;
    },
    REFRESH_STOP_PROGRESS: (state) => {
      state.tokenRefreshOngoing = false;
    },
    SET_USERS_LOCALE: (state) => {
      state.isRuzke = true;
    }
  }
};
