export function roundPlayed(group) {
    const hasWins = group.every(competitor => competitor.wins.length !== 0)
    const equalsWins = group.every(competitor => competitor.wins.length === group[0].wins.length)
    return hasWins && equalsWins
}

export function fullFillRoundWithPlaceholdersCompetitors(rounds) {

    const mockCompetitor = (roundIndex) => {
        return {
            id: null,
            title: '',
            icon: '?',
            t_round: roundIndex + 1,
        }
    }

    return Array.from({length: 7}, (_, index) => {
        if (rounds[index]) {
            return rounds[index].length === 2
                ? rounds[index]
                : [...rounds[index], mockCompetitor(index)]
        } else {
            return Array.from({length: 2}, () => mockCompetitor(index))
        }
    })
}

export function tournamentRoundsByGroups(rounds) {
    const groupLengths = [4, 2, 1]
    const newArray = []

    let currentIndex = 0

    for (const length of groupLengths) {
        newArray.push(Array.from(rounds.slice(currentIndex, currentIndex + length)))
        currentIndex += length
    }
    return newArray
}

export function profitabilityColorCoding(competitor) {
    if (!competitor.slot_score) {
        return ''
    } else if (competitor.slot_score === 0 && competitor.results.filter(result => result.win !== null).length === 0) {
        return ''
    } else if (parseFloat(competitor.slot_score) >= competitor.results.filter(result => result.win !== null).length) {
        return 'green'
    } else if (parseFloat(competitor.slot_score) < competitor.results.filter(result => result.win !== null).length) {
        return 'red'
    }
}

export function tournamentStageTitle(round) {
    if (round<=4) {
        return '1/4'
    }
    else if (round === 5 || round === 6) {
        return '1/2'
    }
    else if (round=== 7) {
        return 'final'
    }
}
