<template>
  <Toolbar>
    <template #start>
      <p>Game type: {{ readableSessionType }}</p>
      <i class="p-toolbar-separator"/>
      <currency-selector v-model="selectedCurrency"/>
      <InputNumber
          id="winTarget"
          v-model="winTarget"
          class="p-inputtext-sm ml-4"
          placeholder="Enter updated deposit"/>
    </template>
    <template #end>
      <Button
          :icon="formReady ? 'pi pi-check' : 'pi pi-undo'"
          class="p-button-sm"
          :class="formReady ? 'p-button-success': 'p-button-danger'"
          @click="formReady ? editComplete() : $emit('edit-complete')"/>
    </template>
  </Toolbar>
</template>

<script>
    import Toolbar from 'primevue/toolbar'
    import Button from 'primevue/button'
    import InputNumber from 'primevue/inputnumber'

    import Constants from '@resources/js/constants/general.js'
    import CurrencySelector from '../misc/CurrencySelect.vue'
    import {readableSessionType} from '@utils/formatters'

    export default {
    name: 'EditableToolbar',
    components: {
      CurrencySelector,
      Toolbar,
      Button,
      InputNumber,
    },
    props: {
      sessionData: {
        required: true,
      },
    },
    data() {
      return {
        selectedCurrency: null,
        winTarget: null,
      }
    },
    computed: {
      readableSessionType() {
        return readableSessionType(this.sessionData.session_type)
      },

      formReady() {
        return !!this.winTarget && !!this.selectedCurrency
      },

      isBattle() {
        return this.sessionData.session_type === Constants.TYPE_BATTLE
      },
    },

    mounted() {
      if (this.sessionData.id) {
        this.reFillInputs()
      }
    },

    methods: {

      async editComplete() {
        try {
          const payload = {
            currency: this.selectedCurrency,
            target_win: this.winTarget,
          }
          console.log('edit')
          await this.$store.dispatch('sessions/editSession', {id: this.sessionData.id, payload})
          await this.$store.dispatch(this.isBattle ? 'sessionBattle/fetchBattle' : 'sessionHB/fetchSession', this.sessionData.id)
          this.$toast.add({severity: 'success', summary: 'Success', detail: 'Session edited', life: 1000})
          this.$emit('edit-complete')
        } catch (error) {
          this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: 1000})
        }
      },
      reFillInputs() {
        this.selectedCurrency = this.sessionData.currency
        this.winTarget = this.sessionData.target_win
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
