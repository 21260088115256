<template>
<!--    <component-->
<!--        :is="pvpViewComponent"-->
<!--        :slots="slots"-->
<!--        @update:slot="markSlotAsActive($event)"-->
<!--        @update:result="$emit('update:result', $event)"-->
<!--    />-->
    <PVPAccordion v-if="pvpViewComponent" :slots="props.slots" @update:slot="markSlotAsActive($event)"
                  @update:result="$emit('update:result', $event)"
    />
    <PVPTwoColumns v-else :slots="props.slots" @update:slot="markSlotAsActive($event)"
                  @update:result="$emit('update:result', $event)"
    />
</template>

<script setup>
    import {computed, ref} from 'vue'
    import PVPAccordion from './PVPAccordion.vue'
    import PVPTwoColumns from "./PVPTwoColumns.vue";
    import {useStore} from "vuex";


    const props = defineProps({
        slots: {
            type: Array
        }
    })
    const emit = defineEmits(['update:slot', 'update:result'])
    const store = useStore()

    const pvpViewComponent = computed(() =>
        store.getters['settings/pvpAccordionView']
    )

    const markSlotAsActive = (e) => {
        const id = props.slots[e.index].slot_id
        store.dispatch('widgetSlot/handleSlotIdChange', id)
    }
</script>
