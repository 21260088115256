<template>
    <GameWidgetWrapper/>
</template>

<script>
    import GameWidgetWrapper from "@widgets/games/GameWidgetWrapper.vue";

    export default {
        name: 'OBSGameWidget',
        components: {GameWidgetWrapper},
        props: {
            publicToken: {
                type: String
            }
        },
        methods: {

            setToken() {
                localStorage.setItem('publicToken', this.publicToken ?? '')
            },

            async cyclicUpdateGame() {
                setTimeout(() => {
                    this.$store.dispatch('games/getWidgetData')
                        .then(this.cyclicUpdateGame())
                }, 1000)
            },

        },
        async mounted() {
            await this.setToken()
            await this.cyclicUpdateGame()
        },
    }
</script>

<style lang="scss" scoped>

</style>
