<template>
  <DataTable
      v-if="ready"
      :value="slotResults"
      responsiveLayout="scroll"
      class="editable-cells-table p-datatable-sm"
      showGridlines
      stripedRows
      editMode="cell"
      @cell-edit-complete="onCellEditComplete">
    <Column
        header="#"
        :styles="{ 'width': '5%', 'justify-content': 'start' }">

      <template #body="data">
        {{ data.index + 1 }}
      </template>

    </Column>
    <Column
        field="win"
        header="Win"
        :styles="{ 'width': '20', 'justify-content': 'start' }">

      <template #editor="{data, field, index}">
        <InputNumber
            mode="decimal"
            :maxFractionDigits="2"
            :disabled="inputDisabled(index)"
            v-model="data[field]"
            class="p-inputtext-sm"/>

      </template>
    </Column>

  </DataTable>
</template>

<script>
  import DataTable from 'primevue/datatable'
  import Column from 'primevue/column'
  import InputNumber from 'primevue/inputnumber'

  export default {
    name: 'PVPTable',

    components: {
      DataTable,
      Column,
      InputNumber,
    },

    props: {
      slotResults: [],
    },

    computed: {
      ready() {
        return !!this.slotResults?.length
      },
    },

    methods: {
      inputDisabled(index) {
        const spins = this.slotResults.length
        if (index === 0) {
          return false
        }
        if (index === spins - 1 && this.slotResults[index - 1]?.win !== null) {
          return false
        }
        return this.slotResults[index - 1].win === null
      },

      onCellEditComplete(event) {
          console.log("(PVPTable.vue:76) ---> event:", event);
        this.$emit('update', event)
      },
    },
  }
</script>

<style lang="scss" scoped>

  :deep .p-inputtext-sm {
    width: 100%;
    height: 28px;
  }
</style>
