import {fixMoney, fixPercents, isBonusHunt, isHuntOrBuy, readableCurrency, toUpperCase} from '@utils/formatters'
import {cloneDeep} from 'lodash'

export function depositFormatter(sessionInfo) {
    const isBH = isBonusHunt(sessionInfo.session_type)
    const formattedDeposit = sessionInfo.deposit ? fixMoney(sessionInfo.deposit) : '-'
    const formattedTotalPrice = sessionInfo.total_price ? fixMoney(sessionInfo.total_price) : '-'
    return isHuntOrBuy(sessionInfo.session_type) ? (isBH ? formattedDeposit : formattedTotalPrice) : sessionInfo.target_win
}

export function sessionInfoFormatter(session) {
    const sessionInfo = cloneDeep(session.session_info)

    const isBH = isBonusHunt(sessionInfo.session_type)
    const currency = readableCurrency(sessionInfo.currency)

    const title = sessionInfo.title

    const bonusQty = sessionInfo.total_slots ? `${sessionInfo.played_slots} / ${sessionInfo.total_slots}` : '-'

    const formattedTotalWin = sessionInfo.total_win ? fixMoney(sessionInfo.total_win) : '-'

    const winDepRatio = `${formattedTotalWin} / ${depositFormatter(sessionInfo)}`

    const avgX = sessionInfo.avg_x ? fixPercents(sessionInfo.avg_x) : '-'
    const avgXPrice = sessionInfo.total_win_to_total_price ? fixPercents(sessionInfo.total_win_to_total_price) : '-'
    const formattedAvgX = isBH ? avgX : avgXPrice

    return {
        title,
        currency,
        bonusQty,
        winDepRatio,
        formattedAvgX,
    }
}

export function sessionLeadersFormatter(session) {
    const sessionTop = cloneDeep(session.top)
    const sessionWorst = cloneDeep(session.worst)

    // const currency = readableCurrency(session.session_info.currency) ?? ''
    const isBH = isBonusHunt(session.session_info.session_type)

    const leaderWin = {
        show: !!sessionTop.absolute.win,
        title: toUpperCase(sessionTop.absolute.title),
        bet: `${fixMoney(isBH ? sessionTop?.absolute?.bet : sessionTop?.absolute?.price)}`,
        x: `x ${fixPercents(isBH ? sessionTop?.absolute?.x : sessionTop?.absolute?.x_price)}`,
        win: `${fixMoney(sessionTop.absolute.win)}`,
    }

    const leaderX = {
        show: !!sessionTop.x.x,
        title: toUpperCase(sessionTop.x.title),
        bet: `${fixMoney(isBH ? sessionTop?.x?.bet : sessionTop?.x?.price)}`,
        x: `x ${fixPercents(isBH ? sessionTop?.x?.x : sessionTop?.x?.x_price)}`,
    }
    const loser = {
        show: !!sessionWorst.absolute.win && sessionWorst.absolute.title !== sessionTop.absolute.title,
        title: toUpperCase(sessionWorst.absolute.title),
        bet: `${fixMoney(isBH ? sessionWorst?.absolute?.bet : sessionWorst?.absolute?.price)}`,
        x: `x ${fixPercents(isBH ? sessionWorst?.absolute?.x : sessionWorst?.absolute?.x_price)}`,
        win: `${fixMoney(sessionWorst?.absolute?.win)}`,
    }

    return {
        leaderWin,
        leaderX,
        loser,
    }
}

export function sessionSlotsFormatter(session) {
    // const currency = readableCurrency(session.session_info.currency) ?? ''
    const isBH = isBonusHunt(session.session_info.session_type)

    const slots = cloneDeep(session.slots)
    slots.forEach(slot => {
        slot.order = `${slot.order + 1}.`
        slot.title = slot.title ? toUpperCase(slot.title) : ''
        slot.bet = `${betFormatter(slot, isBH)}`
        slot.win = slot.win ? `${fixMoney(slot.win)}` : ''
        delete slot.price
    })

    return slots
}

function betFormatter(slot, isBH, currency=null) {
    const value = isBH ? slot.bet : slot.price
    return value ? `(${fixMoney(value)}${currency ?? ''})` : ''
}

export const randomSlotId = {
    min: 1,
    max: 1000,
    generate: function () {
        return Math.floor(Math.random() * (this.max - this.min + 1)) + this.min
    },
}

export const generalSessionFiller = {
    'info': {
        'title': 'No Active Session',
        'status': 'init',
        'serial_id': 2,
        'session_type': 'bonus_hunt',
        'currency': 'eur',
        'deposit': 0,
        'avg_x': 0,
        'avg_x_price': null,
        'total_slots': 0,
        'played_slots': 0,
        'current_slot': 0,
        'total_bet': 0,
        'total_price': 0,
        'total_win': 0,
        'total_win_to_total_bet': null,
        'total_win_to_total_price': null,
    },
    'slots': [{'title': 'Good luck :)', 'order': 0, 'is_active': 1, 'bet': null, 'price': null, 'win': null}],
    'top': {
        'absolute': {'title': null, 'bet': null, 'win': null, 'price': null, 'x': null, 'x_price': null},
        'x': {'title': null, 'price': null, 'bet': null, 'win': null, 'x': null, 'x_price': null},
    },
    'worst': {
        'absolute': {'title': null, 'price': null, 'bet': null, 'win': null, 'x': null, 'x_price': null},
        'x': {'title': null, 'price': null, 'bet': null, 'win': null, 'x': null, 'x_price': null},
    },
}
