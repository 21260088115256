<template>
    <div>
        <Button
            class="p-button p-button-sm"
            label="Create New Slot"
            iconpoz="left"
            icon="pi pi-plus"
            @click="openModal"/>

        <Dialog
            position="left"
            v-model:visible="showModal"
            :style="{ width: '45%' }"
            footer="footer"
            header="header"
            modal
            class="dialog-create">

            <template #header>
                <h3>Create new slot</h3>
            </template>

            <div class="table">
                <div class="table-field">
                    <label for="slotName"><b>Slot name*</b></label>
                    <InputText
                        id="slotName"
                        type="text"
                        :class="{'p-invalid': !isTitleValid}"
                        v-model="newSlot.title"/>
                    <small id="slotName-help" v-show="!isTitleValid" class="text-red-400">
                        Title must contains only latin, numbers and/or symbols
                    </small>
                </div>

                <div class="table-field">
                    <label for="provider"><b>Provider*</b></label>
                    <Dropdown v-if="providers"
                              v-model="provider"
                              :options="providers"
                              :filter="true"
                              :showClear="true"
                              optionLabel="attributes.title_norm"
                              placeholder="Select Provider">

                        <template #value="slotProps">
                            <div v-if="provider">
                                <div>{{ slotProps.value.attributes.title }}</div>
                            </div>
                            <span v-else>
                                Select Provider
                            </span>
                        </template>
                        <template #option="slotProps">
                            <div>{{ slotProps.option.attributes.title }}</div>
                        </template>
                    </Dropdown>
                </div>

                <div class="table-field">
                    <label for="potential">Potential X</label>
                    <InputText id="potential" type="number" v-model="newSlot.potential_x"/>
                </div>

                <div class="table-field">
                    <label for="rtp">RTP</label>
                    <InputText id="rtp" type="number" v-model="newSlot.rtp"/>
                </div>

            </div>

            <template #footer>
                <Button
                    icon="pi pi-save"
                    class="p-button-success create-button"
                    :disabled="!isFormValid"
                    @click="createNewSlot"/>
            </template>

        </Dialog>

    </div>
</template>

<script>
    import InputText from 'primevue/inputtext'
    import Button from 'primevue/button'
    import Dialog from 'primevue/dialog'
    import Dropdown from 'primevue/dropdown'

    export default {
        name: 'SlotsManagerCreateNewSlot',

        components: {
            InputText,
            Button,
            Dialog,
            Dropdown,
        },


        data() {
            return {
                showModal: false,
                newSlot: {},
                provider: null,
            }
        },

        computed: {
            providers() {
                return this.$store.getters['slots/providers']
            },

            isTitleValid() {
                const input = this.newSlot.title
                const regexMask = /^[a-zA-Z0-9!@#$%^&*()_+\-{}[\]:;"'<>,.?~\\/ ]+$/
                return regexMask.test(input)
            },

            isFormValid() {
                return this.isTitleValid && !!this.provider
            },
        },

        mounted() {
            this.$store.dispatch('slots/fetchProviders')
        },

        methods: {
            openModal() {
                this.showModal = true
            },

            closeModal() {
                this.showModal = false
            },


            createNewSlot() {
                this.newSlot.provider = this.provider.attributes.title

                // if (!this.isStringValid(this.newSlot.title)) {
                //   this.$toast.add({severity: 'error', summary: 'Error', detail: 'Title must be in English', life: 2000})
                //   return
                // }

                this.$store
                    .dispatch('slots/createSlot', this.newSlot)
                    .then((response) => {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Success!',
                            detail: response.data.title + ' created!',
                            life: 1000,
                        })
                        this.$emit('slot:created', this.newSlot.title)
                        this.closeModal()
                    })
                    .catch((e) => {
                        if (e.response.status === 422) {
                            this.$toast.add({
                                severity: 'error',
                                summary: 'Error',
                                detail: `Slot ${this.newSlot.title} already exist`,
                                life: 2000,
                            })
                            this.$emit('slot:duplicate', this.newSlot.title)
                            // this.closeModal()
                        } else
                            this.$toast.add({severity: 'error', summary: 'Error', detail: e, life: 1000})
                    })
            },
        },
    }
</script>

<style lang="scss" scoped>

    .p-dialog .p-dialog-footer .create-button {
        width: 3em !important;
    }

    .table {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        width: 40%;

        .table-field {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;

            .p-inputtext {
                width: 66%;
            }
        }
    }

</style>
