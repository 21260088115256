<template>
    <div class="slide-container">
        <transition mode="out-in" tag="div">
            <div :key="key" class="slides">
                <slot :items="currentViewScope"/>
            </div>
        </transition>
    </div>
</template>

<script>

    export default {
        props: {
            allItems: {
                type: Array,
            },
            itemsPerView: {
                type: Number,
                default: 4,
            },
            interval: {
                type: Number,
                default: 4000,
            },
        },
        data() {
            return {
                currentViewScope: [],
                currentIndex: 0,
                key: 'initialShit',
                intervalId: null,
            }
        },

        mounted() {
            this.init()
        },

        beforeDestroy() {
            clearInterval(this.intervalId)
        },

        watch: {
            itemsPerView(newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.init()
                }
            },

            allItems(newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.init()
                }
            },
        },

        methods: {

            init() {
                clearInterval(this.intervalId)
                this.intervalId = setInterval(this.updateScope, this.interval)
                this.updateScope()
                this.key = `uniqueKey-${Date.now()}`
            },


            updateScope() {
                const remainingItems = this.allItems.length - this.currentIndex

                if (remainingItems < this.itemsPerView) {

                    const startIndex = this.allItems.length - this.itemsPerView
                    this.currentViewScope = this.allItems.slice(startIndex)
                    this.currentIndex = 0
                } else {
                    this.currentViewScope = this.allItems.slice(
                        this.currentIndex,
                        this.currentIndex + this.itemsPerView,
                    )
                    this.currentIndex = (this.currentIndex + this.itemsPerView) % this.allItems.length
                }
                this.key = `uniqueKey-${Date.now() + 1}`
            },
        },
    }
</script>

<style lang="scss" scoped>
    .slide-container {
        max-height: min-content;
    }

    .fade-out-in-enter-active,
    .fade-out-in-leave-active {
        transition: opacity 0s;
    }

    .fade-out-in-enter-active {
        transition-delay: .3s;
        transition: opacity 0s;
    }

    .fade-out-in-enter,
    .fade-out-in-leave-to {
        opacity: 0;
        transform: scaleY(0);
        transform-origin: top;
    }
</style>
