import axios from '@api/axios'
import {sessionTournamentRoutes} from '@api/api-routes'
import {v4 as uuidv4} from 'uuid'
import {bucksToCents, centsToBucks} from '@utils/moneyConverters'
import {
    fullFillRoundWithPlaceholdersCompetitors, tournamentRoundsByGroups,
} from '@components/session-crud-tables/tournament/utils'


const resetState = (state) => {
    Object.assign(state, {})
}

export default {
    namespaced: true,

    state: {
        session: {},
    },

    getters: {
        session: state => state.session.session_info,
        rounds: state => {
            if (state.session?.rounds) return Object.values(state.session?.rounds)
        },
        fullFilledRoundsList: (state, getters) => fullFillRoundWithPlaceholdersCompetitors(getters.rounds),
        roundsByGroups: (state, getters) => tournamentRoundsByGroups(getters.fullFilledRoundsList),
        roundByNum: (state) => (num) => state.session.rounds[num],
        isRoundFinished: (state, getters) => (num) => {
            return getters.roundByNum(num).every(slot => slot.results.every(attempt => attempt.win !== null))
        },
    },

    mutations: {
        session: (state, session) => state.session = centsToBucks(session),
        resetState,
    },

    actions: {
        async startTournament({commit}, payload) {
            payload.fe_sign = uuidv4()
            payload = await bucksToCents(payload)

            try {
                const {data} = await axios.post(sessionTournamentRoutes.create, payload)
                return data
            } catch (error) {
                console.log(error)
                throw error
            }
        },

        async fetchTournament({commit}, session_id) {
            try {
                const {data} = await axios.get(sessionTournamentRoutes.getSession(session_id))
                commit('session', data)
                return data
            } catch (e) {
                console.error(e)
                throw e
            }
        },

        async postResults({state, getters, commit}, payload) {
            const session_id = state.session?.session_info.id
            const round = payload.round
            const result = {
                round,
                results: {
                    slot1: getters.roundByNum(round)[0].results
                        .map(result => result.win ? result.win * 100 : null)
                        .filter(value => value !== null),
                    slot2: getters.roundByNum(round)[1].results
                        .map(result => result.win ? result.win * 100 : null)
                        .filter(value => value !== null),
                },
            }
            try {
                const {data} = await axios.patch(sessionTournamentRoutes.postResults(session_id), result)
                commit('session', data)
                return data
            } catch (e) {
                console.error(e)
                throw e
            }

        },

        async changeView({state, commit}, view) {
            const session_id = state.session?.session_info.id
            const {data} = await axios.patch(sessionTournamentRoutes.changeViewMode(session_id), view)
            commit('session', data)
        },
    },
}
