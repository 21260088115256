<template>
    <div class="widget-page" v-if="ready">
        <component
            :is="widgetComponent"
            :style="sessionWidgetStyle"/>
    </div>
</template>

<script>

    import {isBattle, isTournament} from '@utils/formatters'
    import BattleWidget from '@widgets/bonus-battle/BattleWidget.vue';
    import TournamentWidget from '@widgets/tournament/TournamentWidget.vue';
    import NoPermission from '@widgets/NoPermission.vue';
    import SessionWidgetWrapper from '@widgets/session-hunt-or-buy/SessionWidgetWrapper.vue';


    export default {
        name: 'SessionWidgetSeparatedPage',

        data() {
            return {
                ready: false,
                reFetchDelay:  2000,
            }
        },
        computed: {

            activeSession() {
                return this.$store.getters['sessions/activeSession']
            },

            isBattle() {
                return isBattle(this.activeSession.session_type)
            },

            isTournament() {
                return isTournament(this.activeSession.session_type)
            },

            hasPermissionBattle() {
                return this.$tariffs.hasModule('BonusBattle')
            },
            hasPermissionTournament() {
                return this.$tariffs.hasModule('BonusTournament')
            },

            widgetComponent() {
                if (this.isBattle) {
                    return this.hasPermissionBattle ? BattleWidget : NoPermission;
                }
                if (this.isTournament) {
                    return this.hasPermissionTournament ? TournamentWidget : NoPermission;
                }
                return SessionWidgetWrapper;
            },

            sessionWidgetStyle() {
                const sessionWidgetWidth = this.$store.getters['settings/sessionWidgetWidth']
                const fontFactor = sessionWidgetWidth / 400
                return {
                    width: sessionWidgetWidth + 'px',
                    height: sessionWidgetWidth + 'px',
                    'font-size': (fontFactor * 18) + 'px',
                }
            },
        },

        async mounted() {
            await this.setToken()
            await this.fetchSettings()
            await this.cyclicUpdateSession()
        },

        methods: {
            setToken() {
                localStorage.setItem('publicToken', this.$route.query?.public_token ?? '')
            },

            async fetchSettings() {
                await this.$store.dispatch('settings/fetchWidgetSettings')
            },


            async cyclicUpdateSession() {
                setTimeout(async () => {
                    try {
                        const {data} = await this.$store.dispatch('sessions/getPublicActiveSession')

                        if (this.activeSession.session_type !== data?.session_info?.session_type ||
                            this.activeSession.session_id !== data?.session_info?.id) {
                            this.$store.commit('sessions/activeSession', {
                                session_type: data?.session_info?.session_type,
                                session_id: data?.session_info?.id,
                            })
                        }

                        if (this.isBattle) {
                            this.$store.commit('widgetBattle/session', data)
                        }
                        if (this.isTournament) {
                            this.$store.commit('sessionTournament/session', data)
                        } else {
                            this.$store.commit('widgetHB/session', data)
                            this.$store.commit('widgetHB/ready', true)
                        }

                        this.ready = true
                        await this.cyclicUpdateSession()
                    } catch (error) {
                        console.log(error)
                        throw error
                    }
                }, this.reFetchDelay)
            },
        },
    }
</script>

<style lang="scss" scoped>
    .widget-page {
        padding: 2%;
        width: 100%;
        height: 100vh;
    }
</style>
