import { reactive } from 'vue';

class GlobalTariffsService {
	constructor(vuexStore) {
		// this.vueInstance = vueInstance
		this.store = vuexStore

		this.tariffs = {
			plan1: {
				BonusHunt: true,
				BonusBuy: true,
				BonusBattle: false,
				BonusTournament: false,
				SlotStats: false,
				noAdd: false,
			},
			plan2: {
				BonusHunt: true,
				BonusBuy: true,
				BonusBattle: true,
				BonusTournament: true,
				SlotStats: false,
				noAdd: true,
			},
			plan3: {
				BonusHunt: true,
				BonusBuy: true,
				BonusBattle: true,
				BonusTournament: true,
				SlotStats: true,
				noAdd: true,
			},
			plan4: {
				BonusHunt: true,
				BonusBuy: true,
				BonusBattle: true,
				BonusTournament: true,
				SlotStats: true,
				noAdd: true,
			},
		};
	}

	setVuexInstance(vuexStore) {
		this.store = vuexStore;
	}

	hasModule(key) {
		const plan = this.store.state.user.me.subscription_plan

		if (!plan) {
			return true // in case of error => return true
		}

		const myModules = this.tariffs[plan]
		const module = myModules[key]

		if (module === undefined) {
			console.log('module permissions not found')
			return true // in case of error => return true
		}

		return module
	}
}

const GlobalTariffsServiceInstance = new GlobalTariffsService();
export default GlobalTariffsServiceInstance;
