<script setup>
    import {computed, ref} from "vue";
    import Button from 'primevue/button';
    import InputText from 'primevue/inputtext';
    import FileUploader from "@components/misc/FileUploader.vue";
    import FileSelect from "@components/misc/file-upload/FileSelect.vue";

    // const props = defineProps(['sector'])
    const props = defineProps(['modelValue'])

    const emit = defineEmits(['request:tasks', 'update:modelValue'])

    const editableSector = ref(props.modelValue)

    const sector = computed({
        get: () => props.modelValue,
        set: (value) => {
            editableSector.value = value
            emit('update:modelValue', editableSector.value)
        }
    })

    const removeTask = (index) => {
        sector.value.tasks.splice(index, 1)
    }


</script>
<template>
    <div class="sector-editor">

        <div class="content">

            <div class="tasks">
                задания:
                <ol>
                    <li v-for="(task, index) in sector.tasks" :key="task.id" class="task-item">
                        {{ task.text }}
                        <Button size="small" class="p-button-sm" @click="removeTask(index)" icon="pi pi-trash"/>
                    </li>
                    <Button v-if="sector.tasks.length <4" size="small" class="p-button-sm" @click="emit('request:tasks')" >+</Button>
                </ol>
            </div>
            <div class="modifier">
                модификатор:
                <InputText v-model="sector.modifier" placeholder="от -6 до +6" class="p-inputtext-sm" pattern="^[+-]?[1-6]$"/>

            </div>
            <div class="logo">
                лого слота:
<!--                <FileUploader @image:uploaded="sector.image = $event"/>-->
                <div class="logo-handler">
                <file-select v-model="sector.image" type="slots" size="5em"/>
                    <Button size="small" class="p-button-sm" @click="sector.image = ''" icon="pi pi-trash" style="'padding:4px'"/>
                </div>

<!--                <img :src="sector.image" alt="slot logo">-->
            </div>
        </div>

    </div>

</template>

<style lang="scss" scoped>
    .sector-editor {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 8px;

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-inline: 8px;

            h4 {
                margin: 0;
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            align-items: flex-start;

            .logo, .modifier {
                display: flex;
                flex-direction: column;
                .logo-handler {
                    display: flex;
                    button {
                        margin-left: 8px;
                        height: 100%;

                    }
                }
                img {
                    width: 150px;
                    height: 250px;
                }
            }
        }

        .footer {
            display: flex;
            justify-content: center
        }
        .task-item {
            display: grid;
            grid-template-columns: 1fr auto;
        }
    }
</style>