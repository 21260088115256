<template>
  <div class="user-interface">
    <Navigation />
    <MainInterfaceContainer class="side-panel-container" :style="widthStyle"/>
      <transition name="frame">
          <WidgetContainer v-if="showWidgets" class="widget-container"/>
      </transition>
  </div>
</template>

<script>
    import Navigation from '../components/navigation/Navigation.vue'
    import MainInterfaceContainer from './frames/MainInterfaceContainer.vue'
    import WidgetContainer from './frames/WidgetContainer.vue'

    export default {
    name: "LayoutDefault",

    components: {
      Navigation,
      WidgetContainer,
      MainInterfaceContainer,
    },
    computed:   {
      showWidgets() {
        return this.$store.getters["settings/showWidgets"]
      },
      widthStyle() {
        if (!this.showWidgets) {
          return "width: 100%"
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .user-interface {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .side-panel-container {
    height: 100vh;
    width: 62%;
    border-right: 2px solid #8f61f6;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .widget-container {
    width: 38%;
    height: 100vh;
    z-index: 9999999;
    padding: 1%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .frame {
      transition: all 0.5s;
  }

  .frame-enter, .frame-leave-to
      /* .card-leave-active for <2.1.8 */
  {
      opacity: 0;
      transform: scale(0);
  }

  .frame-enter-to {
      opacity: 1;
      transform: scale(1);
  }

  .frame-move {
      opacity: 1;
      transition: all 0.5s;
  }
</style>
