<template>
    <Fieldset>
        <template #legend>
            {{ sessionInfo.title }} | {{ fixDate(sessionInfo.updated_at) }}
        </template>

        <div class="info-block">
            <div class="title">WIN/DEP</div>
            <div class="value">
                {{ fixMoney(sessionInfo.total_win) }} / {{ fixMoney(sessionInfo.deposit) }} {{ currency }}
            </div>
        </div>

        <div class="info-block">
            <div class="title">BONUS</div>
            <div class="value">
                {{ sessionInfo.played_slots }} / {{ sessionInfo.total_slots }}
            </div>
        </div>

        <div class="info-block">
            <div class="title">AVG X</div>
            <div class="value">
                {{ isBonusHunt ? fixPercents(sessionInfo.avg_x) : fixPercents(sessionInfo.total_win_to_total_price) }}
            </div>
        </div>

        <div class="info-block">
            <div class="title">AVG BET</div>
            <div class="value">
                {{ fixMoney(sessionInfo.avg_bet) }} {{ currency }}
            </div>
        </div>

        <div class="info-block" v-if="sessionInfo.avg_price">
            <div class="title">AVG PRICE</div>
            <div class="value">
                {{ fixMoney(sessionInfo.avg_price) }} {{ currency }}
            </div>
        </div>

        <div class="info-block">
            <div class="title">AVG WIN</div>
            <div class="value">
                {{ fixMoney(sessionInfo.avg_win) }} {{ currency }}
            </div>
        </div>
    </Fieldset>
</template>

<script setup>
    import {computed} from 'vue';
    import {useStore} from 'vuex';
    import {useFilters} from "@composables/useFilters.ts";
    import Fieldset from 'primevue/fieldset';
    const {fixPercents, fixMoney, fixDate} = useFilters();

    const store = useStore();
    const sessionInfo = computed(() => store.getters['archive/session'].session_info);
    const isBonusHunt = computed(() => store.getters['archive/isBonusHunt']);
    const currency = computed(() => store.getters['archive/readableCurrency']);

</script>

<style lang="scss" scoped>
    .p-fieldset {
        :deep .p-fieldset-legend {
            padding: 0.5rem;
        }

        :deep .p-fieldset-content {
            padding: 0.5rem;
            display: flex;
            justify-content: space-around;
            gap: 10px;
        }
    }

    .info-block {
        padding: 4px;
        border: 1px solid #8f61f6;
        background: #f8f9fa;
        border-radius: 6px;
        display: flex;
        gap: 4px;
        flex-direction: column;
        width: 150px;
        align-items: center;

        .title {
            color: #8f61f6;
        }

        .value {
            color: black;
            font-weight: 600;
            font-size: 14px;
        }
    }
</style>
