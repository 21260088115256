import zina_1 from './zina_1.webm';
import zina_2 from './zina_2.webm';
import zina_3 from './zina_3.webm';
import zina_4 from './zina_4.webm';
import zina_5 from './zina_5.webm';
import zina_6 from './zina_6.webm';
import jail from './jail.webm';
import prize from './prize.webm';
import percent from './percent.webm';


export const videoAnimations = [
    {src: zina_1}, //0 index
    {src: zina_2}, //1 index
    {src: zina_3}, //2 index
    {src: zina_4}, //3 index
    {src: zina_5}, //4 index
    {src: zina_6}, //5 index
    {src: prize},  //6 index
    {src: percent},//7 index
    {src: jail},   //8 index

];

