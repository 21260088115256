import {widgetBattleRoutes} from '@api/api-routes'
import axios from "@api/axios";
import {centsToBucks} from '@utils/moneyConverters'

export default {
    namespaced: true,

    state: {
        session: null,
    },
    getters: {
        session: state => state.session,
        firstSlot: state => {
            return state.session.slots[0].slot_id
        },
    },
    mutations: {
        session: (state, session) => state.session = centsToBucks(session),
    },
    actions: {
        async fetchWidget({commit}, id) {
            try {
                const {data} = await axios.get(widgetBattleRoutes.fetchBattle(id))
                commit('session', data)
                return data
            } catch (error) {
                console.log(error)
                throw error
            }
        },
    },
}

