<template>
    <Dropdown
        id="currency"
        v-model="currency"
        :options="currencies"
        optionLabel="name"
        optionValue="value"
        placeholder="$ / € / ₽"
        class="p-inputtext-sm"/>
</template>

<script>
    import Dropdown from 'primevue/dropdown'
    import Constants from '@resources/js/constants/general.js'

    export default {
        name: 'CurrencySelector',
        components: {Dropdown},
        data() {
            return {
                currencies: [
                    {name: '$', value: Constants.CURRENCY_USD},
                    {name: '€', value: Constants.CURRENCY_EUR},
                    {name: '₽', value: Constants.CURRENCY_RUB},
                ],
            }
        },
        props: {
            modelValue: {
                type: String,
            },
        },

        computed: {
            currency: {
                get() {
                    return this.modelValue;
                },
                set(newValue) {
                    this.$emit('update:modelValue', newValue);
                },
            },
        },
    }
</script>
<style lang="scss" scoped>
    .p-dropdown {
        width: 128px !important;
    }
</style>
