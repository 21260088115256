<!-- ImageAnimationContainer.vue -->
<template>
    <div
            class="animation-container"
            :class="{ 'is-visible': isVisible }"
    >
        <div class="content-wrapper">
            <slot></slot>
        </div>
    </div>
</template>

<script setup>
    import { ref } from 'vue';

    const isVisible = ref(false);

    const show = () => {
        isVisible.value = true;
    };

    const hide = () => {
        isVisible.value = false;
    };

    defineExpose({
        show,
        hide
    });
</script>

<style scoped lang="scss">
    .animation-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;

    }

    .content-wrapper {
        width: 66%;
        height: 66%;
        position: relative;
        transform: scale(0);
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        transform-origin: center center;
        pointer-events: auto;
        background-image: url('../../../../assets/images/games/slotopoly/sector_bg.png');
        background-size: cover;
    }

    .is-visible .content-wrapper {
        transform: scale(1);
    }
</style>