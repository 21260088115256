<template>
    <router-view/>
</template>

<script>

  export default {
    name: "LandingPageLayout",
  }
</script>
