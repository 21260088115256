<template>
    <session-widget-common-frame>
        <template #title>
            {{ session.title }}
        </template>
        <template #currency>
            {{ readableCurrency }}
        </template>

        <SessionStatisticSection>
            <template #value1>
                {{ playedBonus }}
            </template>

            <template #value2>
                {{ winDepRatio }}
            </template>

            <template #title3>
                ROUND
            </template>
            <template #value3>
                {{ playedRoundsQty }}
            </template>
        </SessionStatisticSection>


        <div class="competitors-list">
            <slide-changer
                :all-items="rounds"
                :interval="roundsSlideChangeDelay"
                :items-per-view="itemsPerViewInList">
                <template #default="{items}">
                    <p-v-p-competitors-list
                        :rounds="items"/>
                </template>
            </slide-changer>
        </div>

        <p-v-p-round-results
            v-if="!!viewMode && viewMode !== 'board' "
            :round="currentRound"/>

    </session-widget-common-frame>
</template>

<script>

    import SessionWidgetCommonFrame from '../widget-parts/SessionWidgetCommonFrame.vue'
    import SessionStatisticSection from '../widget-parts/SessionStatisticSection.vue'
    import PVPCompetitorsList from '../widget-parts/PVPCompetitorsList.vue'
    import PVPRoundResults from '../widget-parts/PVPRoundResults.vue'
    import SlideChanger from '../widget-parts/SlideChanger.vue'
    import {fixMoney, readableCurrency} from '@utils/formatters.js'

    export default {
        name: 'TournamentWidget',
        components: {
            PVPRoundResults,
            PVPCompetitorsList,
            SessionStatisticSection,
            SessionWidgetCommonFrame,
            SlideChanger,
        },

        computed: {

            roundsSlideChangeDelay() {
              return this.$store.getters['settings/tournamentSlidesRefreshDelay']
            },

            session() {
                return this.$store.getters['sessionTournament/session']
            },

            finished() {
                return !!this.session?.meta?.winner
            },

            readableCurrency() {
                return readableCurrency(this.session.currency)
            },

            winDepRatio() {
                return `${fixMoney(this.session.actual_win)} / ${fixMoney(this.session.target_win)}`
            },

            playedRoundsQty() {
                return `${this.session.meta.rounds?.active} / ${this.session.meta.rounds?.total}`
            },

            playedBonus() {
                return `${this.session.meta.bonus.active} / ${this.session.meta.bonus.total}`
            },

            viewMode() {
                return this.session.view_mode
            },

            currentViewRound() {
                if (!!this.viewMode && this.viewMode.startsWith('round')) {
                    return parseInt(this.viewMode.slice(-1))
                }
            },

            itemsPerViewInList() {
                return (this.viewMode === 'board' || !this.viewMode) ? 4 : 2
            },

            rounds() {
                if (this.viewMode === 'board' || !this.viewMode) {
                    return this.roundsListWithWinnerPlaceholder
                }
                else if (this.currentViewRound === 7 && !this.session?.meta?.winner) {
                    return this.finalPlusWinner
                }
                else if (this.currentViewRound === 7 && !!this.session?.meta?.winner) {
                    return this.roundsListWithWinnerPlaceholder
                }
                else {
                    return this.scopedRounds
                }
            },

            winnerStats() {
                const mockWinner = {
                    slot_id: null,
                    slot_title: '',
                    icon: '?',
                }
                return [this.session?.meta?.winner ?? mockWinner]

            },

            roundsListWithWinnerPlaceholder() {
                const rounds = this.$store.getters['sessionTournament/fullFilledRoundsList']
                return [...rounds, this.winnerStats]
            },


            roundsWhereOneCompetitorIsDefined() {
                const rounds = this.$store.getters['sessionTournament/fullFilledRoundsList']
                return rounds.filter(round => round.some(competitor => !!competitor.slot_id))
            },

            finalPlusWinner() {
                const rounds = this.$store.getters['sessionTournament/fullFilledRoundsList']
                return [...rounds.slice(6, 7), this.winnerStats]
            },

            scopedRounds() {
                const rounds = this.$store.getters['sessionTournament/fullFilledRoundsList']

                if (this.currentViewRound === 1 || this.currentViewRound === 2) {
                    return rounds.slice(0, 2)
                }
                if (this.currentViewRound === 3 || this.currentViewRound === 4) {
                    return rounds.slice(2, 4)
                }
                if (this.currentViewRound === 5 || this.currentViewRound === 6) {
                    return rounds.slice(4, 6)
                }
                if (this.currentViewRound === 7) {
                    return rounds.slice(5, 7)
                }
            },

            currentRound() {
                const round = this.$store.getters['sessionTournament/roundByNum'](this.currentViewRound)

                if (round) {
                    return round.map(competitor => {
                        return {
                            ...competitor,
                            spins_finished: competitor.results.reduce((count, result) => {
                                return count + (!!result.win ? 1 : 0)
                            }, 0),
                        }
                    })
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../../../../assets/css/variables';
    .competitors-list {
        height: 100%;
        background-color: $inner-wrapper-bg-color;
        padding: 0.2em;
        border-radius: 0.2em;
        display: flex;
        gap: 0.2em;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
        max-height: min-content;
        transition: max-height 0.3s ease;
    }

</style>
