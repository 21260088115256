<template>
    <div class="flex gap-3 p-1 w-full">
        <div v-for="(group) in roundsByGroups" class="w-4">
            <div class="flex flex-column gap-4 h-full justify-content-around cursor-pointer">
                <div
                    v-for="(round, i) in group"
                    class="round-card"
                    :class="{active: isActiveRound(round)}"
                    @click="onRoundClick(round)">
                    <TournamentRoundCard
                        :key="i"
                        :round="round"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import TournamentRoundCard from './TournamentRoundCard.vue'

    export default {
        name: 'TournamentPlayersBoard',
        components: {TournamentRoundCard},

        props: {
            allRoundsOpenAvailable: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                activeRound: 0,
            }
        },

        computed: {
            roundsByGroups() {
                return this.$store.getters['sessionTournament/roundsByGroups']
            },

            ready() {
                return !!this.roundsByGroups.length
            },
        },

        methods: {
            isActiveRound(round) {
                return this.activeRound === round[1].t_round
            },

            checkIsPreviousRoundsCompleted(round) {
                if (this.allRoundsOpenAvailable) {
                    return true
                }
                for (let i = 1; i < round; i++) {
                    if (!this.$store.getters['sessionTournament/isRoundFinished'](i)) {
                        return false
                    }
                }
                return true
            },

            onRoundClick(round) {
                const roundNumber = round[1].t_round
                if (roundNumber) {
                    if (this.checkIsPreviousRoundsCompleted(roundNumber)) {
                        this.activeRound = roundNumber
                        this.$emit('select:round', round)
                    } else {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: 'Complete previous rounds first',
                            life: 1500,
                        })
                    }
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'This round is not defined yet',
                        life: 1500,
                    })
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    .round-card {
        border: 2px solid transparent;

        &.active {
            border: 2px solid #8f61f6;
            border-radius: 5px
        }
    }
</style>
