<template>
    <div class="slot-stats-widget" v-if="ready">
        <div class="slot-stats-widget-header">
            <span class="text-big long-title">{{ slotData.title }}</span>
            <span class="text-small">{{ slotData.provider }}</span>
        </div>
        <div class="slot-stats-data-table">
            <div class="slot-data-table-column left">
                <div class="slot-data-table-column-header">
                    <span class="text-small text-white">СЛОТ</span>
                </div>
                <div class="flex flex-column justify-content-center w-full h-full gap-2">
                    <div class="table-row text-small text-white">
                        <span>ПОТЕНЦИАЛ: </span>
                        <span>{{ slotData.potential }} x</span>
                    </div>
                    <div class="table-row text-small text-white">
                        <span>РТП: </span>
                        <span>{{ slotData.rtp }}%</span>
                    </div>
                </div>
            </div>
            <div class="slot-data-table-column right">
                <div class="slot-data-table-column-header second">
                    <span class="text-small text-white">РЕКОРД</span>
                </div>
                <div class="flex flex-column justify-content-center w-full h-full gap-2">
                    <div class="table-row text-small text-white">
                        <span class="title">МАКС ВИН: </span>
                        <span class="bet" v-show="slotData.topWin.bet">
                        ({{ slotData.topWin.bet }} {{ slotData.topWin.currency }}) </span>
                        <span class="win">
                        {{ slotData.topWin.win }} {{ slotData.topWin.currency }}</span>
                    </div>
                    <div class="table-row text-small text-white">
                        <span class="title">МАКС X: </span>
                        <span class="bet" v-show="slotData.topX.bet">
                        ({{ slotData.topX.bet }} {{ slotData.topX.currency }}) </span>
                        <span class="win" v-show="slotData.topX.bet">x {{ slotData.topX.x }}</span>
                    </div>
                    <div class="table-row text-small text-white" v-show="slotData.avgX.x">
                        <span class="title">СРД X: </span>
                        <span class="bet">({{ slotData.avgX.bets }}) </span>
                        <span class="win">x {{ slotData.avgX.x }}</span>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'SlotStatsWidgetAks',

        props: {
            slotData: {
                type: Object,
            },
        },

        computed: {
            ready() {
                return !!this.slotData.title
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../../../../../assets/css/variables';

    .slot-stats-widget {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        color: white;
        background-color: #010D11;
        border-radius: 1.5em;
        cursor: default;
        overflow: hidden;
    }

    .slot-stats-widget-header {
        display: flex;
        flex-direction: row;
        padding: 0 calc($section-padding * 5);
        align-items: flex-end;
        justify-content: space-between;
        width: 100%;
        height: 20%;
        color: white;
    }


    .slot-stats-data-table {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        width: 100%;
        height: 75%;
        gap: 1%;
        padding: 0 $section-padding;
        border-radius: $border-radius-inner;
        align-items: center;
    }


    .slot-data-table-column {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5%;
        height: 100%;
        align-items: flex-start;
        justify-content: flex-start;

        background-clip: border-box;
        border-radius: 1em 1em 0 0;

        &.left {
            background: linear-gradient(269.28deg, rgba(8, 170, 112, 0.15) 4.14%, rgba(120, 13, 205, 0.15) 113.19%);
        }

        &.right {
            background: linear-gradient(269.28deg, rgba(120, 13, 205, 0.15) 4.14%, rgba(8, 170, 112, 0.15) 113.19%) !important;

        }
    }


    .slot-data-table-column-header {
        display: flex;
        flex-direction: row;

        background: linear-gradient(89.03deg, #008958 -3.87%, #3B0069 106.39%);
        background-clip: border-box;
        gap: 1em;
        align-items: center;
        width: 100%;
        justify-content: center;
        color: white;
        border-radius: 1em 1em 0 0;

        &.second {
            background: linear-gradient(269.03deg, #008958 -3.87%, #3B0069 106.39%) !important;
        }

        i {
            font-size: 0.85em;
            color: white;
        }

    }


    .table-row {
        display: flex;
        align-items: flex-end;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding: 0 $section-padding;


        .title {
            width: 40%;
            text-align: start;
        }

        .bet {
            width: 30%;
            text-align: center;
        }

        .win {
            width: 30%;
            text-align: end;
        }
    }
</style>
