<template>
  <Button icon="pi pi-trash"
          class="p-button-text p-button-sm"
          :disabled="slotData.win"
          @click="slotDeleteConfirm"/>
</template>

<script>
  import Button from 'primevue/button'

  export default {
        name: 'DeleteButton',
        components: {
            Button,
        },
        props: {
            slotData: {
                required: true,
            },
        },
        methods: {
      slotDeleteConfirm() {
        this.$confirm.require({
          message:     "Do you want to delete " + this.slotData.title + " ?",
          icon:        "pi pi-info-circle",
          acceptClass: "p-button-danger",
          acceptIcon:  "pi pi-trash",
          acceptLabel: "Delete",
          rejectClass: "p-button-text",
          rejectLabel: "No",
          accept:      () => {
            this.deleteSlot()
          },
        })
      },

      async deleteSlot() {
        try {
          await this.$store.dispatch("sessionHB/removeSlot", this.slotData.session_slot_id)
        } catch (e) {
          console.log(e)
        } finally {
          this.$emit("deleted")
        }
      },
    },
  }

</script>

<style lang="scss" scoped>

</style>
