<template>
  <div class="loading"
       :style="width">
    <ProgressSpinner/>
  </div>
</template>

<script>
  import ProgressSpinner from "primevue/progressspinner"

  export default {
    name:       "Loading",
    components: {
      ProgressSpinner,
    },

    computed: {
      width() {
        return this.$store.getters["settings/showWidgets"] ? {"width": "64%"} : {"width": "100%"}
      },
    },

  }
</script>

<style lang="scss" scoped>
  .loading {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    background: rgba(1, 1, 1, 0.2);
  }
</style>
