<template>
    <div id="app">
        <LandingPageLayout v-if="landingPageLayout"/>
        <OBSLayout v-else-if="obsLayout"/>
        <LayoutDefault v-else/>
      <Toast position="top-left" v-if="!obsLayout && !landingPageLayout"/>
    </div>
</template>

<script>
  import Toast from 'primevue/toast'
  import LayoutDefault from './layouts/LayoutDefault.vue'
  import LandingPageLayout from './layouts/LandingPageLayout.vue'
  import OBSLayout from './layouts/OBSLayout.vue'

  export default {
    name: 'App',
    components: {
        Toast,
        LayoutDefault,
        LandingPageLayout,
        OBSLayout,
    },
    computed: {
        landingPageLayout() {
            return this.$route.meta.layout === 'landing'
        },

      obsLayout() {
            return this.$route.meta.layout === 'obs'
        },
    },
    created() {
        this.langCheck()
        this.fetchMe()
    },
    methods: {
        langCheck() {
            const language = window.navigator.userLanguage || window.navigator.language
            if (language === 'ru-RU' || language === 'RU-ru') {
                this.$store.dispatch('user/setUsersLocale')
            }
        },
        fetchMe() {
            this.$store.dispatch('user/fetchMe')
        }
    },
}
</script>

<style lang="scss">
    body {
        font-size: 12px;
        margin: 0;
        padding: 0;
        font-family: 'Montserrat', 'Raleway', Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    .p-component {
        font-family: 'Montserrat', 'Raleway', Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
    }
</style>
