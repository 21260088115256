<script setup lang="ts">
    import {computed, ref} from "vue";
    import Button from "primevue/button";
    import InputText from "primevue/inputtext";
    import RadioButton from "primevue/radiobutton";
    import PlayerChip from "@components/games/slotopoly/PlayerChip.vue";

    const props = defineProps(['modelValue', 'sectors', 'editMode']);
    const emit = defineEmits(["update:modelValue",  'player:move']);

    const localPlayers = ref(props.modelValue);

    const players = computed({
        get: () => props.modelValue,
        set: (value) => {
            localPlayers.value = value;
            emit("update:modelValue", localPlayers.value);
        },
    });

    const activePlayerIndex = ref(0)

    const isActivePlayerOnModSector = computed(() => {
        const activePlayerPosition = players.value[activePlayerIndex.value]?.position
        const sector = props.sectors[activePlayerPosition]
        return sector?.modifier ?? null
    })

    const setJail = (index: number) => {
        if (players.value[index]?.cooldown > 0) {
            players.value[index].cooldown--
        }
        else players.value[index].cooldown = 2;
        emit('update:modelValue');
    };

    const deactivate = index => players.value[index].out = !players.value[index].out


    const setActivePlayer = (index: number)=> {
        players.value.forEach((player) => player.active = false);
        players.value[index].active = true;
        emit("update:modelValue", localPlayers.value);
    };

    const setModifierValue = (index: number) => {
        if (isActivePlayerOnModSector.value) {
            const modifier = parseInt(isActivePlayerOnModSector.value, 10); // Преобразуем строку в число
            if (!isNaN(modifier)) {
                players.value[index].roll = modifier;
            } else {
                players.value[index].roll = 0
                console.warn("Invalid modifier:", isActivePlayerOnModSector.value);
            }
        }
    }

    const rollDice = (index: number) => {
        players.value[index].roll = 0

        const random = Math.floor(Math.random() * 6) + 1;
        players.value[index].rollRandom = random;
        players.value[index].position += random
        emit('player:move', players.value[index], true)

        setModifierValue(index)
    };

    const makeManualMove = (index: number) => {
        players.value[index].rollRandom = 0

        players.value[index].position += players.value[index].roll
        emit('player:move', players.value[index], false)

        players.value[index].roll = 0
        setModifierValue(index)
    }



</script>

<template>
    <div class="players">
        <div v-for="(player, index) in players" :key="index" class="player" :class="player.deactivated ? 'deactivated' : ''">
            <RadioButton v-model='activePlayerIndex' :value="index" @change="setActivePlayer(index)" :disabled="player.cooldown > 0 || player.out || editMode"/>

            <player-chip :player="player" />

            <span>{{ player.name }}</span>

            <InputText
                    v-model.number="player.balance"
                    :placeholder="player.balance || 'Баланс'"
                    class="p-inputtext-sm"
                    type="number"
                    min="1"
                    max="6"
                    :disabled="player.cooldown > 0 || player.out || editMode || index !== activePlayerIndex"
            />

            <Button
                    icon="pi pi-hashtag"
                    class="p-button-sm"
                    @click="setJail(index)"
                    :label="player.cooldown > 0 ? `cd ${player.cooldown}` : 'Тюрьма'"
                    :disabled="player.out || editMode || index !== activePlayerIndex"
            />

            <Button
                    :icon="!player.out ? 'pi pi-eraser' : 'pi pi-history'"
                    class="p-button-sm"
                    @click="deactivate(index)"
                    :label="player.out ? 'Вернуть' : 'Выбыл'"
                    :disabled="editMode || index !== activePlayerIndex"
            />
            <Button
                    icon="pi pi-microsoft"
                    class="p-button-sm"
                    @click="rollDice(index)"
                    label="Рандом"
                    :disabled="player.cooldown > 0 || player.out || editMode || index !== activePlayerIndex"
            />

            <InputText
                    v-model.number="player.roll"
                    :placeholder="player.roll || 'Кубик'"
                    class="p-inputtext-sm"
                    type="number"
                    min="1"
                    max="6"
                    :disabled="player.cooldown > 0 || player.out || editMode || index !== activePlayerIndex"
            />

            <Button
                    icon="pi pi-arrow-right"
                    class="p-button-sm"
                    @click="makeManualMove(index)"
                    label="Сделать Ход(вручную)"
                    :disabled="!player.roll || player.cooldown > 0 || editMode || index !== activePlayerIndex"
            />
        </div>

    </div>
</template>

<style lang="scss" scoped>
    .players {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: start;
    }

    .player {
        display: grid;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        width: 100%;
        grid-template-columns: 32px 32px 100px 150px 150px 100px 150px 100px 250px;

        &.deactivated {
            filter: grayscale(100%);
        }
    }
</style>
