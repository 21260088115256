import {singleRollRoutes} from '@api/api-routes'
import axios from "@api/axios";
import {bucksToCents, centsToBucks} from '@utils/moneyConverters'

export default {
    namespaced: true,

    state: {
        slot: {},
        slotHistory: [],
    },
    getters: {
        slot: state => state.slot,
        slotHistory: state => state.slotHistory,
    },
    mutations: {
        slot: (state, slot) => state.slot = slot,
        slotHistory: (state, slotHistory) => state.slotHistory = centsToBucks(slotHistory),
        clear: (state) => {
            state.slot = {}
            state.slotHistory = []
        },
    },
    actions: {
        addSlot({state,commit}, payload) {
            commit('slot', payload)
        },
        async postResult(state, payload) {
            try {
                payload = await bucksToCents(payload)
                return await axios.post(singleRollRoutes.postResult, payload)
            } catch (error) {
                throw error
            }
        },

        async changeEntry(state, value) {
            try {
                const payload = await bucksToCents(value.payload)
                return await axios.put(singleRollRoutes.changeEntry(value.id), payload)
            } catch (error) {
                throw error
            }
        },

        async deleteEntry(state, id) {
            try {
                return await axios.delete(singleRollRoutes.changeEntry(id))
            } catch (error) {
                throw error
            }
        },

        async fetchSlotHistory({commit}, id) {
            try {
                const {data} = await axios.get(singleRollRoutes.fetchHistory(id))
                commit('slotHistory', data)
                return data
            } catch (error) {
                throw error
            }
        },

        clearData({commit}) {
            commit('clear')
        },
    },

}
