import axios from "@api/axios";
import {FileStorageRoutes} from '@api/api-routes';

export default {
    namespaced: true,

    //maybe someday we will need more image types, but not today. defo not today...
    state: {
        avatars: [],
        slots: [],
        noCategory: []
    },
    getters: {
        avatars: state => state.avatars,
        slots: state => state.slots,
        noCategory: state => state.noCategory,

    },
    mutations: {
        avatars: (state, file) => state.avatars = [file, ...state.avatars ],
        slots: (state, file) => state.slots = [file, ...state.slots],
        noCategory: (state, file) => state.noCategory = [...state.noCategory, file]
    },
    actions: {
        download: async ({commit}, type) => {
            const response = await axios.get(FileStorageRoutes.get,
                {params: type ? {custom_properties: {type: type}} : null})
            commit(type, response.data)
        },

        getAll: async ({commit}) => {
            const {data} = await axios.get(FileStorageRoutes.get)
            data.forEach((file) => {
                if (file.custom_properties?.type === 'avatars') {
                    commit('avatars', file)
                } else if (file.custom_properties?.type === 'slots') {
                    commit('slots', file)
                } else {
                    commit('noCategory', file)
                }
            });
        },

        upload: async ({commit}, {files, type}) => {
            try {

                const formData = new FormData();
                files.forEach((file) => formData.append('files[]', file));

                const {data} = await axios.post(FileStorageRoutes.upload, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    params: type ? {custom_properties: {type: type}} : null

                });

                commit(type, data)
                return data
            } catch (error) {
                console.error('Error:', error);
            }
        }
    },
}