<template>
    <SessionWidget
        v-if="ready && !isLuxuryGRL && !isAks"
        :show-losers="showLosers"
        :leaders="sessionLeaders"
        :session="sessionInfo"
        :slots="sessionSlots"/>

    <SessionWidgetAks
        v-else-if="ready && isAks"
        :show-losers="showLosers"
        :leaders="sessionLeaders"
        :session="sessionInfo"
        :slots="sessionSlots"/>

    <SessionWidgetLux
        v-else-if ="ready && isLuxuryGRL"
        :slots="sessionSlotsLux"
        :leaders="sessionLeadersLux"
        :session="sessionInfoLux"/>
</template>

<script>

    import SessionWidget from './SessionWidget.vue'
    import SessionWidgetLux from './lux/SessionWidgetLux.vue'
    import SessionWidgetAks from './aks/SessionWidgetAks.vue'

    import {sessionInfoFormatter, sessionLeadersFormatter, sessionSlotsFormatter} from './utils'
    import {sessionInfoFormatterLux, sessionLeadersFormatterLux, sessionSlotsFormatterLux} from './lux/utils'

    export default {
        name: 'SessionHBWidgetWrapper',

        components: {
            SessionWidgetAks,
            SessionWidget,
            SessionWidgetLux,
        },


        computed: {

            isAks() {
                return this.$store.getters['user/me'].id === 841
            },

            isLuxuryGRL() {
                return this.$store.getters['user/me'].id === 834
            },

            showLosers() {
                return this.$store.getters['settings/showLosers']
            },

            ready() {
                return this.$store.getters['widgetHB/ready']
            },

            session() {
                return this.$store.getters['widgetHB/session']
            },

            sessionInfo() {
                return sessionInfoFormatter(this.session)
            },
            sessionInfoLux() {
                return sessionInfoFormatterLux(this.session)
            },

            sessionLeaders() {
                return sessionLeadersFormatter(this.session)
            },
            sessionLeadersLux() {
                return sessionLeadersFormatterLux(this.session)
            },

            sessionSlots() {
                return sessionSlotsFormatter(this.session)
            },
            sessionSlotsLux() {
                return sessionSlotsFormatterLux(this.session)
            },
        },
    }
</script>

