import Constants from '@resources/js/constants/general.js'

export function fixPercents(number, digits) {
    return fixDecimal(number, digits)
}

export function fixMoney(number) {
    return numberComplexHandler(number)
}

export function toUpperCase(string) {
    return string ? string.toUpperCase() : string
}

export function numberComplexHandler(number) {
    if (number >= 0 && number < 100) return fixDecimal(number, 2)

    else if (number >= 100 && number < 1000) return fixDecimal(number, 0)

    else if (number >= 1000 && number < 100000) {
        return numberSpacing(fixDecimal(number, 0))
    } else if (number >= 100000) {
        return abbrNum((fixDecimal(number, 0)))
    } else
        return number
}

export function fixDecimal(data, dig=2) {
    let number = typeof data === 'string' ? Number(data) : data
    let result = 0

    if (number > 0 && number < 10) {
        let n = dig
        while (result === 0 && n <= 4) {
            result = parseFloat(number.toFixed(n))
            if (result === 0) {
                n++
            }
        }
    } else if (number >= 10 && number < 100) {
        result = parseFloat(number.toFixed(1))
    } else if (number >= 100) {
        result = number.toFixed(0)
    } else {
        result = number
    }

    return result
}

export function abbrNum(number, decPlaces = 2) {

    decPlaces = Math.pow(10, decPlaces)
    let abbrev = ['K', 'M', 'B', 'T']
    for (let i = abbrev.length - 1; i >= 0; i--) {
        let size = Math.pow(10, (i + 1) * 3)
        if (size <= number) {
            number = Math.round(number * decPlaces / size) / decPlaces
            if ((number === 1000) && (i < abbrev.length - 1)) {
                number = 1
                i++
            }
            return number += abbrev[i]
        }
    }
}

export function numberSpacing(number) {
    return number ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : number
}

export function truncate(data, num = 10) {
    if (data) {
        const res =
            data.split('').slice(0, num).join('')
        if (data.length <= num) {
            return data
        } else
            return res
    }
    return ''
}

export function readableCurrency(currency) {
    if (currency === Constants.CURRENCY_USD) return '$'
    if (currency === Constants.CURRENCY_EUR) return '€'
    if (currency === Constants.CURRENCY_RUB) return '₽'
    else return ''
}

export function readableSessionType(type) {
    if (type === Constants.TYPE_BONUS_HUNT) return 'Bonus Hunt'
    if (type === Constants.TYPE_BONUS_BUY) return 'Bonus Buy'
    if (type === Constants.TYPE_BATTLE) return 'Bonus Battle'
    if (type === Constants.TYPE_BONUS_TOURNAMENT) return 'Bonus Tournament'
    if (type === Constants.TYPE_SINGLE_ROLL) return 'Single Roll'
    else return ''
}

export function isBonusHunt(sessionType) {
    return sessionType === Constants.TYPE_BONUS_HUNT
}

export function isHuntOrBuy(sessionType) {
    return sessionType === Constants.TYPE_BONUS_HUNT || sessionType === Constants.TYPE_BONUS_BUY
}

export function isBattle(sessionType) {
    return sessionType === Constants.TYPE_BATTLE
}

export function isTournament(sessionType) {
    return sessionType === Constants.TYPE_BONUS_TOURNAMENT
}






