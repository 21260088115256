<template>
    <div class="archive-session">
        <archive-session-header/>
        <archive-session-leader-board/>
        <archive-session-table/>
    </div>
</template>

<script>
import ArchiveSessionHeader from '../../components/archive-session/general/ArchiveSessionHeader.vue'
import ArchiveSessionLeaderBoard from '../../components/archive-session/general/ArchiveSessionLeaderBoard.vue'
import ArchiveSessionTable from '../../components/archive-session/general/ArchiveSessionTable.vue'

export default {
    name: 'ArchiveGeneralSession',
    components: {
        ArchiveSessionHeader,
        ArchiveSessionLeaderBoard,
        ArchiveSessionTable,
    },

}
</script>

<style lang="scss" scoped>
.archive-session {
    padding: 8px;
    overflow-y: auto;
    height: 100vh
}
</style>
