<script setup>
    import {computed, defineExpose, ref, watch} from "vue";
    import SectorPlayersLayer from "@components/games/slotopoly/SectorPlayersLayer.vue";
    import PlayerChip from "@components/games/slotopoly/PlayerChip.vue";
    import VideoAnimationContainer from "@components/games/slotopoly/VideoAnimationContainer.vue";
    import {videoAnimations} from "@public/video/videos.js"
    import ImageAnimationContainer from "@components/games/slotopoly/ImageAnimationContainer.vue";

    const BOARD_SIZE = 28

    const props = defineProps({
        sectors: {
            type: Array,
            required: true
        },

        players: {
            type: Array,
        },

        editMode: {
            type: Boolean,
            default: true
        },

        isWidget: {
            type: Boolean,
            default: false
        },

        lastMove: {
            type: Object,
        },

        tasks: {
            type: Object,
        }
    })

    const emit = defineEmits(['click:sector', 'click:task'])

    const videoPlayer = ref(null); //templateRef
    const animContainer = ref(null); //templateRef

    const boardCopy = ref([...props.sectors]) //local copy

    const editableSector = ref(null) //on edit mode - not widget

    const currentMoveId = ref(null); //storage slot to prevent duplicate movements

    const animationOngoing = ref(false)

    const noRandomDiceResult = ref(null) //show value of manual dice or modifier
    const showSectorTasks = ref(false)

    const winner = ref(null)


    const clearEditableSector = () => {
        editableSector.value = null
    }

    const activePlayerIndex = computed(() => props.players?.findIndex(player => player.active) ?? 0)
    const activePlayerId = computed(() => props.players?.find(player => player.active)?.id)

    const activeTaskOfActivePlayer = computed(() => props.tasks?.[activePlayerId.value])

    const activeSector = computed(() => {
        if (animationOngoing.value) return null
        const sectorIndex = props.players?.[activePlayerIndex.value]?.position ?? 0
        if (props.sectors) return props.sectors[sectorIndex]
    });

    const topPlayers = computed(() => {
        if (props.players) return props.players.length > 3 ? props.players.slice(0, 3) : props.players
    })

    const bottomPlayers = computed(() => {
        if (props.players) return props.players.length > 3 ? props.players.slice(3, 6) : []
    })

    const handleSectorClick = (sector) => {
        if (!props.editMode) return
        editableSector.value = sector
        emit('click:sector', sector)
    }

    const sectorClass = (sector) => {
        const position = sector?.position
        const editable = !!editableSector.value && sector.id === editableSector.value.id ? 'editable' : ''
        const active = !!activeSector.value && !props.editMode && sector.id === activeSector.value.id && !animationOngoing.value ? 'active' : ''
        return `sector ${position} ${active} ${editable}`
    }


    const playVideoAnimation = async (index) => {
        if (!props.isWidget) return
        try {
            await videoPlayer.value.playVideo(index);
        } catch (error) {
            console.error('Ошибка при воспроизведении:', error);
        }
    };

    const playMoveAnimation = async (move) => {
        // showSectorTasks.value = false
        // noRandomDiceResult.value = null
        const isWinner = move.player.position >= BOARD_SIZE;

        const extraSteps = move.player.position - BOARD_SIZE

        const roll = move.player.rollRandom || move.player.roll;

        const steps = isWinner ? roll - extraSteps : roll;
        const prevPosition = move.player.position - roll;
        const stepDirection = steps > 0 ? 1 : -1;

        for (let i = 0; i < Math.abs(steps); i++) {
            await new Promise((resolve) => {
                setTimeout(() => {
                    // Удаляем игрока из текущего сектора
                    const currentSector = boardCopy.value[prevPosition + i * stepDirection];
                    if (currentSector) {
                        currentSector.players = currentSector?.players.filter(
                                (pl) => pl.id !== move.player.id
                        );
                    }

                    // Добавляем игрока в новый сектор
                    const nextSector = boardCopy.value[prevPosition + (i + 1) * stepDirection];
                    const alreadyHasPlayer = nextSector?.players.some((pl) => pl.id === move.player.id);
                    if (nextSector && !alreadyHasPlayer) {
                        nextSector.players.push(move.player);
                    }

                    resolve();
                }, 300);
            });

        }


        if (isWinner) {
            boardCopy.value.at(-1)?.players.filter((pl) => pl.id !== move.player.id)
            boardCopy.value[0]?.players.push(move.player)
            winner.value = move.player
            showSectorTasks.value = false
            noRandomDiceResult.value = null
            animContainer.value.show();
        }

    };

    const updatePlayersOnBoard = () => {
        boardCopy.value.forEach((sector) => {
            sector.players = [];
        });

        props.players?.forEach((player) => {
            const sector = boardCopy.value[player.position ?? 0];
            const alreadyHasPlayer = sector?.players.some((pl) => pl.id === player.id);
            if (sector && !alreadyHasPlayer) {
                sector.players.push(player);
            }
        });
    };


    defineExpose({
        clearEditableSector,
    })


    const showCenterAnimation = (delay = 3000) => {
        return new Promise((resolve) => {
            animContainer.value.show();
            setTimeout(() => {
                animContainer.value.hide();
                resolve();
            }, delay);
        });
    };

    const showNotRandomDiceResult = async (value) => {
        if (!props.isWidget) return
        noRandomDiceResult.value = value
        await showCenterAnimation(3000)
        noRandomDiceResult.value = null
    }

    const showSectorInfoWithTasks = () => {
        noRandomDiceResult.value = null
        showSectorTasks.value = true
        animContainer.value.show();
    }

    const playTargetSectorAnimation = async (newMove) => {
        // if (!props.isWidget) return

        const corners = ['corner_1', 'corner_2', 'corner_3', 'corner_4'];
        const newPositionSectorId = boardCopy.value[newMove.player.position].id
        const modifier = boardCopy.value[newMove.player.position]?.modifier
        const whichCorner = corners.findIndex(corner => corner === newPositionSectorId)
        if (whichCorner > 0) {
            await playVideoAnimation(whichCorner + 5) // 5 - it's a dice sides.length
        } else if (whichCorner < 0) {
            if (!!modifier) {
                await showNotRandomDiceResult(modifier)
            } else {
                showSectorInfoWithTasks()
            }
        }

    };

    const hideSectorTasks = () => {
        if (!props.isWidget) return
        console.log("hideSectorTasks timer")
        setTimeout(() => {
            showSectorTasks.value = false
            animContainer.value.hide();
        }, 5000)
    }


    const onTaskClick = (task) => {
        emit('click:task', {[activePlayerId.value]: task})

        setTimeout(() => {
            showSectorTasks.value = false
            animContainer.value.hide();
        }, 3000)
    }


    const handlePlaybackComplete = () => {
        console.log('Видео завершило воспроизведение');
    };

    watch(
            () => props.players?.length,
            (newLength, oldLength) => {
                if (newLength !== oldLength) {
                    updatePlayersOnBoard();
                }
            },
            {immediate: true}
    );

    watch(
            () => props.players,
            (newPlayers, oldPlayers) => {
                const activeOld = oldPlayers?.findIndex(player => player.active)
                const activeNew = newPlayers?.findIndex(player => player.active)
                if (activeOld !== activeNew) {
                    updatePlayersOnBoard();
                }

            },
            {immediate: true, deep: true}
    );

    watch(
            () => activeTaskOfActivePlayer.value,
            (newTask, oldTask) => {
                console.log("(SlotopolyBoard.vue:266) ---> newTask:", newTask);
                console.log("(SlotopolyBoard.vue:266) ---> oldTask:", oldTask);
                if (!!newTask?.id && newTask?.id !== oldTask?.id) hideSectorTasks()
            }
    )

    watch(
            () => props.lastMove,
            async (newMove) => {
                if (newMove?.id && newMove.id !== currentMoveId.value) {
                    currentMoveId.value = newMove.id;

                    if (newMove.random) {
                        await playVideoAnimation(newMove.player.rollRandom - 1)
                    } else {
                        await showNotRandomDiceResult(newMove.player.roll)
                    }
                    animationOngoing.value = true;
                    await playMoveAnimation(newMove);
                    animationOngoing.value = false;
                    await playTargetSectorAnimation(newMove);
                }
            },
            {immediate: true}
    );

</script>

<template>
    <div class="board" :class="{'widget-view': isWidget}">
        <div class="grid" :class="{ 'sector-active': !!editableSector || !!activeSector}">
            <div
                    v-for="sector in boardCopy"
                    :key="sector?.text"
                    :class="sectorClass(sector)"
                    :style="{ gridArea: sector.gridArea }"
                    @click="handleSectorClick(sector)"
            >


                <div class="sector-content">
                    <div class="sector-props texts" v-if="editMode">

                            <span v-for="(task, index) in sector.tasks"
                                  :key="index"
                                  :class="{'text-green': task, 'text-red': !task}">
                                {{ index + 1 }}
                            </span>

                    </div>

                    <span class="sector-name" :class="{'edit-mode' : editMode}">
                            {{ sector.name ?? sector.text }}
                        </span>

                    <img :src="sector.image" alt="" class="sector-image">

                </div>

                <sector-players-layer
                        v-if="sector.players.length > 0"
                        :sector-players="sector.players"
                        :all-players="players"
                        :is-widget="isWidget"
                />
            </div>


            <div class="center" :style="[`--font-size: ${isWidget ? '4em' : '2em'}`]">

                <video-animation-container ref="videoPlayer"
                                           :videos="videoAnimations"
                                           @playback-complete="handlePlaybackComplete"/>

                <image-animation-container ref="animContainer">

                    <div class="dice-value" v-if="noRandomDiceResult">
                        {{ noRandomDiceResult }}
                    </div>

                    <div class="center-content" v-else-if="showSectorTasks">
                        <div class="left-side">
                            <img v-if="activeSector?.image" :src="activeSector.image" alt="slot-logo"/>
                        </div>
                        <div class="right-side">

                            <div class="tasks" v-if="activeSector?.tasks">
                                <span v-for="task in activeSector?.tasks"
                                      :key="task.id"
                                      class="task"
                                      :class="{'selected': activeTaskOfActivePlayer?.id === task.id}"

                                      role="button"
                                      @click="onTaskClick(task)">
                                    {{ task.text }}
                                    <br>
                                </span>
                            </div>
                        </div>
                    </div>

                                    <div class="winner-content" v-else-if="winner">
                                        <span>ПОБЕДИТЕЛЬ: {{ winner.name }}</span>
                                        <player-chip :player="winner"/>
                                        <span>Баланс: {{ winner.balance }}</span>
                                    </div>

                </image-animation-container>


                <div class="top-stats-bar" v-if="!animationOngoing">
                    <div v-for="player in topPlayers" class="player-stats">
                        <player-chip :player="player" :size="isWidget ? 'xlarge' : 'normal'"/>
                        <span>| {{ player.name }} </span>
                        <span class="balance">| {{ player.balance }} </span>
                        <span v-if="player.cooldown > 0">| тюрьма({{ player.cooldown }})</span>
                    </div>
                </div>


                <!--                <div class="center-content" v-if="winner">-->
                <!--                    <span>ПОБЕДИТЕЛЬ: {{ winner.name }}</span>-->
                <!--                    <player-chip :player="winner"/>-->
                <!--                    <span>Баланс: {{ winner.balance }}</span>-->
                <!--                </div>-->


                <div class="bottom-stats-bar" v-if="!animationOngoing">

                    <div v-for="player in bottomPlayers" class="player-stats">
                        <player-chip :player="player" :size="isWidget ? 'xlarge' : 'normal'"/>
                        <span>| {{ player.name }} </span>
                        <span class="balance">| {{ player.balance }} </span>
                        <span v-if="player.cooldown > 0">| тюрьма({{ player.cooldown }})</span>
                    </div>
                </div>

            </div>
        </div>
    </div>

</template>

<style lang="scss" scoped>
    .board {
        max-width: 960px;
        max-height: 640px;
        padding: 2%;

        &.widget-view {
            max-width: 1920px;
            max-height: 1080px;
            width: 96%
        }
    }

    .grid {
        display: grid;
        grid-template-rows: repeat(8, 1fr);
        grid-template-columns: repeat(8, 1fr);
        gap: 2px;
        position: relative;
        margin: 0;
    }

    .sector {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        border: 1px solid #ccc;
        aspect-ratio: 16 / 9;
        position: relative;
        cursor: pointer;
        background-color: RGB(144, 99, 207);
        min-width: 100px;

        .sector-content {
            z-index: 1;
            width: 100%;
            height: 100%;
            position: relative;

            .texts {
                position: absolute;
                left: 10px; /* Отступ слева */
                top: 50%; /* Центрирование по вертикали */
                transform: translateY(-50%);
                display: flex;
                flex-direction: column;
                z-index: 2;
                font-weight: bold;

                .text-green {
                    color: green;

                }

                .text-red {
                    color: red;
                }
            }

            .sector-name {
                position: absolute;
                top: 5px; /* Отступ сверху */
                left: 50%;
                transform: translateX(-50%);
                color: transparent;
                z-index: 3;

                &.edit-mode {
                    color: black;
                }
            }

            .sector-image {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-height: 100%; /* Высота изображения ограничена */
                max-width: 90%; /* Ширина ограничена */
                object-fit: contain; /* Пропорциональное масштабирование */
            }
        }

    }

    .grid.sector-active .sector.active {
        transform: scale(1.3);
        z-index: 6;
        border: 2px solid white;
    }

    .center {
        grid-area: 2 / 2 / span 6 / span 6;
        display: flex;
        background-color: transparent;
        border: 2px solid #000;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        position: relative;

        .dice-value {
            display: flex;
            font-size: 10em;
            color: white;
            font-weight: bold;
            align-items: center;
            justify-content: center;
            height: 100%;
        }


        .bottom-stats-bar, .top-stats-bar {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 1%;

            .player-stats {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                gap: 8px;
                font-size: var(--font-size);
                font-weight: bold;
            }
        }

        .center-content {
            //position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            gap: 12px;

            .left-side {
                max-width: 33%; /* Контролирует ширину области для картинки */
                max-height: 100%; /* Чтобы ограничить высоту контейнера */

                img {
                    object-fit: contain; /* Масштабирует изображение, сохраняя пропорции, без выхода за границы */
                    width: 100%; /* Занимает всю доступную ширину контейнера */
                    height: auto; /* Сохраняет соотношение сторон */
                    max-height: 100%; /* Дополнительно ограничивает изображение по высоте */
                }
            }

            .tasks {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;

                .task {
                    font-size: var(--font-size);
                    cursor: pointer;
                    color: white;
                    border: 2px solid transparent;
                    font-weight: bold;

                    &:hover {
                        border: 2px solid white
                    }

                    &.selected {
                        color: gold
                    }
                }

            }

        }
        .winner-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            font-size: var(--font-size);
            font-weight: bolder;
        }

    }
</style>