<template>
  <Button icon="pi pi-bolt"
          class="p-button-text p-button-sm"
          @click="openSingleRoll"
  />
</template>

<script>
  import Button from 'primevue/button'
  import router from "@/router.js";


  export default {
        name: 'SingleRollButton',
        components: {
            Button,
        },
        props: {
            slotData: {
                required: true,
            },
            currency: {
                required: true,
      },
    },
    methods:    {
      openSingleRoll() {
        const payload = {
            currency: this.currency,
            id: this.slotData.slot_id,
            title: this.slotData.title,
        }
        this.$store.dispatch("singleRoll/addSlot", payload)
        this.$emit("click")
        router.push({name: "single-roll"})
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
