<template>
  <div class="slot-stats-widget"
       v-if="ready"
       :class="bordersStyle">

    <div class="slot-stats-widget-header">
      <div class="title">
      <i class="pi pi-info-circle"></i>
      <h2>{{ slotData.title }}</h2>
      </div>
        <h2>{{ slotData.rtp }}% | x{{ slotData.potential }}</h2>
    </div>

      <div class="slot-stats-widget-stats">
          <div class="title">
              <i class="pi pi-user"/>
              <h2>:</h2>
          </div>
          <div class="stats">
              <div class="stat-item" v-show="slotData.topWin.bet">
                  <h3>Max win: </h3>
                  <h2>({{ slotData.topWin.bet }} {{ slotData.topWin.currency }}) - {{ slotData.topWin.win }}
                      {{ slotData.topWin.currency }}</h2>
              </div>

              <div class="stat-item" v-show="slotData.topX.bet">
                  <h3>Max X: </h3>
                  <h2> ({{ slotData.topX.bet | combiner }} {{ slotData.topX.currency }}) x {{ slotData.topX.x }}</h2>
              </div>

              <div class="stat-item" v-show="slotData.avgX.x">
                  <h3>Avg X: </h3>
                  <h2> ({{ slotData.avgX.bets }}) x {{ slotData.avgX.x }}</h2>
              </div>
          </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: "SlotWidgetCompact",

    props: {
        bordersStyle: {
            default: 'both',
        },
        slotData: {
            type: Object,
        },
    },

    computed: {
        ready() {
            return !!this.slotData.title
        },
    },
  }
</script>

<style lang="scss" scoped>


  h1 {
    margin: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 1.25em;
    color: white;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 0.85em;
    color: white;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 0.65em;
    color: white;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }


  .slot-stats-widget {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #181329;
    padding: 4px 8px;
    cursor: default;
    justify-content: center;
    gap: 2px;

    &.bottom {
      border-radius: 0 0 8px 8px;
    }

    &.top {
      border-radius: 8px 8px 0 0;
    }

    &.both {
      border-radius: 8px;
    }
  }

  .slot-stats-widget-header {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;

    .title {
      display: flex;
      align-items: center;
      gap: 8px;

      i {
        font-size: 0.85em;
        color: white;
      }
    }
  }


    .slot-stats-widget-stats {
      width: 100%;
      height: 50%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;
      gap: 4px;

      .title {
        display: flex;
        width: 5%;

        i {
          font-size: 0.85em;
          color: white;
        }
      }

      .stats {
        display: flex;
        justify-content: space-between;
        gap: 2px;
        width: 95%;

        .stat-item {
          display: flex;
          align-items: center;

          h3 {
            margin-right: 2px;
          }
        }
      }
    }


</style>
